import * as t from "io-ts";
import {
  appointments,
  permissions as mediaSessionPermissions,
  profiles,
} from "@proximie/api";
import { DateFromISOString } from "io-ts-types";
import { nullable } from "io-ts/lib/Type";

// Reserved socket.io events
// these are deprecated - only the SocketIOClientWrapper should care about such things...
export const SocketConnect = "connect";
export const SocketError = "connect_error";
export const SocketDisconnect = "disconnect";
export const SocketServerDisconnect = "io server disconnect";

// Request body types

export const MediaSessionEventType = {
  sessionLaunched: "session_launched",
  getPermissions: "get_permissions",
  sessionStarted: "session_started",
  joinSession: "join_session",
  leaveSession: "participant_left",
  mute: "mute",
  endSession: "end_session",
  telestration: "telestration",
  chat: "chat",
  //APIv1->APIv2 transition only
  getAppointmentInfo: "get_appointment_information",
  //APIv1->APIv2 transition only
  appointmentInfo: "appointment_information",
  retransmit: "retransmit",
  getRecordingStatus: "get_recording_status",
  recordingStatus: "recording_status",
  //APIv1->APIv2 transition only
  inviteUser: "invite_user",
  //APIv1->APIv2 transition only
  listOrganisationUsers: "list_organisation_users",
  broadcast: "broadcast",
  spotlightStream: "spotlight_stream",
  getSpotlightHistory: "get_spotlight_history",
  sessionJoined: "session_joined",
  credentialsRefreshed: "credentials_refreshed",
} as const;

export type ObjectAsEnum<T> = T[keyof T];

export type MediaSessionEventType = ObjectAsEnum<typeof MediaSessionEventType>;

export const MediaSessionEventBroadcastTopics = {
  ptzControl: "ptz_control",
  privacyMask: "privacy_mask",
  videoKicked: "video_kicked",
};

export const MandatoryUserCapabilities = t.type({
  userAgent: t.string,
});
export type MandatoryUserCapabilities = t.TypeOf<
  typeof MandatoryUserCapabilities
>;

export const OptionalUserCapabilities = t.partial({
  language: t.string,
  isTouchSupport: t.boolean,
  screenSize: t.type({
    width: t.number,
    height: t.number,
  }),
  isCookieEnabled: t.boolean,
  logicCores: t.number,
  connectionDownlinkMbps: t.number,
  connectionType: t.string,
  memoryGb: t.number,
});
export type OptionalUserCapabilities = t.TypeOf<
  typeof OptionalUserCapabilities
>;

export const UserCapabilities = t.intersection([
  MandatoryUserCapabilities,
  OptionalUserCapabilities,
  t.record(t.string, t.unknown),
]);
export type UserCapabilities = t.TypeOf<typeof UserCapabilities>;

export enum RequestMediaSessionSyncEndpoint {
  GetSequenceInfo = "get_sequence_info",
  RetransmitEvents = "retransmit_events",
}

export const DetailMediaSessionSyncRetransmitEvents = t.partial({
  lastInOrderSequenceNumber: t.union([t.undefined, t.number]),
  firstOutOfOrderSequenceNumber: t.union([t.undefined, t.number]),
});

export const RequestBodyMediaSessionSync = t.partial({
  endpoint: t.string,
  detail: t.union([t.undefined, DetailMediaSessionSyncRetransmitEvents]),
});

export const DetailMediaSessionSyncGetSequenceInfo = t.type({
  sequenceNumber: t.number,
});

export const ResponseBodyMediaSessionSync = t.type({
  status: t.string,
  detail: t.union([t.undefined, DetailMediaSessionSyncGetSequenceInfo]),
  error: t.union([t.undefined, t.string]),
});

export const RequestBodyMediaSessionEvent = t.type({
  type: t.string,
  detail: t.union([t.undefined, t.unknown]),
  userId: t.string,
  mediaSessionId: t.string,
  mediaClientTimestamp: t.union([t.undefined, DateFromISOString]),
});

export enum MediaSessionEventResponseStatus {
  Success = "success",
  Error = "error",
  Accepted = "accepted",
}

const RequestBodyTransitLog = t.type({
  level: t.string,
  message: t.union([t.string, t.undefined]),
  userId: t.string,
  mediaSessionId: t.string,
  mediaClientTimestamp: nullable(DateFromISOString),
});

// Responses
export const ResponseSessionBroadcast = "broadcast_notification";
export const ResponseSessionEventBroadcast = "broadcast_media_session_event";
export const ResponsePersonalNotification = "personal_notification";
export const ResponseSessionDetails = "session_details";
export const ResponseGetPermissions = "get_permissions";

// Response body types

export const ResponseBodyMediaSessionEvent = t.type({
  type: t.string,
  detail: t.union([t.undefined, t.unknown]),
  userId: t.string,
  mediaSessionId: t.string,
  timestamp: t.number,
  sequenceNumber: t.number,
});

// This replicates the RTCIceServer TypeScript type
const IceServer = t.type({
  username: t.union([t.string, t.undefined]),
  credential: t.union([t.string, t.undefined]),
  urls: t.string,
});

const JanusServerURL = t.type({
  url: t.string,
  httpUrl: t.string,
  apiKey: t.string,
});
export type JanusServerURLPresentation = t.TypeOf<typeof JanusServerURL>;

export const CallStatsCredentials = t.type({
  appId: t.string,
  proxies: t.dictionary(t.string, t.string),
  token: t.string,
  userId: t.string,
});

const MandatoryMediaConnectionDetails = t.type({
  audioServer: JanusServerURL,
  videoServer: JanusServerURL,
  username: t.string,
  credential: t.string,
});

const DcpBrokerConfig = t.type({
  wssUrl: t.string,
  mqttsUrl: t.string,
});
export type DcpBrokerConfig = t.TypeOf<typeof DcpBrokerConfig>;

export const WatchRTCConfig = t.type({
  rtcApiKey: t.string,
  proxyUrl: t.string,
});

const OptionalMediaConnectionDetails = t.partial({
  iceServers: t.array(IceServer),
  // Needs decoding on the media-client side
  startedAt: DateFromISOString,
  callstats: CallStatsCredentials,
  dcpHomeBroker: DcpBrokerConfig,
  dcpSessionBroker: DcpBrokerConfig,
  newRelicLogUrl: t.string,
  watchRTC: WatchRTCConfig,
});

export const MediaConnectionDetails = t.intersection([
  MandatoryMediaConnectionDetails,
  OptionalMediaConnectionDetails,
]);

// Exports

export type RequestBodyMediaSessionEvent = t.TypeOf<
  typeof RequestBodyMediaSessionEvent
>;

export type ResponseBodyMediaSessionEvent = t.TypeOf<
  typeof ResponseBodyMediaSessionEvent
>;

export type RequestBodyTransitLog = t.TypeOf<typeof RequestBodyTransitLog>;

export type MediaConnectionDetails = t.TypeOf<typeof MediaConnectionDetails>;

// admin topic messages
export type RequestBodyMediaSessionSync = t.TypeOf<
  typeof RequestBodyMediaSessionSync
>;

export type DetailMediaSessionSyncGetSequenceInfo = t.TypeOf<
  typeof DetailMediaSessionSyncGetSequenceInfo
>;

export type DetailMediaSessionSyncRetransmitEvents = t.TypeOf<
  typeof DetailMediaSessionSyncRetransmitEvents
>;

export type ResponseBodyMediaSessionSync = t.TypeOf<
  typeof ResponseBodyMediaSessionSync
>;

//TODO - what of the previous definitions will we still need?

export const MediaSessionEventResponseDetailsJoin = t.type({
  profile: profiles.profilePresentation,
  permissions: mediaSessionPermissions.MediaSessionPermissionsPresentation,
  appointment: appointments.AppointmentWithInvitationsPresentation,
});

export const MediaSessionEventResponseDetailsJoinV2 = t.type({
  serverParams: MediaConnectionDetails,
  permissions: mediaSessionPermissions.MediaSessionPermissionsPresentation,
  sessionId: t.string,
});

export type MediaSessionEventResponseDetailsJoinPresentation = t.TypeOf<
  typeof MediaSessionEventResponseDetailsJoin
>;

export const RecordingStatusEvent = t.type({
  isEnabled: t.boolean,
  timestamp: t.number,
  sequenceNumber: t.number,
});

export const SpotlightEvent = t.type({
  streamId: t.union([t.string, t.null]),
  userId: t.string,
  timestamp: t.number,
  sequenceNumber: t.number,
});

//APIv1->APIv2 transition only
export const SpotlightEventV2 = t.type({
  streamId: t.union([t.string, t.null]),
  userId: t.string,
  timestamp: t.number,
  sequenceNumber: t.number,
});

export const RecordingStatusEvents = t.array(RecordingStatusEvent);

export type RecordingStatusEvent = t.TypeOf<typeof RecordingStatusEvent>;

export type RecordingStatusEvents = t.TypeOf<typeof RecordingStatusEvents>;

export const SpotlightEvents = t.array(SpotlightEvent);

export type SpotlightEvent = t.TypeOf<typeof SpotlightEvent>;

export type SpotlightEvents = t.TypeOf<typeof SpotlightEvents>;

//APIv1->APIv2 transition only
export const SpotlightEventsV2 = t.array(SpotlightEventV2);

//APIv1->APIv2 transition only
export type SpotlightEventV2 = t.TypeOf<typeof SpotlightEventV2>;

//APIv1->APIv2 transition only
export type SpotlightEventsV2 = t.TypeOf<typeof SpotlightEventsV2>;

export const MediaSessionEventResponseDetailsUserList = t.array(
  profiles.ProfileNoRelations,
);

export type MediaSessionEventResponseDetailsUserList = t.TypeOf<
  typeof MediaSessionEventResponseDetailsUserList
>;

export type MediaSessionEventResponseDetails =
  | MediaSessionEventResponseDetailsJoinPresentation
  | RecordingStatusEvents
  | MediaSessionEventResponseDetailsUserList
  | SpotlightEvents
  | appointments.AppointmentWithInvitationsPresentation
  // since the io-ts parsing translates all string dates into Date objects
  // we would need an additional type here that replicates the data structures
  // with the dates defined as a string.  Since, I'm not keen on maintaining
  // duplicate (almost identical) types we'll allow an unknown type here
  | unknown;

export type MediaSessionEventDetailsTelestration = {
  streamId: string;
  command: string;
  params?: unknown;
};

export const MediaSessionEventDetailsSpotlight = t.type({
  streamId: t.union([t.string, t.null]),
});

export const MediaSessionEventDetailsPersistenceSpotlight = t.type({
  streamId: t.union([t.string, t.null]),
  userId: t.string,
});

export type MediaSessionEventDetailsSpotlight = t.TypeOf<
  typeof MediaSessionEventDetailsSpotlight
>;

export type MediaSessionEventDetailsPersistenceSpotlight = t.TypeOf<
  typeof MediaSessionEventDetailsPersistenceSpotlight
>;

export type MediaSessionEventDetailsChat = {
  author?: string;
  message: string;
};

export const MediaSessionEventDetailsMute = t.type({
  state: t.boolean,
  streamId: t.union([t.string, t.literal("all")]),
});
export type MediaSessionEventDetailsMute = t.TypeOf<
  typeof MediaSessionEventDetailsMute
>;

export type MediaSessionEventDetailsRetransmit = {
  lastInOrderSequenceNumber?: number;
  firstOutOfOrderSequenceNumber?: number;
};

export const MediaSessionEventDetailsRecordingStatus = t.type({
  isEnabled: t.boolean,
});

export type MediaSessionEventDetailsRecordingStatus = t.TypeOf<
  typeof MediaSessionEventDetailsRecordingStatus
>;

export const MediaSessionEventDetailsInviteUser = t.type({
  invitedUserId: t.string,
});

export type MediaSessionEventDetailsInviteUser = t.TypeOf<
  typeof MediaSessionEventDetailsInviteUser
>;

export enum PtzCommands {
  StatusRequest = "status_request",
  StatusNotification = "status_notification",
  ControlRequest = "control_request",
  ControlCancel = "control_cancel",
  ControlRescind = "control_rescind",
  ControlDeny = "control_deny",
}

export enum PtzMountings {
  Inverted = "inverted",
  Standard = "standard",
}

export const MediaSessionEventDetailsPtzControl = t.union([
  t.type({
    command: t.literal(PtzCommands.StatusRequest),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.StatusNotification),
    controllerId: t.number,
    requesterId: t.number,
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlRequest),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlCancel),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlRescind),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlDeny),
    deviceId: t.string,
  }),
]);

export type MediaSessionEventDetailsPtzControl = t.TypeOf<
  typeof MediaSessionEventDetailsPtzControl
>;

export enum PrivacyMaskActions {
  ConfirmChange = "confirmChange",
  RequestDataTest = "requestData_test",
  RequestData = "requestData",
  ReceiveData = "receiveData",
  ReceiveDataTest = "receiveData_test",
}

export const MediaSessionEventDetailsPrivacyMask = t.union([
  t.type({
    action: t.literal(PrivacyMaskActions.ConfirmChange),
    streamId: t.string,
    masks: t.array(t.any),
  }),
  t.type({
    action: t.literal(PrivacyMaskActions.RequestDataTest),
    streamId: t.string,
  }),
  t.type({
    action: t.literal(PrivacyMaskActions.RequestData),
    streamId: t.string,
  }),
  t.type({
    action: t.literal(PrivacyMaskActions.ReceiveData),
    streamId: t.string,
    masks: t.array(t.any),
  }),
  t.type({
    action: t.literal(PrivacyMaskActions.ReceiveDataTest),
    streamId: t.string,
    masks: t.array(t.any),
  }),
]);

export type MediaSessionEventDetailsPrivacyMask = t.TypeOf<
  typeof MediaSessionEventDetailsPrivacyMask
>;

//APIv1->APIv2 transition only
export const MediaSessionEventDetailsPtzControlV2 = t.union([
  t.type({
    command: t.literal(PtzCommands.StatusRequest),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.StatusNotification),
    controllerId: t.union([t.string, t.null]),
    requesterId: t.union([t.string, t.null]),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlRequest),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlCancel),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlRescind),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlDeny),
    deviceId: t.string,
  }),
]);

//APIv1->APIv2 transition only
export type MediaSessionEventDetailsPtzControlV2 = t.TypeOf<
  typeof MediaSessionEventDetailsPtzControlV2
>;

export const MediaSessionEventDetailsBroadcast = t.type({
  topic: t.string,
  data: t.UnknownRecord,
});

export type MediaSessionEventDetailsBroadcast = t.TypeOf<
  typeof MediaSessionEventDetailsBroadcast
>;

export type MediaSessionEventDetails =
  | MediaSessionEventDetailsTelestration
  | MediaSessionEventDetailsSpotlight
  | MediaSessionEventDetailsChat
  | MediaSessionEventDetailsMute
  | MediaSessionEventDetailsRetransmit
  | MediaSessionEventDetailsRecordingStatus
  | MediaSessionEventDetailsInviteUser
  | appointments.AppointmentWithInvitationsPresentation
  | MediaSessionEventDetailsPtzControl
  | MediaSessionEventDetailsPrivacyMask
  | MediaSessionEventDetailsBroadcast
  // since the io-ts parsing translates all string dates into Date objects
  // we would need an additional type here that replicates the data structures
  // with the dates defined as a string.  Since, I'm not keen on maintaining
  // duplicate (almost identical) types we'll allow an unknown type here
  | unknown;

export type MediaSessionEventNotification = [
  number, // sequenceNumber
  string, // userId, used to be profileId
  MediaSessionEventDetails, // details
  number, // timestamp
];

export type MediaSessionEventStore = [
  number, // sequenceNumber
  string, // topic
  string, // userId, used to be profileId
  MediaSessionEventDetails, // details
  number, // timestamp
];

//TODO - maybe this should be an array too?
export type MediaSessionEventResponse = {
  status: MediaSessionEventResponseStatus;
  sequenceNumber?: number;
  detail?: MediaSessionEventResponseDetails;
};

export type MediaSessionEventObject = {
  sequenceNumber?: number;
  topic?: MediaSessionEventType;
  userId: string;
  detail: MediaSessionEventDetails;
  timestamp?: number;
};

//APIv1->APIv2 transition only
export type MediaSessionEventObjectV2 = {
  sequenceNumber?: number;
  topic?: MediaSessionEventType;
  userId: string;
  detail: MediaSessionEventDetails;
  timestamp?: number;
};

export function makeMediaSessionEventObject(
  userId: string,
  detail: MediaSessionEventDetails,
  timestamp: number = Date.now(),
): MediaSessionEventObject {
  return {
    userId,
    detail,
    timestamp,
  };
}

export function convertMediaSessionEventObjectToMediaSessionEventStore(
  topic: MediaSessionEventType,
  event: MediaSessionEventObject,
): MediaSessionEventStore {
  return [0, topic, event.userId, event.detail, event.timestamp || Date.now()];
}

export function extractTopicFromMediaSessionEventStore(
  store: MediaSessionEventStore,
): string {
  return store[1];
}

export function convertMediaSessionEventStoreToMediaSessionEventNotification(
  store: MediaSessionEventStore,
): MediaSessionEventNotification {
  return store.filter(
    (_, i) => i !== 1,
  ) as unknown as MediaSessionEventNotification;
}

export function convertMediaSessionEventNotificationToMediaSessionEventObject(
  notification: MediaSessionEventNotification,
): MediaSessionEventObject {
  return {
    sequenceNumber: notification[0],
    userId: notification[1],
    detail: notification[2],
    timestamp: notification[3],
  };
}
