import { Typography } from "@proximie/components";
import React, { FC } from "react";
import VideoStatsStyled from "./VideoStats.style";
import { WebRTCUtil, IceProtocol, IceType } from "@proximie/media";
export interface VideoStatsProps {
  packetLoss?: number;
  fps?: number;
  bitrate?: number;
  protocol?: IceProtocol;
  type?: IceType;
}

const VideoStats: FC<VideoStatsProps> = (props: VideoStatsProps) => {
  const parseType = (type: IceType): string => {
    switch (type) {
      case "relay":
        return "TURN";
      case "prflx":
      case "srflx":
        return "STUN";
      default:
        return type.toUpperCase();
    }
  };

  return (
    <VideoStatsStyled>
      <ul>
        {!!props.fps && (
          <li>
            <Typography variant="body2">
              FPS: {Math.round(props.fps)}
            </Typography>
          </li>
        )}
        {!!props.bitrate && (
          <li>
            <Typography variant="body2">
              Bitrate: {WebRTCUtil.bitrateToReadable(props.bitrate)}
            </Typography>
          </li>
        )}
        {!!props.packetLoss && (
          <li>
            <Typography variant="body2">
              Packet Loss: {Math.round(100 * props.packetLoss) / 100}%
            </Typography>
          </li>
        )}
        {!!props.protocol && (
          <li>
            <Typography variant="body2">
              Protocol: {props.protocol.toUpperCase()}
            </Typography>
          </li>
        )}
        {!!props.type && (
          <li>
            <Typography variant="body2">
              Type: {parseType(props.type)}
            </Typography>
          </li>
        )}
      </ul>
    </VideoStatsStyled>
  );
};

export default VideoStats;
