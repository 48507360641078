import { Controls, ModeType } from "./Quality";
import { OUTBOUND_CAMERA_QUALITY_LIMITS } from "../../Constants";
import QualityVideoOutgoing, {
  QualityLevel,
  SimulcastLayers,
} from "./QualityVideoOutgoing";
import { JanusJS } from "@proximie/janus-gateway";

type SimulcastLayerInfo = {
  // as a percentage of totalBitratePc.  if zero then the layer is not active
  bitratePc: number;
  scale: number;
};

type QualityLevelInfo = {
  height: number;
  width: number;
  // bitrate to allocate the this level as a percentage of the total bitrate
  totalBitratePc: number;
  layers: {
    [SimulcastLayers.High]: SimulcastLayerInfo;
    [SimulcastLayers.Medium]: SimulcastLayerInfo;
    [SimulcastLayers.Low]: SimulcastLayerInfo;
  };
};

const QUALITY_INFO: QualityLevelInfo[] = [
  // level 0 - QualityLevel.Low
  {
    height: 360,
    width: 640,
    totalBitratePc: 20,
    layers: {
      high: {
        bitratePc: 75,
        scale: 1,
      },
      medium: {
        bitratePc: 25,
        scale: 2, // 180p
      },
      low: {
        bitratePc: 0,
        scale: 10,
      },
    },
  },
  // level 1 - QualityLevel.Medium
  {
    height: 480,
    width: 854,
    totalBitratePc: 40,
    layers: {
      high: {
        bitratePc: 75,
        scale: 1,
      },
      medium: {
        bitratePc: 25,
        scale: 24 / 9, // 180p
      },
      low: {
        bitratePc: 0,
        scale: 10,
      },
    },
  },
  // level 2 - QualityLevel.High
  {
    height: 720,
    width: 1280,
    // we allow 5% below the maximum just in case!
    totalBitratePc: 95,
    layers: {
      high: {
        bitratePc: 75,
        scale: 1,
      },
      medium: {
        bitratePc: 20,
        scale: 2, // 360p,
      },
      low: {
        bitratePc: 5,
        scale: 4, // 180p
      },
    },
  },
];

export default class QualityVideoOutgoingCamera extends QualityVideoOutgoing {
  private constraints: MediaStreamConstraints = {
    audio: false,
    video: {
      width: { ideal: 1280 },
      height: { ideal: 720 },
      frameRate: { max: 24, ideal: 24 },
    },
  };
  static defaultConstraints: MediaStreamConstraints = {
    audio: false,
    video: {
      width: { ideal: 1280 },
      height: { ideal: 720 },
      frameRate: { max: 24, ideal: 24 },
    },
  };

  static override getInitialConstraints(
    deviceId?: string,
  ): MediaStreamConstraints {
    const constraints = { ...this.defaultConstraints };
    if (deviceId) {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      (constraints.video as any).deviceId = { exact: deviceId };
    }
    return constraints;
  }

  private getQualityValueFromIdealHeightOrThrow(
    height: ConstrainULongRange,
  ): number {
    if (typeof height.ideal === "undefined") {
      throw new Error("Ideal height not specified");
    }
    const index = QUALITY_INFO.findIndex(
      (quality: QualityLevelInfo): boolean => quality.height === height.ideal,
    );
    if (index === -1) {
      throw new Error("Ideal height not found");
    }
    return index;
  }

  public start(streamId: string, handle: JanusJS.PluginHandle) {
    super.start(streamId, handle);

    const video = this.constraints.video as MediaTrackConstraints;
    const startValue = this.getQualityValueFromIdealHeightOrThrow(
      video.height as ConstrainULongRange,
    );
    this.setResolution(startValue);
  }

  public controls: Controls = {
    mode: {
      value: ModeType.Automatic,
      options: [
        { value: ModeType.Manual, label: "Manual" },
        { value: ModeType.Automatic, label: "Automatic" },
      ],
      onChange: async (newValue: number): Promise<void> => {
        console.debug(
          {
            streamId: this.streamId,
          },
          "QualityVideoInCam: auto value=",
          newValue,
        );
        this.controls.mode.value = Number(!!newValue);
        this.reset();
      },
    },
    resolution: {
      value: QualityLevel.High,
      options: [
        {
          value: QualityLevel.High,
          label: QualityLevel[QualityLevel.High],
        },
        {
          value: QualityLevel.Medium,
          label: QualityLevel[QualityLevel.Medium],
        },
        {
          value: QualityLevel.Low,
          label: QualityLevel[QualityLevel.Low],
        },
      ],
      onChange: async (newValue: number): Promise<void> => {
        console.debug(
          {
            streamId: this.streamId,
          },
          "QualityVideoInScr: resolution value=",
          newValue,
        );
        if (newValue < QualityLevel.Low || newValue > QualityLevel.High) {
          console.warn(
            {
              streamId: this.streamId,
            },
            "ualityVideoOutScr: Invalid resolution=",
            newValue,
          );
          return;
        }
        if (!this.isAutoMode) {
          console.debug(
            {
              streamId: this.streamId,
            },
            "QualityVideoOutCam: resolution value=",
            newValue,
          );
          try {
            await this.setResolution(newValue);
          } catch {
            //ignore error
          }
        }
      },
    },
  };

  private get isAutoMode() {
    return !!this.controls.mode.value;
  }

  protected limits = OUTBOUND_CAMERA_QUALITY_LIMITS;

  protected downgrade(): boolean {
    if (!this.isAutoMode) {
      // we're not in automatic mode - ignore
      return false;
    }

    if (this.controls.resolution.value === QualityLevel.Low) {
      return false;
    }

    console.log(
      {
        streamId: this.streamId,
      },
      "OUTBOUND ALG downgrade",
      this.controls.resolution.value,
    );

    this.reset();

    this.setResolution(this.controls.resolution.value - 1);

    return true;
  }

  protected upgrade(): boolean {
    if (!this.isAutoMode) {
      // we're not in automatic mode - ignore
      return false;
    }

    if (this.controls.resolution.value >= QualityLevel.High) {
      return false;
    }

    console.log(
      {
        streamId: this.streamId,
      },
      "OUTBOUND ALG upgrade",
      this.controls.resolution.value,
    );

    this.reset();

    this.setResolution(this.controls.resolution.value + 1);

    return true;
  }

  updateVideoTrack(constraints: MediaTrackConstraints): Promise<void> {
    const sender = (
      this.handle?.webrtcStuff.pc as unknown as RTCPeerConnection
    ).getSenders()?.[0];
    if (!sender || !sender.track) {
      throw new Error("No track to update");
    }
    return sender.track.applyConstraints(constraints);
  }

  get resolution() {
    return this.controls.resolution.value;
  }

  set resolution(value: number) {
    const oldValue = this.controls.resolution.value;
    this.controls.resolution.value = value;
    this.emit("resolution", oldValue, value);
    this.reset();
  }

  async setResolution(value: number): Promise<void> {
    console.debug(
      {
        streamId: this.streamId,
      },
      "VideoOutCam:setResolution - value=",
      value,
    );

    const constraints = this.constraints.video as MediaTrackConstraints;

    if (
      !constraints ||
      typeof constraints.width !== "object" ||
      typeof constraints.width.ideal !== "number" ||
      typeof constraints.height !== "object" ||
      typeof constraints.height.ideal !== "number"
    ) {
      console.warn(
        {
          streamId: this.streamId,
        },
        "Invalid constraints",
      );
      return;
    }

    const newHeight = QUALITY_INFO[value].height;

    //update constraints
    constraints.height.ideal = newHeight;
    constraints.width.ideal = QUALITY_INFO[value].width;

    // update track with new constraints
    await this.updateVideoTrack(constraints);

    const sender = (
      this.handle?.webrtcStuff.pc as unknown as RTCPeerConnection
    ).getSenders()?.[0];
    if (!sender) {
      throw new Error("No sender");
    }

    // restrict bit rate dependent on the current frame rate as a proportion of maximum
    const maxFrameRate = (constraints.frameRate as { max: number })?.max;
    const currentFrameRate = sender.track?.getSettings().frameRate;
    const frameRateMultiplier =
      maxFrameRate && currentFrameRate ? currentFrameRate / maxFrameRate : 1.0;

    const parameters = sender.getParameters();

    parameters.encodings = [
      {
        rid: "h",
        active: this.isSimulcastActive(value, SimulcastLayers.High),
        maxBitrate:
          this.getSimulcastBitrate(value, SimulcastLayers.High) *
          frameRateMultiplier,
      },
      {
        rid: "m",
        active: this.isSimulcastActive(value, SimulcastLayers.Medium),
        maxBitrate:
          this.getSimulcastBitrate(value, SimulcastLayers.Medium) *
          frameRateMultiplier,
        scaleResolutionDownBy: this.getSimulcastScale(
          value,
          SimulcastLayers.Medium,
        ),
      },
      {
        rid: "l",
        active: this.isSimulcastActive(value, SimulcastLayers.Low),
        maxBitrate:
          this.getSimulcastBitrate(value, SimulcastLayers.Low) *
          frameRateMultiplier,
        scaleResolutionDownBy: this.getSimulcastScale(
          value,
          SimulcastLayers.Low,
        ),
      },
    ];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    parameters.degradationPreference = "maintain-framerate";

    console.debug(
      {
        streamId: this.streamId,
      },
      "VideoOutCam:setResolution - encodings=",
      parameters.encodings,
    );

    await sender.setParameters(parameters);

    this.resolution = value;
  }

  // workaround to get at static initial constraints when we have the instance
  get initialConstraints(): MediaStreamConstraints {
    return QualityVideoOutgoingCamera.getInitialConstraints();
  }

  override isSimulcastActive(
    level: QualityLevel,
    layer: SimulcastLayers,
  ): boolean {
    return QUALITY_INFO[level].layers[layer].bitratePc !== 0;
  }

  override getSimulcastBitrate(
    level: QualityLevel,
    layer: SimulcastLayers,
  ): number {
    if (typeof this.options.bitrate === "undefined") {
      return 0;
    }

    const bitrate =
      this.options.bitrate * (QUALITY_INFO[level].totalBitratePc / 100);

    return (
      bitrate * ((QUALITY_INFO[level].layers[layer]?.bitratePc || 0) / 100)
    );
  }

  override getSimulcastScale(
    level: QualityLevel,
    layer: SimulcastLayers,
  ): number {
    return QUALITY_INFO[level].layers[layer]?.scale || 0;
  }
}
