import Quality, { QualityMetrics, Controls, SummaryStats } from "./Quality";

export default abstract class QualityVideoIncoming extends Quality {
  public abstract controls: Controls | null;

  protected generateSummaryStats(report: RTCStatsReport): SummaryStats {
    const summary: SummaryStats = {};

    report.forEach((stat /* RTCStats */): void => {
      summary.timestamp = stat.timestamp;

      switch (stat.type) {
        case "inbound-rtp":
          summary.packetsTransferred = stat.packetsReceived;
          summary.packetsLost = stat.packetsLost;
          summary.framesTransferred = stat.framesReceived;
          summary.bytesTransferred = stat.bytesReceived;
          summary.framesProcessed = stat.framesDecoded;
          summary.totalProcessingTime = stat.totalDecodeTime;
          summary.width = stat.frameWidth;
          summary.height = stat.frameHeight;
          break;
        case "candidate-pair":
          if (stat.state === "succeeded") {
            summary.totalRoundTripTime = stat.totalRoundTripTime;
            summary.responsesReceived = stat.responsesReceived;
          }
          break;
        default:
        // do nothing
      }
    });

    return summary;
  }

  protected override debug(metrics: QualityMetrics): void {
    console.debug(
      { streamId: this.streamId },
      `INBOUND STATS ${JSON.stringify(metrics)}`,
    );
  }
}
