import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { UserRole } from "./UserRole";

const getUserRole: (
  sessionId: string,
  participantId: string,
) => () => Promise<UserRole> =
  (sessionId: string, participantId: string) => () =>
    axios.get(
      `${environment.apiUrl}/api/v2/sessions/${sessionId}/participants/${participantId}/roles`,
    );

export const useUserRole = (
  sessionId: string | undefined,
  participantId: string | undefined,
  opts?: SWRConfiguration,
) => {
  const { data, error } = useSWR<UserRole>(
    sessionId != null && participantId != null ? `role` : null,
    getUserRole(sessionId || "", participantId || ""),
    opts,
  );

  return {
    role: data?.name,
    isRoleLoading: !error && !data,
    roleError: error,
  };
};
