import { EventEmitter } from "events";
import { isEqual } from "lodash";
import { newTagSVG, oldTagSVG } from "./Constants";
import Device from "./ServerAdapter/Devices/Device";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const VideoFrame = (window as any).VideoFrame;

export enum Shapes {
  Rect,
  Elip,
}

export interface RectMask {
  shape: Shapes.Rect;
  x: number;
  y: number;
  w: number;
  h: number;
  a: number;
}

export interface ElipMask {
  shape: Shapes.Elip;
  x: number;
  y: number;
  rx: number;
  ry: number;
  a: number;
}

const oldTag = new Image();
oldTag.src =
  "data:image/svg+xml;charset=utf-8," + encodeURIComponent(oldTagSVG);
const newTag = new Image();
newTag.src =
  "data:image/svg+xml;charset=utf-8," + encodeURIComponent(newTagSVG);

export const degToRad = (deg: number): number => (deg * Math.PI) / 180;

export const generateMaskSprite = (
  masks: Array<RectMask | ElipMask>,
  vw: number,
  vh: number,
  color?: string,
  strike?: boolean,
  tag?: HTMLImageElement,
  compareMasks?: Array<RectMask | ElipMask>,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const canvas1 = document.createElement("canvas");
  canvas1.height = vh;
  canvas1.width = vw;
  const ctx1 = canvas1.getContext("2d");
  if (!ctx1) return canvas1;
  ctx1.fillStyle = color ? "rgba(0,0,0,0)" : "rgba(255,255,255,1)";
  if (color) {
    ctx1.strokeStyle = color;

    if (strike) {
      const spacing = Math.round(vh * (16 / 720));
      const ptrnCanvas = document.createElement("canvas");
      ptrnCanvas.height = spacing;
      ptrnCanvas.width = spacing;
      const ptrnCtx = ptrnCanvas.getContext("2d");
      if (ptrnCtx) {
        ptrnCtx.globalAlpha = 0.3;
        ptrnCtx.strokeStyle = color;
        ptrnCtx.fillStyle = color;
        ptrnCtx.lineWidth = 1;

        ptrnCtx.beginPath();
        ptrnCtx.moveTo(spacing, 0);
        ptrnCtx.lineTo(0, spacing);
        ptrnCtx.stroke();
      }
      const ptrn = ctx1.createPattern(ptrnCanvas, "repeat");
      ctx1.fillStyle = ptrn as unknown as string;
    }
  }

  const drawShape = (
    ctx: CanvasRenderingContext2D,
    mask: RectMask | ElipMask,
  ) => {
    ctx.beginPath();
    if (mask.shape === Shapes.Rect) {
      const x = mask.x * vw;
      const y = mask.y * vh;
      const w = mask.w * vw;
      const h = mask.h * vh;

      ctx.save();

      ctx.translate(x, y);
      ctx.rotate(degToRad(mask.a));
      ctx.rect(-w / 2, -h / 2, w, h);
      ctx.fill();
      ctx.stroke();
      if (tag && compareMasks) {
        const exist = compareMasks.find((m) => isEqual(m, mask));
        if (!exist) ctx.drawImage(tag, -oldTag.width / 2, h / 2);
      }
      ctx.restore();
    } else if (mask.shape === Shapes.Elip) {
      const x = Math.round(mask.x * vw);
      const y = Math.round(mask.y * vh);
      const w = Math.round(mask.rx * vw);
      const h = Math.round(mask.ry * vh);
      ctx.ellipse(x, y, w, h, degToRad(mask.a), 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
      if (tag && compareMasks) {
        const exist = compareMasks.find((m) => isEqual(m, mask));
        if (!exist) {
          ctx.save();
          ctx.translate(x, y);
          ctx.rotate(degToRad(mask.a));
          ctx.drawImage(tag, -oldTag.width / 2, h);
          ctx.restore();
        }
      }
    }
  };
  masks.forEach((mask) => {
    drawShape(ctx1, mask);
  });
  return canvas1;
};

export const browserSupportsPrivacyControlTool = () =>
  "MediaStreamTrackProcessor" in window &&
  "MediaStreamTrackGenerator" in window;

export const MaskHandlerEvents = {
  ObjectSelectedChanged: "isObjectSelectedChanged",
  HistoryChanged: "historyChanged",
  SelectedMaskingShapeChanged: "selectedMaskingShapeChanged",
  IsEraseModeChanged: "isEraseModeChanged",
  Deselect: "deselect",
  DeleteObject: "deleteObject",
};

interface VideoMaskHandlerConstructor {
  stream?: MediaStream | null;
  masks?: Array<RectMask | ElipMask>;
  device: Device;
  isEdit?: boolean;
  streamId?: string;
  isRemoteEdit?: boolean;
}

type FrameStore = {
  frame: typeof VideoFrame;
  timestamp: Date;
};

export class VideoMaskHandler extends EventEmitter {
  private inputStream: MediaStream | null = null;
  private inputTrack: MediaStreamTrack | null = null;
  private outputStream: MediaStream | null = null;
  private isDead = false;
  private device: Device;
  private isEdit: boolean;
  private isRemoteEdit: boolean;
  private streamId: string;

  private masks: Array<RectMask | ElipMask> = [];
  private originMasks: Array<RectMask | ElipMask> = [];
  private isMasksChanged = true;
  private isOriginMasksChanged = false;
  private last: FrameStore | null = null;
  private interval: any;
  public hasEverBeenMasked = false;

  //CONSTANTS
  private blurValue = 25;

  //HISTORY
  private maskHistory: Array<Array<ElipMask | RectMask>> = [[]];
  private historyIndex = 0;

  addHistory(item: Array<ElipMask | RectMask>) {
    this.maskHistory = [item, ...this.maskHistory.slice(this.historyIndex)];
    this.historyIndex = 0;
    this.emit(MaskHandlerEvents.HistoryChanged, null);
  }

  undo() {
    if (this.historyIndex > this.maskHistory.length - 2) return;
    this.historyIndex = this.historyIndex + 1;
    this.setMasks(this.maskHistory[this.historyIndex]);
    this.emit(MaskHandlerEvents.HistoryChanged, null);
  }

  clearAllMasks() {
    if (!this.containsMasks()) return;
    this.addHistory([]);
    this.setMasks([]);
    this.emit(MaskHandlerEvents.HistoryChanged, null);
  }

  containsMasks() {
    return this.masks.length > 0;
  }

  redo() {
    if (this.historyIndex < 1) return;
    this.historyIndex = this.historyIndex - 1;
    this.setMasks(this.maskHistory[this.historyIndex]);
    this.emit(MaskHandlerEvents.HistoryChanged, null);
  }

  canUndo() {
    return this.historyIndex < this.maskHistory.length - 1;
  }

  canRedo() {
    return this.maskHistory.length > 0 && this.historyIndex > 0;
  }

  //UI

  private selectedMaskingShape: Shapes | null = null;

  setSelectedMaskingShape(shape: Shapes | null) {
    this.selectedMaskingShape = shape;
    this.emit(MaskHandlerEvents.SelectedMaskingShapeChanged, null);
  }

  getSelectedMaskingShape() {
    return this.selectedMaskingShape;
  }

  private isEraseMode: boolean | null = null;

  setIsEraseMode(eraseMode: boolean | null) {
    this.isEraseMode = eraseMode;
    this.emit(MaskHandlerEvents.IsEraseModeChanged, null);
  }

  getIsEraseMode() {
    return this.isEraseMode;
  }

  private isObjectSelected = false;

  setIsObjectSelected(isSelected: boolean) {
    this.isObjectSelected = isSelected;
    this.emit(MaskHandlerEvents.ObjectSelectedChanged, null);
  }

  getIsObjectSelected() {
    return this.isObjectSelected;
  }

  deselect() {
    this.emit(MaskHandlerEvents.Deselect, null);
  }

  emitDelete() {
    this.emit(MaskHandlerEvents.DeleteObject, null);
  }

  //class logic
  private lastState = {
    h: 0,
    w: 0,
    spriteDark: document.createElement("canvas"),
    originSprite: document.createElement("canvas"),
    originUpdateSprite: document.createElement("canvas"),
  };

  outputCanvas = document.createElement("canvas");
  private outputCtx = this.outputCanvas.getContext("2d");
  private tempCanvas = document.createElement("canvas");
  private tempCtx = this.tempCanvas.getContext("2d");

  constructor({
    device,
    stream = null,
    masks = [],
    isEdit = false,
    streamId = "",
    isRemoteEdit = false,
  }: VideoMaskHandlerConstructor) {
    super();

    this.setMasks(masks, true);

    this.inputStream = stream;
    this.device = device;
    this.isEdit = isEdit;
    this.streamId = streamId;
    this.isRemoteEdit = isRemoteEdit;

    if (!this.inputStream) {
      console.log("No stream - ignoring");
      return;
    }

    if (!browserSupportsPrivacyControlTool()) {
      this.outputStream = stream;
      throw new Error(
        // "Browser does not support MediaStreamTrackProcessor and MediaStreamTrackGenerator",
        "Browser does not support required features",
      );
    }

    const [inputTrack] = this.inputStream.getVideoTracks();
    this.inputTrack = inputTrack;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trackProcessor = new (window as any).MediaStreamTrackProcessor({
      track: inputTrack,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trackGenerator = new (window as any).MediaStreamTrackGenerator({
      kind: "video",
    });

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const masker = this;

    const transformer = new TransformStream({
      start(controller) {
        masker.interval = setInterval(async () => {
          try {
            if (
              !masker.isDead &&
              masker.last &&
              Date.now() - masker.last.timestamp.getTime() >= 2000
            ) {
              // Frame stalled for some time, repeating last frame
              await masker.handleFrames(masker.last.frame, controller);
            }
          } catch (error) {
            console.error("Cannot repeat frame - error=", error);
            masker.end(controller);
          }
        }, 300); //change this value to control idle framerate
      },

      async transform(videoFrame, controller) {
        if (masker.isDead) {
          masker.end(controller);
          videoFrame.close();
          return;
        }

        masker.saveLastFrame(videoFrame);

        try {
          await masker.handleFrames(videoFrame, controller);
        } catch (error) {
          console.error("Cannot handle frame - error=", error);
          masker.end(controller);
        }
      },

      flush(controller) {
        masker.end(controller);
      },
    });

    trackProcessor.readable
      .pipeThrough(transformer)
      .pipeTo(trackGenerator.writable);

    this.outputStream = new MediaStream();
    this.outputStream.addTrack(trackGenerator);

    const [outputTrack] = this.outputStream.getVideoTracks();
    this.outputStream.getVideoTracks = () => [inputTrack];

    outputTrack.getCapabilities = () => inputTrack.getCapabilities();

    outputTrack.getSettings = () => inputTrack.getSettings();
    outputTrack.getConstraints = () => inputTrack.getConstraints();
    outputTrack.applyConstraints = (constraints: MediaTrackConstraints) =>
      inputTrack.applyConstraints(constraints);
  }

  private end(controller: TransformStreamDefaultController<any>): void {
    clearInterval(this.interval);
    controller.terminate();
    this.last?.frame.close();
    this.last = null;
  }

  private async handleFrames(
    videoFrame: typeof VideoFrame,
    controller: TransformStreamDefaultController<any>,
  ): Promise<void> {
    if (this.masks.length === 0) {
      controller.enqueue(videoFrame.clone());
    } else {
      const {
        displayHeight, //actual frame size
        displayWidth, // actual frame size
        timestamp,
      } = videoFrame;

      const bitmap = await createImageBitmap(videoFrame, {
        resizeWidth: displayWidth,
        resizeHeight: displayHeight,
      });
      //apply effect
      const newFrame = this.applyBlur(bitmap, timestamp);
      controller.enqueue(newFrame);
      bitmap.close();
    }
  }

  saveLastFrame(frame: typeof VideoFrame): void {
    this.last?.frame.close();
    this.last = { frame, timestamp: new Date() };
  }

  stopTracks() {
    this.inputStream?.getTracks &&
      this.inputStream?.getTracks().forEach((track) => {
        track.stop();
      });
  }

  destroy(): void {
    if (this.inputStream) {
      this.inputStream = null;
    }
    if (this.outputStream) {
      this.outputStream = null;
    }

    clearInterval(this.interval);

    this.isDead = true;
  }

  getStream(): MediaStream | null {
    return this.outputStream;
  }

  setMasks(masks: Array<RectMask | ElipMask>, init?: boolean): void {
    this.masks = masks;
    this.isMasksChanged = true;
    if (init) this.maskHistory = [masks];

    if (masks.length > 0) {
      this.hasEverBeenMasked = true;
    }
  }

  setOriginMasks(masks: Array<RectMask | ElipMask>): void {
    this.originMasks = masks;
    this.isOriginMasksChanged = true;
  }

  getMasks() {
    return this.masks;
  }

  getInputStream() {
    return this.inputStream;
  }

  getDevice() {
    return this.device;
  }

  getIsEdit() {
    return this.isEdit;
  }

  getStreamId() {
    return this.streamId;
  }

  getIsRemoteEdit() {
    return this.isRemoteEdit;
  }

  //for insertable stream
  // eslint-disable-next-line sonarjs/cognitive-complexity
  private applyBlur(
    videoFrame: ImageBitmap,
    timestamp: number,
  ): typeof VideoFrame {
    const h = videoFrame.height;
    const w = videoFrame.width;

    //exit loop here if no masks
    if (
      (this.masks.length === 0 && this.originMasks.length === 0) ||
      !this.outputCtx ||
      !this.tempCtx
    ) {
      return new (window as any).VideoFrame(videoFrame, { timestamp });
    }

    //return if no video size
    if (!w || !h) {
      return new (window as any).VideoFrame(this.outputCanvas, { timestamp });
    }

    //update canvas sizes only on change
    if (this.lastState.w !== w || this.lastState.h !== h) {
      this.outputCanvas.height = h;
      this.outputCanvas.width = w;

      this.tempCanvas.height = h;
      this.tempCanvas.width = w;

      this.blurValue = Math.round((h * 25) / 720);
    }

    //update sprites only on change
    if (
      this.isMasksChanged ||
      this.lastState.w !== w ||
      this.lastState.h !== h ||
      this.isOriginMasksChanged
    ) {
      this.lastState.spriteDark = generateMaskSprite(this.masks, w, h);
      this.isMasksChanged = false;

      if (this.originMasks.length) {
        this.lastState.originUpdateSprite = generateMaskSprite(
          this.masks,
          w,
          h,
          "rgb(61,90,254)",
          false,
          newTag,
          this.originMasks,
        );
        this.lastState.originSprite = generateMaskSprite(
          this.originMasks,
          w,
          h,
          "rgb(255,0,0)",
          true,
          oldTag,
          this.masks,
        );
        this.isOriginMasksChanged = false;
      }
    }

    this.lastState.w = w;
    this.lastState.h = h;

    // // //draw base image
    this.outputCtx.globalCompositeOperation = "source-over";
    this.outputCtx.drawImage(videoFrame, 0, 0);

    //draw origin sprite in red
    if (this.originMasks.length) {
      this.outputCtx.drawImage(this.lastState.originSprite, 0, 0);
    }

    if (this.masks.length) {
      //clip mask
      this.outputCtx.globalCompositeOperation = "destination-out";
      this.outputCtx.drawImage(
        this.lastState.spriteDark,
        0,
        0,
        w,
        h,
        0,
        0,
        w,
        h,
      );

      // draw blur on temp
      this.tempCtx.globalCompositeOperation = "copy";
      this.tempCtx.filter = `blur(${this.blurValue}px`;
      this.tempCtx.drawImage(videoFrame, 0, 0, w, h, 0, 0, w, h);

      // //draw blur over clipped part
      this.outputCtx.globalCompositeOperation = "destination-over";
      this.outputCtx.drawImage(this.tempCanvas, 0, 0, w, h, 0, 0, w, h);

      //draw updated highligh sprite in cyan
      if (this.originMasks.length) {
        this.outputCtx.globalCompositeOperation = "source-over";
        this.outputCtx.drawImage(this.lastState.originUpdateSprite, 0, 0);
      }
    }

    return new (window as any).VideoFrame(this.outputCanvas, { timestamp });
  }
}
