import { ServerParams, FeedType } from "../../index";
import ConnectionMetadata from "../models/ConnectionMetadata";
import CallStats, { CallStatsParams } from "./CallStats";
import { decodeStreamId, encodeStreamId } from "../MediaUtil";
import { init } from "./Init/Janus";
import Endpoint from "./Endpoints/Endpoint";
import EndpointDcpSession from "./Endpoints/EndpointDcpSession";
import EndpointVirtual from "./Endpoints/EndpointVirtual";
import MonitorDeviceDcp from "./Monitors/MonitorDeviceDcp";
import MonitorDeviceLocalCamera from "./Monitors/MonitorDeviceLocalCamera";
import MonitorDeviceLocalMicrophone from "./Monitors/MonitorDeviceLocalMicrophone";
import MonitorDeviceLocalSpeaker from "./Monitors/MonitorDeviceLocalSpeaker";
import MonitorDeviceLocal from "./Monitors/MonitorDeviceLocal";
import MonitorConnectionJanusVideo from "./Monitors/MonitorConnectionJanusVideo";
import MonitorCamera from "./Monitors/MonitorCamera";
import Device, { DeviceType } from "./Devices/Device";
import DeviceVirtual from "./Devices/DeviceVirtual";
import DeviceLocalMicrophone from "./Devices/DeviceLocalMicrophone";
import DeviceLocalSpeaker from "./Devices/DeviceLocalSpeaker";
import DeviceIncoming from "./Devices/DeviceIncoming";
import DeviceDcpInvitee from "./Devices/DeviceDcpInvitee";
import DeviceDcp, { DcpType } from "./Devices/DeviceDcp";
import EndpointJanusAudio from "./Endpoints/Janus/EndpointJanusAudio";
import EndpointJanusVideo from "./Endpoints/Janus/EndpointJanusVideo";
import Connection, {
  ConnectionGlobals,
  ConnectionType,
  Direction,
} from "./Connections/Connection";
import ConnectionJanusAudio from "./Connections/Janus/ConnectionJanusAudio";
import ConnectionDcpVideoOutgoing from "./Connections/Dcp/ConnectionDcpVideoOutgoing";
import QualityAudio from "./Quality/QualityAudio";
import { BITRATE, CODEC, ENDPOINT_CONFIG } from "../Constants";
import { ExceptionCodes, exceptions } from "@proximie/common";
import { IncomingVideosCallback } from "../models/IncomingVideo";
import { OutgoingVideosCallback } from "../models/OutgoingVideo";
import WebRTCUtil from "../WebRTCUtil";
import readDeviceId from "./utils/readDeviceId";
import watchRTC from "@testrtc/watchrtc-sdk";

enum State {
  NONE,
  INITIALISING,
  ACTIVE,
  FINISHING,
  DESTROYED,
}

export type StartArgs = {
  sessionId: string;
  userId: string;
  serverParams: ServerParams;
  callStatsParams?: CallStatsParams;
  tokenProvider: () => string;
  incomingVideosCallback: IncomingVideosCallback;
  outgoingVideosCallback: OutgoingVideosCallback;
};

export default abstract class ServerAdapter {
  private static currState: State = State.NONE;
  //APIv1->APIv2 transition only
  public static userId = ""; //TODO

  private static incomingVideosCallback: IncomingVideosCallback;
  private static outgoingVideosCallback: OutgoingVideosCallback;

  private static videoConnections: Record<string, Connection> = {};

  private static _dcpEndpoint: EndpointDcpSession | null = null;

  //LATER - this be private when PTZ functionality moves into the ServerAdapter
  private static _dcpMonitor: MonitorDeviceDcp | null = null;

  private static localMonitors: {
    audioinput: MonitorDeviceLocalMicrophone | null;
    audiooutput: MonitorDeviceLocalSpeaker | null;
    videoinput: MonitorDeviceLocalCamera | null;
  } = {
    audioinput: null,
    audiooutput: null,
    videoinput: null,
  };

  private static _cameraMonitor: MonitorCamera | null = null;

  private static audioRoom: EndpointJanusAudio | null = null;
  private static _audioConnection: ConnectionJanusAudio | null = null;

  private static videoRoom: EndpointJanusVideo | null = null;
  private static videoMonitor: MonitorConnectionJanusVideo | null = null;

  private static inviteeDevice: DeviceDcpInvitee | null = null;
  private static _hostDeviceId = "";
  private static connectionGlobals: ConnectionGlobals = {};
  private static hasWatchRTC = false;

  static isInitialised() {
    return this.currState === State.ACTIVE;
  }

  static isIdle() {
    return this.currState === State.NONE;
  }

  private static async createAndConnectToAudiobridge(
    args: StartArgs,
  ): Promise<EndpointJanusAudio> {
    const audioRoom = new EndpointJanusAudio({
      sessionId: args.sessionId,
      userId: args.userId,
      serverUrl: args.serverParams.audioServer,
      iceServers: args.serverParams.iceServers || [],
      iceTransportPolicy: "relay",
    });

    audioRoom.on("closed", (): void => {
      console.warn("ServerAdapter: Audio room closed");
      this.finish().catch(() => {
        /* ignore */
      });
    });

    await audioRoom.connect();

    return audioRoom;
  }

  private static async createAndConnectToVideoroom(
    args: StartArgs,
  ): Promise<EndpointJanusVideo> {
    let bitrate = BITRATE;
    let codec = CODEC;
    if (localStorage.getItem("hackVideoCodecs")) {
      codec = localStorage.getItem("hackVideoCodecs") || codec;
    }
    if (localStorage.getItem("hackBitrate")) {
      bitrate = Number(localStorage.getItem("hackBitrate")) || bitrate;
    }

    const videoRoom = new EndpointJanusVideo({
      sessionId: args.sessionId,
      userId: args.userId,
      serverUrl: args.serverParams.videoServer,
      iceServers: args.serverParams.iceServers || [],
      iceTransportPolicy: "relay",
      codec,
      bitrate,
    });

    videoRoom.on("closed", (): void => {
      console.warn("ServerAdapter: Video room closed");
      this.finish().catch(() => {
        /* ignore */
      });
    });

    await videoRoom.connect();

    return videoRoom;
  }

  private static startRTCMonitoring(args: StartArgs): void {
    if (args.serverParams.watchRTC?.rtcApiKey) {
      try {
        watchRTC.init({
          ...args.serverParams.watchRTC,
          rtcRoomId: args.sessionId,
          rtcPeerId: args.userId,
          keys: args.callStatsParams,
          debug: false,
        });
        this.hasWatchRTC = true;
        console.debug("watchRTC: started");
      } catch (error) {
        console.warn("Failed to start watchRTC - error=", error);
      }
    } else {
      console.debug("watchRTC is not configured");
    }
  }

  private static connectRTCMonitoring(
    stream: MediaStream,
    streamId: string,
  ): void {
    if (!this.hasWatchRTC) {
      return;
    }
    const [track] = stream.getTracks();
    if (track && this.hasWatchRTC) {
      watchRTC.mapTrack(track.id, streamId);
    }
  }

  static async start(args: StartArgs): Promise<void> {
    console.debug(
      "ServerAdapter:start",
      args.serverParams,
      args.callStatsParams,
    );

    if (
      !args.serverParams.dcpHomeBroker ||
      !args.serverParams.dcpSessionBroker
    ) {
      throw new Error("Do DCP brokers defined");
    }

    if (this.currState !== State.NONE) {
      throw new Error("Incorrect state");
    }

    if (this.audioRoom || this.videoRoom || this._dcpEndpoint) {
      throw new Error("Already created");
    }

    this.currState = State.INITIALISING;
    this.userId = args.userId;

    this.connectionGlobals = { serverParams: args.serverParams };

    this.incomingVideosCallback = args.incomingVideosCallback;
    this.outgoingVideosCallback = args.outgoingVideosCallback;

    this.startRTCMonitoring(args);

    try {
      await CallStats.initialise(
        args.sessionId,
        args.callStatsParams,
        args.serverParams.callstats,
      );
    } catch (error) {
      console.warn("Error initialising callstats - error=", error);
      // ignore error
    }

    try {
      await init();

      this._hostDeviceId = await readDeviceId();
      console.debug("hostDeviceId=", this._hostDeviceId);

      this.videoConnections = {};

      // start the audio room endpoint
      this.audioRoom = await this.createAndConnectToAudiobridge(args);

      // start the video room endpoint
      this.videoRoom = await this.createAndConnectToVideoroom(args);

      this.videoMonitor = new MonitorConnectionJanusVideo(this.videoRoom, {
        hostDeviceId: this._hostDeviceId,
        userId: this.userId,
      });
      this.videoMonitor.on("added", (device: DeviceIncoming) => {
        this.handleIncomingVideoConnection(device);
      });
      this.videoMonitor.on("removed", (device: DeviceIncoming): void => {
        console.log("removed - already handled", device);
      });
      this.videoMonitor.on("error", (error: Error): void => {
        console.warn("Error from monitor connection - error=", error);
        this.finish(error).catch(() => {
          /* ignore */
        });
      });
      this.videoMonitor.on("closed", (): void => {
        console.warn("Monitor connection closed");
        this.finish().catch(() => {
          /* ignore */
        });
      });

      // start the DCP session broker endpoint

      this._dcpEndpoint = new EndpointDcpSession({
        sessionId: args.sessionId,
        userId: args.userId,
        brokerConfig: args.serverParams.dcpSessionBroker,
        tokenProvider: args.tokenProvider,
      });
      // connect to broker in the background
      this._dcpEndpoint.connect().catch(() => {
        /* ignore error */
      });

      if (this._hostDeviceId) {
        this.inviteeDevice = new DeviceDcpInvitee(this._hostDeviceId, {
          dcpHomeBrokerConfig: args.serverParams.dcpHomeBroker,
          dcpSessionBrokerConfig: args.serverParams.dcpSessionBroker,
        });
        this.inviteeDevice.invoke(this._dcpEndpoint, "", {});
      }

      // monitor DCP for new devices

      this._dcpMonitor = new MonitorDeviceDcp(this._dcpEndpoint, {
        hostDeviceId: this._hostDeviceId,
      });
      this._dcpMonitor.on("added", (device) => {
        Object.values(this.videoConnections).forEach(
          (connection: Connection): void => {
            console.log("device=", device, connection);
            if (
              connection.options.params?.devices?.find(
                (myDevice) =>
                  myDevice.deviceId === device.deviceId &&
                  myDevice.component === device.options.component,
              )
            ) {
              console.log("Adding device that arrived");
              connection.addDevice(device, this._dcpEndpoint as Endpoint);
            }
          },
        );
      });
      // and finally the local device monitors (microphone, speaker and camera)

      const virtualEndpoint = new EndpointVirtual({
        sessionId: args.sessionId,
        userId: this.userId,
      });

      const localMicrophoneMonitor = new MonitorDeviceLocalMicrophone(
        virtualEndpoint,
      );
      const localSpeakerMonitor = new MonitorDeviceLocalSpeaker(
        virtualEndpoint,
      );
      const localCameraMonitor = new MonitorDeviceLocalCamera(virtualEndpoint);

      this.localMonitors = {
        audioinput: localMicrophoneMonitor,
        audiooutput: localSpeakerMonitor,
        videoinput: localCameraMonitor,
      };

      // cameraMonitor combines local cameras and DCP cameras into a single monitor
      this._cameraMonitor = new MonitorCamera(
        localCameraMonitor,
        this._dcpMonitor,
        this._hostDeviceId,
      );

      this.currState = State.ACTIVE;
    } catch (error) {
      console.warn("ServerAdapter:start - error=", error);
      this.finish();
      throw error;
    }
  }

  private static async handleIncomingVideoConnection(
    device: DeviceIncoming,
  ): Promise<void> {
    console.log("added", device);
    const streamId = device.options.streamId;

    const existingConnection = this.videoConnections[streamId];
    if (existingConnection) {
      //LATER - pre-LMS backwards compatibility - start
      if (
        existingConnection instanceof ConnectionDcpVideoOutgoing &&
        existingConnection.isLegacyPxKit
      ) {
        console.debug({ streamId }, "We have a pending connection - use it");
        this.videoConnections[streamId].addDevice(
          device,
          this.videoRoom as Endpoint,
        );
        this.updateVideos();
        return;
        //LATER - pre-LMS backwards compatibility - start
      } else {
        // its our connection - ignore
        return;
      }
    }

    const { index } = decodeStreamId(streamId);

    if (index >= ENDPOINT_CONFIG.video.maxPublishers) {
      console.warn(
        {
          streamId,
        },
        "ServerAdapter:processParticipants - index out of range=",
        index,
      );
      return;
    }

    if (!this.videoRoom) {
      console.warn("Video room not defined");
      return;
    }
    console.log("invoking", streamId);
    const connection = device.invoke(this.videoRoom, streamId, {
      params: {
        userId: this.userId,
        userUUID: this.userId,
      },
    });
    if (!connection) {
      console.log("No connection");
      return;
    }

    this.videoConnections[streamId] = connection;

    if (device.options.params.devices && this._dcpEndpoint) {
      const additionalDevice = this._dcpMonitor?.deviceList.find(
        (myDevice: DeviceDcp): boolean =>
          myDevice.deviceId === device.deviceId &&
          myDevice.options.component ===
            device.options.params.devices?.[0].component,
      );
      console.log("additionalDevice=", additionalDevice);
      if (additionalDevice) {
        connection.addDevice(additionalDevice, this._dcpEndpoint);
        this.updateVideos();
      }
    }

    connection.on("connected", (stream: MediaStream): void => {
      console.debug(
        {
          streamId,
        },
        "ServerAdapter - inbound connected",
      );

      this.connectRTCMonitoring(stream, streamId);

      this.updateVideos();
    });

    connection.on("closed", (): void => {
      console.debug(
        {
          streamId,
        },
        "ServerAdapter - inbound closed",
      );
      delete this.videoConnections[streamId];
      this.updateVideos();
    });

    connection.on("error", (error: Error): void => {
      console.debug(
        {
          streamId,
        },
        "ServerAdapter - inbound error=",
        error,
      );
      delete this.videoConnections[streamId];
      this.updateVideos();
    });

    await connection.open(this.videoRoom);
  }

  static async finish(error?: Error): Promise<void> {
    console.debug("ServerAdapter: finish - currState=", this.currState);

    if (
      this.currState === State.INITIALISING ||
      this.currState === State.ACTIVE
    ) {
      this.currState = State.FINISHING;

      await this.finishAudioRoom(error);
      await this.finishVideoRoom(error);
      await this.finishDcpEndpoint(error);
      await this.finishLocalDeviceMonitor();

      this.currState = State.NONE;
    }
  }

  static async destroy(): Promise<void> {
    // destroy means that the ServerAdapter will not be able to be re-started
    console.debug("ServerAdapter: destroy");
    this.currState = State.FINISHING;

    await this.finishAudioRoom();
    await this.finishVideoRoom();
    await this.finishDcpEndpoint();
    await this.finishLocalDeviceMonitor();

    this.currState = State.DESTROYED;
  }

  private static async finishConnection(
    connection: Connection | null,
    error?: Error,
  ): Promise<void> {
    if (connection) {
      // do not wait for response before returning
      await connection.close(error);
    }
  }

  private static async finishLocalDeviceMonitor(): Promise<void> {
    if (this.localMonitors.audioinput) {
      await this.localMonitors.audioinput.close();
      this.localMonitors.audioinput = null;
    }
    if (this.localMonitors.audiooutput) {
      await this.localMonitors.audiooutput.close();
      this.localMonitors.audiooutput = null;
    }
    if (this.localMonitors.videoinput) {
      await this.localMonitors.videoinput.close();
      this.localMonitors.videoinput = null;
    }

    /*TODO- why doesn't this work??
    Object.keys(this.localMonitors).forEach(
      (kind: string): void => {
        if (this.localMonitors[kind]) {
          this.localMonitors[kind].close();
          this.localMonitors[kind] = null;
        }
      },
    );*/
  }

  private static async finishAudioRoom(error?: Error): Promise<void> {
    if (this.audioRoom) {
      try {
        //TODO - when we have an Audio device we won't need to do this
        await this.finishConnection(this._audioConnection);
        this._audioConnection = null;
        await this.audioRoom.close(error);
      } catch (error) {
        console.warn("ServerAdapter:finishAudioRoom - error=", error);
      } finally {
        this.audioRoom = null;
      }
    }
  }

  private static async finishVideoMonitor(error?: Error): Promise<void> {
    if (this.videoMonitor) {
      try {
        await this.videoMonitor.close(error);
      } catch (error) {
        console.warn("ServerAdapter:finishVideoMonitor - error=", error);
      } finally {
        this.videoMonitor = null;
      }
    }
  }

  private static async finishVideoRoom(error?: Error): Promise<void> {
    if (this.videoRoom) {
      try {
        await this.finishVideoMonitor(error);

        await Promise.all(
          Object.values(this.videoConnections).map((connection: Connection) =>
            this.finishConnection(connection, error),
          ),
        );

        await this.videoRoom.close(error);
      } catch (error) {
        console.warn("ServerAdapter:finishVideoRoom - error=", error);
      } finally {
        this.videoRoom = null;
      }
    }
  }

  private static async finishDcpEndpoint(error?: Error): Promise<void> {
    if (this.inviteeDevice) {
      try {
        await this.inviteeDevice.close(error);
      } catch (myError) {
        console.warn("ServerAdapter:finishDcpEndpoint - error=", myError);
      } finally {
        this.inviteeDevice = null;
      }
    }

    if (this._dcpEndpoint) {
      try {
        await this._dcpEndpoint.close(error);
      } catch (myError) {
        console.warn("ServerAdapter:finishDcpEndpoint - error=", myError);
      } finally {
        this._dcpEndpoint = null;
      }
    }
  }

  static async joinAudio(): Promise<ConnectionJanusAudio> {
    if (!this.audioRoom) {
      return Promise.reject(new Error("Audio room not initialised"));
    }

    const streamId = encodeStreamId({
      type: FeedType.Audio,
      userId: this.userId,
    });

    let audioConnection: ConnectionJanusAudio | null = null;
    try {
      audioConnection = new ConnectionJanusAudio(
        this.audioRoom,
        new DeviceVirtual("AUDIO_DEVICE"),
        streamId,
        {
          params: {
            userUUID: this.userId,
          },
          quality: new QualityAudio({}),
        },
      );

      audioConnection.on("connecting", (): void => {
        console.debug(
          {
            streamId,
          },
          "ServerAdapter - audio connecting",
        );
        if (audioConnection && this.audioRoom) {
          CallStats.audio(audioConnection.pc, streamId);
        }
      });

      audioConnection.on("connected", (stream: MediaStream): void => {
        console.debug(
          {
            streamId,
          },
          "ServerAdapter - audio connected",
        );

        this.connectRTCMonitoring(stream, streamId);
      });

      audioConnection.on("update", (stream: MediaStream): void => {
        console.debug(
          {
            streamId,
          },
          "ServerAdapter - audio updated",
        );

        this.connectRTCMonitoring(stream, streamId);
      });

      audioConnection.on("ismuted", (isMuted: boolean): void => {
        console.debug(
          {
            streamId,
          },
          "ServerAdapter - audio isMuted=",
          isMuted,
        );
        if (this.hasWatchRTC) {
          //LATER - signal watchRTC (when we have that ability)
        }
        if (audioConnection) {
          CallStats.setMute(audioConnection.pc, isMuted);
        }
      });

      audioConnection.on("closed", (): void => {
        console.debug(
          {
            streamId,
          },
          "ServerAdapter - audio closed",
        );
        void this.finish();
      });

      audioConnection.on("error", (error: Error): void => {
        console.debug(
          {
            streamId,
          },
          "ServerAdapter - audio error=",
          error,
        );
        this.finish(error).catch(() => {
          /* ignore */
        });
      });

      await audioConnection.open();
    } catch (error) {
      console.warn(
        {
          streamId,
        },
        "ServerAdapter - failed to connect audio - error=",
        error,
      );
      this.finish();
      throw error;
    }

    // only set the public variable when we've finished initialising - in case
    // something went wrong
    this._audioConnection = audioConnection;

    return audioConnection;
  }

  private static getAvailableIndex(): number {
    const potentiallyFilledConnectionsIndexes = Array<boolean>(
      ENDPOINT_CONFIG.video.maxPublishers,
    );

    Object.values(this.videoConnections).forEach(
      (connection: Connection): void => {
        const { index } = decodeStreamId(connection.streamId);
        potentiallyFilledConnectionsIndexes[index] = true;
      },
    );

    // find the first unused element in potentiallyFilledConnectionsIndexes
    return potentiallyFilledConnectionsIndexes.findIndex(
      (elem) => typeof elem === "undefined",
    );
  }

  static generateStreamId(
    type: FeedType,
    userId: string,
    streamId?: string,
  ): string {
    // use streamId if it is provided, otherwise create a new one
    if (streamId) {
      if (this.videoConnections[streamId]) {
        console.warn({ streamId }, "Duplicate streamId");
        throw new exceptions.DuplicateVideoStreamException(
          ExceptionCodes.DUPLICATE_VIDEO_STREAM,
        );
      }
    } else {
      const index = this.getAvailableIndex();

      if (index < 0) {
        throw new exceptions.VideoCapacityExceededException(
          ExceptionCodes.VIDEO_CAPACITY_EXCEPTION_EXCEEDED,
        );
      }

      streamId = encodeStreamId({
        type,
        userId,
        index,
      });
    }
    return streamId;
  }

  private static updateVideos(): void {
    //TODO - use just a single callback
    this.incomingVideosCallback?.(
      Object.values(this.videoConnections).filter(
        (connection: Connection): boolean =>
          connection.direction === Direction.Incoming,
      ),
    );
    this.outgoingVideosCallback?.(
      Object.values(this.videoConnections).filter(
        (connection: Connection): boolean =>
          connection.direction === Direction.Outgoing,
      ),
    );
  }

  private static lookupDeviceTypeToEndpoint(
    deviceType: DeviceType,
  ): Endpoint | null {
    return deviceType === DeviceType.Dcp ? this._dcpEndpoint : this.videoRoom;
  }

  //LATER - pre-LMS backwards compatibility - start
  private static isLMSPxKit(device: Device): boolean {
    if (!device || !(device instanceof DeviceDcp)) {
      return false;
    }

    if (!device.hasDcpType(DcpType.Stream) || !device.serviceName) {
      return false;
    }

    const stream = device.state?.STREAM;

    return stream ? "mode" in stream : false;
  }

  //LATER - pre-LMS backwards compatibility - end

  private static async checkRemoteDcpDevice(device: Device): Promise<void> {
    // if its a DCP device check we've used it for any other streams, and
    // if its the local connection for a DCP device that is already streaming
    // from remote then replace it

    if (!(device instanceof DeviceDcp)) {
      return;
    }

    const remoteConnection = Object.values(this.videoConnections).find(
      (connection: Connection): boolean => {
        if (connection.connectionType !== ConnectionType.DcpVideoIncoming) {
          return false;
        }

        const connectionDevice = connection.devices[
          DeviceType.Incoming
        ] as DeviceIncoming;
        if (!connectionDevice) {
          return false;
        }

        return (
          connectionDevice.deviceId === device.deviceId &&
          connectionDevice.options.params.devices?.[0].component ===
            device.options.component
        );
      },
    );

    if (
      remoteConnection &&
      device.deviceId === this._hostDeviceId &&
      this.isLMSPxKit(device)
    ) {
      console.warn(
        {
          streamId: remoteConnection.streamId,
          deviceId: device.deviceId,
        },
        "Replacing remote stream with the local stream",
      );
      await this.finishConnection(
        remoteConnection,
        new Error("Replacing remote stream"),
      );
      // proceed to accept the local stream in the normal way
    }

    // check that we have established a stream already for this DCP device
    const localConnection = Object.values(this.videoConnections).find(
      (connection: Connection): boolean =>
        connection.devices[DeviceType.Dcp] === device,
    );

    if (localConnection) {
      console.warn(
        { deviceId: device.deviceId },
        "DCP device already in use - component=",
        device.options.component,
      );
      throw new Error("DCP device in use");
    }
  }

  public static async createConnection(
    device: Device,
    streamId: string, // use "" for generate one
    stream?: MediaStream,
    params?: ConnectionMetadata,
    boundData?: unknown,
  ): Promise<Connection | null> {
    if (this.currState === State.DESTROYED) {
      console.debug("ServerAdapter - createConnection");
      throw new exceptions.ServerAdapterDestroyedException();
    }

    console.debug(
      "ServerAdapter - createConnection - streamId=",
      streamId,
      device,
      params,
    );

    const endpoint = this.lookupDeviceTypeToEndpoint(device.deviceType);
    if (!endpoint) {
      throw new exceptions.EndpointNotInitializedException(
        ExceptionCodes.ENDPOINT_NOT_INITIALIZED,
      );
    }

    await this.checkRemoteDcpDevice(device);

    streamId = this.generateStreamId(device.mediaType, this.userId, streamId);

    const connection = device.invoke(endpoint, streamId, {
      params: {
        ...params,
        userId: this.userId,
        userUUID: this.userId,
      },
      boundData,
      globals: this.connectionGlobals,
    });

    if (!connection) {
      return null;
    }

    try {
      this.videoConnections[streamId] = connection;

      console.debug("ServerAdapter - outbound created - streamId=", streamId);

      connection.on("closed", (): void => {
        console.debug("ServerAdapter - outbound closed - streamId=", streamId);
        delete this.videoConnections[streamId];
        this.updateVideos();
      });

      connection.on("error", (error: Error): void => {
        console.debug("ServerAdapter - outbound error=", error);
        delete this.videoConnections[streamId];
        this.updateVideos();
      });

      connection.on("connected", (stream: MediaStream): void => {
        console.debug(
          {
            streamId,
          },
          "ServerAdapter - outbound connected",
        );

        this.connectRTCMonitoring(stream, streamId);

        this.updateVideos();
      });

      await connection.open(endpoint);
      if (stream) {
        await connection.send(stream);
      }
      this.updateVideos();

      return connection;
    } catch (error) {
      console.warn(
        "ServerAdapter - failed to connect outbound video - error=",
        error,
      );
      this.finishConnection(this.videoConnections[streamId]);
      delete this.videoConnections[streamId];
      throw error;
    }
  }

  static getMonitorByKindOrThrow(kind: MediaDeviceKind): MonitorDeviceLocal {
    if (!this.localMonitors[kind]) {
      throw new Error("Monitor does not exist");
    }
    return this.localMonitors[kind] as MonitorDeviceLocal;
  }

  static getDevicesByKind(kind: MediaDeviceKind): Device[] {
    return this.localMonitors[kind]?.deviceList || [];
  }

  static get microphoneMonitor(): MonitorDeviceLocalMicrophone | null {
    return this.localMonitors["audioinput"];
  }

  static get microphones(): DeviceLocalMicrophone[] {
    return this.getDevicesByKind(
      WebRTCUtil.KINDS.audioinput,
    ) as DeviceLocalMicrophone[];
  }

  static get speakerMonitor(): MonitorDeviceLocalSpeaker | null {
    return this.localMonitors["audiooutput"];
  }

  static get speakers(): DeviceLocalSpeaker[] {
    return this.getDevicesByKind(
      WebRTCUtil.KINDS.audiooutput,
    ) as DeviceLocalSpeaker[];
  }

  static get cameras(): Device[] {
    return this._cameraMonitor?.deviceList || [];
  }

  static get cameraMonitor(): MonitorCamera | null {
    return this._cameraMonitor;
  }

  static get dcpEndpoint(): EndpointDcpSession | null {
    return this._dcpEndpoint;
  }

  static get dcpMonitor(): MonitorDeviceDcp | null {
    return this._dcpMonitor;
  }

  static get audioConnection(): ConnectionJanusAudio | null {
    return this._audioConnection;
  }

  static get hostDeviceId(): string {
    return this._hostDeviceId;
  }
}
