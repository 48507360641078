import React, { FC, useEffect, useState } from "react";
import {
  AppBar,
  Button,
  ButtonGroup,
  DialogActions,
  DialogTitle,
  DisplayTime,
  FullscreenToggle,
  MicIndicator,
  SnackbarContext,
} from "@proximie/components";
import { useFlag } from "@proximie/feature-flag-react-sdk";
import ClickableMicIndicatorProps from "../../models/ClickableMicIndicator";
import {
  Box,
  Button as MuiButton,
  Dialog,
  Tooltip,
  useTheme,
} from "@mui/material";
import { ScreenShare, Settings, VolumeUp } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/session-context/session-context";
import RecordingControls from "../RecordingControls/RecordingControls";
import RecordingStatus from "../RecordingStatus/RecordingStatus";
import { LiveSessionDivider } from "./LiveSessionControlBar.styles";
import {
  RecordingStateStatus,
  useRecording,
} from "../../contexts/recording-context/recording-context";
import useMediaQuery from "@mui/material/useMediaQuery";
import AudioSettingsMenu from "../AudioSettingsMenu/AudioSettingsMenu";
import VolumeSlider from "../VolumeSlider/VolumeSlider";
import { Permission } from "../../utils/checkPermission";
import { SpotlightControls } from "../SpotlightControls/SpotlightControls";
import { ViewSelectorMenu } from "../ViewSelectorMenu/ViewSelectorMenu";
import { FeatureFlags } from "../constants";
import { useTranslation } from "react-i18next";

export interface LiveSessionControlBarProps {
  addVideo: React.ReactNode;
  startedAt?: Date;
  mic: ClickableMicIndicatorProps;
  isEndSessionButtonDisabled: boolean;
  onEndSessionClick?: () => void;
  onScreenShareClick: () => void;
  checkMic?: () => void;
}

const dividerHeight = 36;

const LiveSessionControlBar: FC<LiveSessionControlBarProps> = (
  props,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const isSpotlightEnabled = useFlag(FeatureFlags.SPOTLIGHT);

  const sessionContext = useSessionContext();

  const { status } = useRecording();
  const [isEndSessionOpen, setIsEndSessionOpen] = useState(false);

  const [audioSettingsAnchorEl, setAudioSettingsAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const handleAudioSettingsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAudioSettingsAnchorEl(event.currentTarget);
  };

  const [viewSelectorAnchorEl, setViewSelectorAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const handleViewSelectorClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setViewSelectorAnchorEl(event.currentTarget);
  };

  const [audioVolumeAnchorEl, setAudioVolumeAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const handleAudioVolumeClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAudioVolumeAnchorEl(event.currentTarget);
  };

  const openEndSessionPopup = () => {
    setIsEndSessionOpen(true);
  };

  const closeEndSessionPopup = () => {
    setIsEndSessionOpen(false);
  };

  const isRecordingEnabled = !!sessionContext.organisation?.allowRecording;

  const hasRecordingStarted = status !== RecordingStateStatus.NotStarted;

  const { showSnackbar, closeSnackbar } = SnackbarContext.useSnackbarContext();

  useEffect(() => {
    if (status === RecordingStateStatus.Recording) {
      showSnackbar({
        message: {
          body: t("common.components.snackbar.messages.recordingInProgress"),
        },
        anchorOrigin: { vertical: "top", horizontal: "center" },
        severity: "error",
      });
    } else {
      closeSnackbar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const minRes = useMediaQuery("(min-width:1025px)");

  return (
    <AppBar>
      <Dialog open={isEndSessionOpen} onClose={closeEndSessionPopup}>
        <DialogTitle onClose={closeEndSessionPopup}>
          Are you sure you want to end this session?
        </DialogTitle>

        <DialogActions>
          <Button
            variant="primary"
            onClick={props.onEndSessionClick}
            disabled={props.isEndSessionButtonDisabled}
            data-cy="confirm-end-session"
            data-testid="confirm-end-session"
          >
            End Session
          </Button>
          <Button variant="secondary" onClick={closeEndSessionPopup}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Box display="flex" alignItems="center">
        {props.startedAt && <DisplayTime startedAt={props.startedAt} />}
        <sessionContext.ShowForPermission permission={Permission.END_SESSION}>
          <Box pl={2} flexShrink={0}>
            <MuiButton
              aria-label="End Session"
              name="End Session"
              size="small"
              variant="contained"
              onClick={openEndSessionPopup}
              data-cy="end-session-button"
              data-testid="end-session-button"
              sx={{
                "&.MuiButton-contained": {
                  backgroundColor: colors.Red700,
                },
              }}
            >
              {minRes ? "End Session" : "End"}
            </MuiButton>
          </Box>
        </sessionContext.ShowForPermission>
        <Box ml={4} flexShrink={0} display={"flex"} height={dividerHeight}>
          <LiveSessionDivider
            variant={"middle"}
            orientation="vertical"
            light
            flexItem
          />
        </Box>
        {isRecordingEnabled && (
          <>
            <sessionContext.ShowForPermission
              permission={[
                Permission.START_RECORDING,
                Permission.STOP_RECORDING,
              ]}
            >
              <RecordingControls />
            </sessionContext.ShowForPermission>
            <sessionContext.ShowForPermission
              invert
              permission={[
                Permission.START_RECORDING,
                Permission.STOP_RECORDING,
              ]}
              additionalPredicate={(value) => value && hasRecordingStarted}
            >
              <Box display={"flex"} alignItems={"center"} ml={5} minWidth={180}>
                <RecordingStatus />
              </Box>
            </sessionContext.ShowForPermission>
            <sessionContext.ShowForPermission
              permission={[
                Permission.START_RECORDING,
                Permission.STOP_RECORDING,
              ]}
              additionalPredicate={(value) => value || hasRecordingStarted}
            >
              <Box
                ml={4}
                flexShrink={0}
                display={"flex"}
                height={dividerHeight}
              >
                <LiveSessionDivider
                  variant={"middle"}
                  orientation="vertical"
                  light
                  flexItem
                />
              </Box>
            </sessionContext.ShowForPermission>
          </>
        )}

        <Box ml={5} flexShrink={0} display={"flex"} alignItems={"center"}>
          <ButtonGroup variant="text" color="primary">
            {props.addVideo}
            <Tooltip title="Share your screen" placement="top">
              <MuiButton
                aria-label="screenShare"
                size="small"
                color="inherit"
                onClick={props.onScreenShareClick}
                data-cy={"screen-share-button"}
                id={"screen-share-button"}
                data-testid={"screen-share-button"}
              >
                <ScreenShare />
              </MuiButton>
            </Tooltip>
          </ButtonGroup>
          <Box mx={3} flexShrink={0} display={"flex"} height={dividerHeight}>
            <LiveSessionDivider
              variant={"middle"}
              orientation="vertical"
              light
              flexItem
            />
          </Box>
          <ButtonGroup variant="text" color="primary">
            <Tooltip
              title={props.mic.isMuted ? "Unmute" : "Mute"}
              placement="top"
            >
              <MuiButton
                aria-label="mute"
                data-cy={props.mic.isMuted ? "icon-unmute" : "icon-mute"}
                name="mute"
                size="small"
                color="inherit"
                onClick={() => {
                  props.checkMic?.();
                  if (props.mic.isConnected && props.mic.onClick) {
                    props.mic.onClick();
                  }
                }}
              >
                <MicIndicator
                  isConnected={props.mic.isConnected}
                  isMuted={props.mic.isMuted}
                  isActive={props.mic.isActive}
                />
              </MuiButton>
            </Tooltip>
            <Tooltip title={"Volume"} placement="top">
              <MuiButton
                aria-label="audio-volume-btn"
                data-cy="audio-volume-btn"
                data-testid="audio-volume-btn"
                size="small"
                variant="text"
                color="inherit"
                onClick={handleAudioVolumeClick}
              >
                <VolumeUp />
              </MuiButton>
            </Tooltip>
            <Tooltip title={"Audio Settings"} placement="top">
              <MuiButton
                aria-label="audio-settings-btn"
                data-cy="audio-settings-btn"
                size="small"
                variant="text"
                color="inherit"
                onClick={handleAudioSettingsClick}
              >
                <Settings />
              </MuiButton>
            </Tooltip>
          </ButtonGroup>
          <Box mx={3} flexShrink={0} display={"flex"} height={dividerHeight}>
            <LiveSessionDivider
              variant={"middle"}
              orientation="vertical"
              light
              flexItem
            />
          </Box>
          <ButtonGroup variant="text" color="primary">
            {isSpotlightEnabled && (
              <SpotlightControls onClick={handleViewSelectorClick} />
            )}
            <FullscreenToggle />
          </ButtonGroup>
        </Box>
      </Box>

      <AudioSettingsMenu
        anchorEl={audioSettingsAnchorEl}
        onClose={() => setAudioSettingsAnchorEl(null)}
      />
      {!!audioVolumeAnchorEl && (
        <VolumeSlider
          anchorEl={audioVolumeAnchorEl}
          onClose={() => setAudioVolumeAnchorEl(null)}
        />
      )}

      {isSpotlightEnabled && !!viewSelectorAnchorEl && (
        <ViewSelectorMenu
          anchorEl={viewSelectorAnchorEl}
          onClose={() => setViewSelectorAnchorEl(null)}
        />
      )}
    </AppBar>
  );
};

export default LiveSessionControlBar;
