import React, { FC, useEffect, useRef, useState } from "react";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { EventTypes, PtzUser } from "../../utils/PtzUser";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import OpenWidthIcon from "@mui/icons-material/OpenWith";
import { Participant } from "../../hooks/useParticipants/useParticipants";

export interface PtzStatusLabelProps {
  ptzUser: PtzUser;
  label: string;
  index: number;
}

const PtzStatusLabel: FC<PtzStatusLabelProps> = (
  props: PtzStatusLabelProps,
): JSX.Element => {
  const { participants, user } = useSessionContext();
  const { colors } = useTheme();

  const UNUSED_USER = null;

  const [controllerId, setControllerId] = useState<string | null>(UNUSED_USER);
  const [controllerName, setControllerName] = useState<string>("");
  const [isTextOverflown, setTextOverflown] = useState<boolean>(true);

  const textRef = useRef<HTMLElement>(null);

  const theme = useTheme();

  useEffect(() => {
    props.ptzUser.on(
      EventTypes.ControlNotification,
      (myControllerId: string | null): void => {
        setControllerId(myControllerId);

        if (myControllerId && participants) {
          const participant = participants.find(
            (myParticipant: Participant): boolean =>
              myParticipant.id === myControllerId,
          );
          setControllerName(participant?.name ?? "UNKNOWN");
        } else {
          setControllerName("");
        }
      },
    );

    props.ptzUser.requestStatusAndControl();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ptzUser]);

  useEffect(() => {
    const handleResize = () => {
      if (!textRef.current) {
        return;
      }

      const text = textRef.current;
      setTextOverflown(text.offsetWidth < text.scrollWidth);
    };

    window.addEventListener("spotlight-resize", handleResize);
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("spotlight-resize", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const text =
    controllerId === user?.id
      ? `You control: ${props.label} (${props.index + 1})`
      : controllerId
      ? `Controlled by: ${controllerName}`
      : `Control available: ${props.label} (${props.index + 1})`;

  return (
    <Tooltip
      title={text}
      placement="top"
      disableHoverListener={!isTextOverflown}
      disableFocusListener={!isTextOverflown}
      disableTouchListener={!isTextOverflown}
      componentsProps={{
        tooltip: {
          sx: { margin: "5px !important" },
        },
      }}
      arrow
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          borderRadius: "4px",
          color: colors.White,
          backgroundColor:
            controllerId === user?.id ? colors.Blue700 : colors.BlackInactive,
        }}
      >
        <Box display="flex" paddingX="5px" paddingY="3px">
          <OpenWidthIcon />
        </Box>
        <Typography
          pr={1}
          noWrap
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...theme.typography.body2,
          }}
          ref={textRef}
        >
          {text}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default PtzStatusLabel;
