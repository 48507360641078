import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AppName,
  Logger,
  createLogger,
  getEnvironment,
} from "@proximie/common";
import overrideLogger from "../overrideLogger";
import { useAuthenticatedUser } from "./media-client-authenticated-user-context";
import { environment } from "../../environments/environment";

interface LoggerProviderProps {
  children: ReactNode;
}

type LoggerCreateConfig = {
  sessionId: string;
  profileReference: string;
  newRelicLogUrl: string;
};

export interface LoggerContext {
  create: (config: LoggerCreateConfig) => void;
}

export const LoggerContext = createContext<LoggerContext | null>(null);

export const useLoggerContext = () => useContext(LoggerContext);

export const LoggerProvider = ({ children }: LoggerProviderProps) => {
  const authContext = useAuthenticatedUser();

  let logger: Logger | null = null;

  const create = (config: LoggerCreateConfig) => {
    if (logger) {
      console.warn("Logger already created");
      return;
    }

    const token = authContext?.token || "NO_TOKEN";

    logger = createLogger({
      configs: {
        newRelic: {
          auth: token,
          hostURL: config.newRelicLogUrl || "",
        },
      },
      sessionId: config.sessionId,
      profileReference: config.profileReference,
      app: AppName.LIVE,
      env: getEnvironment(environment.name),
      version: "1.0",
    });

    if (config.newRelicLogUrl) {
      overrideLogger(logger);
    }
  };

  useEffect(() => {
    if (authContext?.token && logger) {
      logger.setConfig?.({ auth: authContext?.token });
    }
  }, [authContext?.token, logger]);

  return (
    <LoggerContext.Provider
      value={{
        create,
      }}
    >
      {children}
    </LoggerContext.Provider>
  );
};
