import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { User } from "./User";

const getUser: () => Promise<User> = async () =>
  await axios.get(`${environment.apiUrl}/api/v2/users/me`);

export const useUser = (opts?: SWRConfiguration) => {
  const { data, error } = useSWR<User>(`user`, getUser, opts);

  return {
    user: data,
    isUserLoading: !error && !data,
    error: error,
  };
};
