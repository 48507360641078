import { default as LetterAvatar } from "./components/LetterAvatar/LetterAvatar";
import { default as AppBar } from "./components/AppBar/AppBar";
import { Button } from "./components/Button/Button";
import { default as PageTitle } from "./components/PageTitle/PageTitle";
import { default as AuthBackground } from "./components/AuthBackground/AuthBackground";
import { default as Icon } from "./components/Icon/Icon";
import { default as IconButton } from "./components/IconButton/IconButton";
import { default as SearchField } from "./components/SearchField/SearchField";
import { GlobalStyles } from "./styles/global-styles";
import { default as Assets } from "./assets";
import { PROXIMIE_URLS } from "./lib/constants";
import { default as Grid } from "./components/Grid/Grid";
import { DialogActions } from "./components/DialogActions/DialogActions";
import { DialogTitle } from "./components/DialogTitle/DialogTitle";
import { default as Flexbox } from "./components/Flexbox/Flexbox";
import { default as TextButton } from "./components/TextButton/TextButton";
import { default as MenuWithIcons } from "./components/MenuWithIcons/MenuWithIcons";
import { default as FullscreenToggle } from "./components/FullscreenToggle/FullscreenToggle";
import { default as DisplayTime } from "./components/DisplayTime/DisplayTime";
import { default as Radio } from "./components/Radio/Radio";
import { BrowserCheck } from "./components/BrowserCheck/BrowserCheck";
import { PendoInit } from "./components/PendoInit/PendoInit";
import { Breadcrumbs } from "./components/Breadcrumbs/Breadcrumbs";
import {
  default as MicIndicator,
  MicIndicatorProps as _MicIndicatorProps,
} from "./components/MicIndicator/MicIndicator";
import { default as NameCard } from "./components/NameCard/NameCard";
import { default as ButtonGroup } from "./components/ButtonGroup/ButtonGroup";
import { default as ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import ExampleComponent from "./components/ErrorBoundary/ExampleComponent";
import * as Permissions from "./contexts/permissions-context/index";
import * as SnackbarContext from "./contexts/snackbar-context/snackbar-context";
import * as SnackbarCommon from "./contexts/snackbar-context/common";
import * as hooks from "./utils/hooks";
import * as timer from "./utils/timer";
import LiveSessionChipIcon from "./components/LiveSessionChipIcon/LiveSessionChipIcon";
import { default as MediaControls } from "./components/MediaControls/MediaControls";
import { VolumeSlider } from "./components/MediaControls/Controls/VolumeControl/VolumeControl";
import {
  ColorName as _ColorName,
  Colors as _Colors,
  colors,
} from "./styles/colors";
import CollapsibleText from "./components/CollapsibleText/CollapsibleText";
import {
  ChipProps as _ChipProps,
  default as Chip,
} from "./components/Chip/Chip";
import { FeatureFlag } from "./components/FeatureFlag/FeatureFlag";
import { default as OutlinedAlert } from "./components/OutlinedAlert/OutlinedAlert";
import { Alert } from "./components/Alert/Alert";
import {
  CloseFullScreen,
  IsFullScreen,
  OpenFullScreen,
} from "./components/FullscreenToggle/FullscreenUtil";
import {
  DefaultFont,
  FontWeightName as _FontWeightName,
  fontWeights,
  Typographys,
  TypographysCSS,
} from "./styles/typography";
import { generateTheme } from "./styles/mui-theme/proximie/theme";
import { Typography } from "./components/Typography/Typography";
import FileInput from "./components/FileInput/FileInput";
import Stepper from "./components/Stepper/Stepper";
import { CustomerSupportDialog } from "./components/CustomerSupportDialog/CustomerSupportDialog";

import { Spacer as _Spacer, spacers, spacersAsString } from "./styles/spacers";
import { FontWeight as _FontWeight } from "./models/font";
import { EMPTY_THUMBNAIL, Thumbnail } from "./components/Thumbnail/Thumbnail";
import { Logo } from "./components/Logo/Logo";

export {
  // foundation
  colors,
  Typographys,
  TypographysCSS,
  DefaultFont,
  fontWeights,
  spacers,
  spacersAsString,
  generateTheme,
  // components
  Alert,
  AppBar,
  LetterAvatar,
  AuthBackground,
  Breadcrumbs,
  BrowserCheck,
  Button,
  Chip,
  CollapsibleText,
  CustomerSupportDialog,
  DialogActions,
  DialogTitle,
  ErrorBoundary,
  ExampleComponent,
  Flexbox,
  FileInput,
  Grid,
  SearchField,
  Icon,
  IconButton,
  Logo,
  MediaControls,
  OutlinedAlert,
  PageTitle,
  GlobalStyles,
  Assets,
  MenuWithIcons,
  TextButton,
  Thumbnail,
  Typography,
  FullscreenToggle,
  DisplayTime,
  Radio,
  MicIndicator,
  ButtonGroup,
  NameCard,
  LiveSessionChipIcon,
  Permissions,
  PendoInit,
  VolumeSlider,
  Stepper,
  // context
  SnackbarContext,
  SnackbarCommon,
  PROXIMIE_URLS,
  EMPTY_THUMBNAIL,
  // utils
  timer,
  hooks,
  FeatureFlag,
  OpenFullScreen,
  CloseFullScreen,
  IsFullScreen,
};

export * from "./contexts/dialog-context/dialog-context";

// Export interfaces and types below
// if used via normal export you encounter a warning like
// "export 'MediaDeviceKinds' was not found in './lib/WebRTCUtil'
// https://github.com/webpack/webpack/issues/7378
// https://github.com/microsoft/TypeScript/wiki/Performance
export type MicIndicatorProps = _MicIndicatorProps;
export type ChipProps = _ChipProps;
export type ColorName = _ColorName;
export type Colors = _Colors;
export type FontWeight = _FontWeight;
export type FontWeightName = _FontWeightName;
export type Spacer = _Spacer;
