import ServerAdapter from "./lib/ServerAdapter/ServerAdapter";
import EchoAdapter from "./lib/ServerAdapter/EchoAdapter";
import Quality, {
  IceProtocol,
  IceType,
} from "./lib/ServerAdapter/Quality/Quality";

import {
  default as WebRTCUtil,
  MediaDeviceKind as _MediaDeviceKind,
  MediaDeviceKinds as _MediaDeviceKinds,
} from "./lib/WebRTCUtil";

import {
  VideoMaskHandler,
  RectMask as _RectMask,
  ElipMask as _ElipMask,
  MaskHandlerEvents,
} from "./lib/CanvasUtils";
export { Shapes } from "./lib/CanvasUtils";

import { default as MediaUtil } from "./lib/MediaUtil";

import FeedType from "./lib/models/FeedType";

import { default as _Participants } from "./lib/models/Participants";
import { default as _CallStatsInfo } from "./lib/models/CallStatsInfo";
import { default as _ServerParams } from "./lib/models/ServerParams";
import { default as _ServerURL } from "./lib/models/ServerURL";
import { default as _DcpBrokerConfig } from "./lib/models/DcpBrokerConfig";
import { default as _IncomingVideo } from "./lib/models/IncomingVideo";
import { default as _OutgoingVideo } from "./lib/models/OutgoingVideo";
import { default as _MediaVideo } from "./lib/models/MediaVideo";
import { default as _ConnectionMetadata } from "./lib/models/ConnectionMetadata";
import { default as _DeviceMetadata } from "./lib/models/DeviceMetadata";

import {
  default as _AudioParticipant,
  AudioParticipantsCallback as _AudioParticipantsCallback,
  AudioParticipants as _AudioParticipants,
} from "./lib/models/AudioParticipant";

import DeviceLocalCamera from "./lib/ServerAdapter/Devices/DeviceLocalCamera";
import DeviceLocalCameraPTZ from "./lib/ServerAdapter/Devices/DeviceLocalCameraPTZ";
import DeviceLocalSpeaker from "./lib/ServerAdapter/Devices/DeviceLocalSpeaker";
import DeviceLocalMicrophone from "./lib/ServerAdapter/Devices/DeviceLocalMicrophone";
import DeviceScreen from "./lib/ServerAdapter/Devices/DeviceScreen";
import DeviceDcp, { DcpType } from "./lib/ServerAdapter/Devices/DeviceDcp";
import DeviceIncoming from "./lib/ServerAdapter/Devices/DeviceIncoming";
import Device, { DeviceType } from "./lib/ServerAdapter/Devices/Device";
import Connection, {
  ConnectionType,
} from "./lib/ServerAdapter/Connections/Connection";
import Monitor from "./lib/ServerAdapter/Monitors/Monitor";

export { MediaDeviceInfoList } from "./lib/models/MediaDeviceInfoList";
export {
  ServerAdapter,
  EchoAdapter,
  WebRTCUtil,
  MediaUtil,
  FeedType,
  VideoMaskHandler,
  MaskHandlerEvents,
  Quality,
  Device,
  DeviceType,
  DcpType,
  DeviceLocalCamera,
  DeviceLocalCameraPTZ,
  DeviceLocalSpeaker,
  DeviceLocalMicrophone,
  DeviceScreen,
  DeviceDcp,
  DeviceIncoming,
  Connection,
  ConnectionType,
  Monitor,
  IceProtocol,
  IceType,
};

export {
  TestResult,
  TestList,
  defaultTestResultsList,
} from "./lib/models/TroubleShooterTests";
import { TestResultsList as _TestResultsList } from "./lib/models/TroubleShooterTests";

// Export interfaces and types below
// if used via normal export you encounter a warning like
// "export 'MediaDeviceKinds' was not found in './lib/WebRTCUtil'
// https://github.com/webpack/webpack/issues/7378
// https://github.com/microsoft/TypeScript/wiki/Performance
export type MediaDeviceKind = _MediaDeviceKind;
export type MediaDeviceKinds = _MediaDeviceKinds;
export type IncomingVideo = _IncomingVideo;
export type OutgoingVideo = _OutgoingVideo;
export type MediaVideo = _MediaVideo;
export type AudioParticipant = _AudioParticipant;
export type AudioParticipants = _AudioParticipants;
export type AudioParticipantsCallback = _AudioParticipantsCallback;
export type CallStatsInfo = _CallStatsInfo;
export type ServerParams = _ServerParams;
export type ServerURL = _ServerURL;
export type DcpBrokerConfig = _DcpBrokerConfig;
export type Participants = _Participants;
export type TestResultsList = _TestResultsList;
export type ConnectionMetadata = _ConnectionMetadata;
export type DeviceMetadata = _DeviceMetadata;

export type RectMask = _RectMask;
export type ElipMask = _ElipMask;
