import { EventEmitter } from "events";
import { QUALITY_CONFIG, QualityLimits } from "../../Constants";
import { JanusJS } from "@proximie/janus-gateway";
import FixedFifo from "./FixedFifo";

export interface QualityOptions {
  bitrate?: number;
}

export interface Control {
  value: number;
  onChange: (value: number) => Promise<void>;
  options: Array<{ value: number; label: string }>;
}

export enum ModeType {
  Manual = 0,
  Automatic = 1,
}

export type QualityTriggers = {
  pktLossPercent: number;
  roundTripTimeMs: number;
  processingTimeMs: number;
};

export type QualityMetrics = QualityTriggers & {
  processingTimePerByteMs: number;
  processingTimePerPixelMs: number;
  availableOutgoingBitrate: number;
  fps: number;
  bitrate: number;
};

const DEBUG_INTERVAL_MS = 60000;

export type SummaryStats = {
  bytesTransferred?: number;
  packetsTransferred?: number;
  framesTransferred?: number;
  packetsLost?: number;
  totalProcessingTime?: number;
  framesProcessed?: number;
  timestamp?: number;
  totalRoundTripTime?: number;
  responsesReceived?: number;
  width?: number;
  height?: number;
  availableOutgoingBitrate?: number;
};

export type IceProtocol = "udp" | "tcp" | "N/A";
export type IceType = "host" | "srflx" | "prflx" | "relay" | "N/A";

type IceConfig = {
  protocol: IceProtocol;
  type: IceType;
};

export type GuiStats = {
  packetLoss: number;
  fps: number;
  bitrate: number;
  bytes: number;
  protocol: IceProtocol;
  type: IceType;
};

export type Controls = Record<string, Control>;

export default abstract class Quality extends EventEmitter {
  private intervalId: ReturnType<typeof setInterval> | null = null;
  protected handle: JanusJS.PluginHandle | null = null;
  public controls: Controls | null = null;
  public stats: GuiStats | null = null;
  protected limits: QualityLimits | null = null;
  public streamId = "";
  private iceConfig: IceConfig | null = null;

  constructor(protected options: QualityOptions) {
    super();
  }

  start(streamId: string, handle: JanusJS.PluginHandle) {
    console.debug(
      {
        streamId,
      },
      "Quality:start",
    );

    if (!handle.webrtcStuff.pc) {
      throw new Error("PeerConnection not established");
    }

    this.streamId = streamId;
    this.handle = handle;

    this.intervalId = setInterval(async () => {
      try {
        const report = await this.getStatsOrThrow();
        this.processStats(report);
      } catch {
        // ignore error
      }
    }, QUALITY_CONFIG.statsIntervalMs);
  }

  stop(): void {
    console.debug(
      {
        streamId: this.streamId,
      },
      "Quality:stop",
    );
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private generateIceConfig(report: RTCStatsReport): IceConfig | null {
    let localCandidateId = "";
    const localCandidates: Record<string, object> = {};

    report.forEach((stat /* RTCStats */): void => {
      switch (stat.type) {
        case "candidate-pair":
          if (stat.state === "succeeded") {
            localCandidateId = stat.localCandidateId;
          }
          break;
        case "local-candidate":
          localCandidates[stat.id] = stat;
          break;
        default:
        // do nothing
      }
    });

    if (localCandidateId && localCandidates[localCandidateId]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const candidate = localCandidates[localCandidateId] as any;
      return {
        protocol: candidate.protocol,
        type: candidate.candidateType,
      };
    } else {
      return null;
    }
  }

  private static calculateRateOverInterval(
    currTransferred: number | undefined,
    currTimestamp: number | undefined,
    prevTransferred: number | undefined,
    prevTimestamp: number | undefined,
  ): number {
    if (
      typeof prevTransferred === "undefined" ||
      typeof currTransferred === "undefined" ||
      typeof prevTimestamp === "undefined" ||
      typeof currTimestamp === "undefined" ||
      prevTransferred >= currTransferred ||
      prevTimestamp >= currTimestamp
    ) {
      return 0;
    }

    return (
      (currTransferred - prevTransferred) /
      ((currTimestamp - prevTimestamp) / 1000)
    );
  }

  private static calculateIncrement(
    curr: number | undefined,
    prev: number | undefined,
  ) {
    if (
      typeof prev === "undefined" ||
      typeof curr === "undefined" ||
      prev >= curr
    ) {
      return 0;
    }
    return curr - prev;
  }

  private static calculateRateOverElapsedMs(
    currTotalTimeSec: number | undefined,
    currTotalCount: number | undefined,
    prevTotalTimeSec: number | undefined,
    prevTotalCount: number | undefined,
  ): number {
    if (
      typeof prevTotalCount === "undefined" ||
      typeof currTotalCount === "undefined" ||
      typeof prevTotalTimeSec === "undefined" ||
      typeof currTotalTimeSec === "undefined" ||
      prevTotalCount >= currTotalCount
    ) {
      return 0;
    }
    return (
      ((currTotalTimeSec - prevTotalTimeSec) /
        (currTotalCount - prevTotalCount)) *
      1000
    );
  }

  private static calculatePacketLossPercentage(
    currPacketsLostCount: number | undefined,
    currPacketsProcessedCount: number | undefined,
    prevPacketsLostCount: number | undefined,
    prevPacketsProcessedCount: number | undefined,
  ): number {
    if (
      typeof prevPacketsLostCount === "undefined" ||
      typeof currPacketsLostCount === "undefined" ||
      typeof prevPacketsProcessedCount === "undefined" ||
      typeof currPacketsProcessedCount === "undefined" ||
      currPacketsLostCount <= prevPacketsLostCount ||
      currPacketsProcessedCount <= prevPacketsProcessedCount
    ) {
      return 0;
    }

    return (
      ((currPacketsLostCount - prevPacketsLostCount) /
        (currPacketsProcessedCount -
          prevPacketsProcessedCount +
          (currPacketsLostCount - prevPacketsLostCount))) *
      100
    );
  }

  private calculateNumberOfPixels(): number {
    //NB. stream size and FPS may have changed during collection preiod
    const history = this.history.get();
    let pixelCount = 0;
    let currentFramesTransferred = history[0].framesTransferred || 0;

    history.forEach((stats: SummaryStats): void => {
      if (
        typeof stats.framesTransferred !== "undefined" &&
        typeof stats.width !== "undefined" &&
        typeof stats.height !== "undefined"
      ) {
        pixelCount +=
          stats.width *
          stats.height *
          (stats.framesTransferred - currentFramesTransferred);

        currentFramesTransferred = stats.framesTransferred;
      }
    });
    return pixelCount;
  }

  private async getStatsOrThrow(): Promise<RTCStatsReport> {
    if (!this.handle?.webrtcStuff.pc) {
      throw new Error("No active PC for this connection");
    }
    return this.handle.webrtcStuff.pc.getStats();
  }

  // with 2-second stats polling time these give us 20 seconds of data
  private history = new FixedFifo<SummaryStats>(10);
  private lastDebugTimestampMs = 0;

  private processStats(report: RTCStatsReport): void {
    const currStat = this.generateSummaryStats(report);

    this.iceConfig = this.generateIceConfig(report);

    this.history.push(currStat);

    const first = this.history.first();
    const last = this.history.last();

    const metrics = {
      roundTripTimeMs: Quality.calculateRateOverElapsedMs(
        last.totalRoundTripTime,
        last.responsesReceived,
        first.totalRoundTripTime,
        first.responsesReceived,
      ),
      processingTimeMs: Quality.calculateRateOverElapsedMs(
        last.totalProcessingTime,
        last.framesProcessed,
        first.totalProcessingTime,
        first.framesProcessed,
      ),
      processingTimePerByteMs:
        Quality.calculateIncrement(
          last.totalProcessingTime,
          first.totalProcessingTime,
        ) /
        Quality.calculateIncrement(
          last.bytesTransferred,
          first.bytesTransferred,
        ),
      processingTimePerPixelMs:
        Quality.calculateIncrement(
          last.totalProcessingTime,
          first.totalProcessingTime,
        ) / this.calculateNumberOfPixels(),
      availableOutgoingBitrate: last.availableOutgoingBitrate,
      fps: Quality.calculateRateOverInterval(
        last.framesTransferred,
        last.timestamp,
        first.framesTransferred,
        first.timestamp,
      ),
      bitrate: Quality.calculateRateOverInterval(
        last.bytesTransferred === undefined
          ? undefined
          : last.bytesTransferred * 8,
        last.timestamp,
        first.bytesTransferred === undefined
          ? undefined
          : first.bytesTransferred * 8,
        first.timestamp,
      ),
      packetsTransferred: Quality.calculateIncrement(
        last.packetsTransferred,
        first.packetsTransferred,
      ),
      pktLossPercent: Quality.calculatePacketLossPercentage(
        last.packetsLost,
        last.packetsTransferred,
        first.packetsLost,
        first.packetsTransferred,
      ),
    };

    this.stats = {
      packetLoss: metrics.pktLossPercent,
      fps: metrics.fps,
      bitrate: metrics.bitrate,
      bytes: last.bytesTransferred || 0,
      protocol: this.iceConfig?.protocol ?? "N/A",
      type: this.iceConfig?.type ?? "N/A",
    };

    if (!this.history.full()) {
      // FIFO isn't full yet - wait for a bit
      return;
    }

    if (
      last.timestamp &&
      last.timestamp > this.lastDebugTimestampMs + DEBUG_INTERVAL_MS
    ) {
      this.debug(metrics);
      this.lastDebugTimestampMs = last.timestamp;
    }

    if (metrics.packetsTransferred <= 0) {
      console.warn(
        {
          streamId: this.streamId,
        },
        "No packets received on this layer",
      );
      this.stalled();
      return;
    }

    this.flowing();

    if (this.limits === null) {
      return;
    }

    if (
      metrics.pktLossPercent > this.limits.downgrade.pktLossPercent ||
      metrics.roundTripTimeMs > this.limits.downgrade.roundTripTimeMs ||
      metrics.processingTimeMs > this.limits.downgrade.processingTimeMs
    ) {
      console.debug(
        {
          streamId: this.streamId,
        },
        "DOWNGRADE: pktLossPercent=",
        metrics.pktLossPercent,
        "/",
        this.limits.downgrade.pktLossPercent,
        "roundTripTimeMs=",
        metrics.roundTripTimeMs,
        "/",
        this.limits.downgrade.roundTripTimeMs,
        "processingTimeMs=",
        metrics.processingTimeMs,
        "/",
        this.limits.downgrade.processingTimeMs,
      );
      this.downgrade();
      return;
    }

    if (
      metrics.pktLossPercent < this.limits.upgrade.pktLossPercent &&
      metrics.roundTripTimeMs < this.limits.upgrade.roundTripTimeMs &&
      metrics.processingTimeMs < this.limits.upgrade.processingTimeMs
    ) {
      this.upgrade();
    }
  }

  protected reset() {
    this.history.clear();
  }

  protected abstract generateSummaryStats(report: RTCStatsReport): SummaryStats;
  protected abstract upgrade(): boolean;
  protected abstract downgrade(): boolean;

  protected stalled(): void {
    console.warn(
      {
        streamId: this.streamId,
      },
      "Video has stalled - no packets",
    );
    this.emit("error", new Error("Video has stalled"));
  }

  protected flowing(): void {
    // do nothing
  }

  protected abstract debug(metrics: {
    pktLossPercent: number;
    roundTripTimeMs: number;
    processingTimeMs: number;
  }): void;
}
