import React, { useEffect, useRef, useState } from "react";
import { SessionLoading, TroubleShooterNetworkResult } from "../../components";
import { useSessionContext } from "../../contexts/session-context/session-context";
import {
  defaultTestResultsList,
  EchoAdapter,
  FeedType,
  TestList,
  TestResult,
  TestResultsList,
} from "@proximie/media";
import { navigateToDashboard } from "../../utils";
import { useDashboardUrl } from "../../hooks/useDashboardUrl";
import { SYSTEM_USER_ID } from "../../constants";

export const generateCanvasVideoStream = (): MediaStream => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const c = document.createElement("canvas") as any;
  const ctx = c.getContext("2d");
  c.height = 360;
  c.width = 640;

  const strm = c.captureStream(24);

  let tick = 0;
  const tickInterval = setInterval(() => {
    if (strm.getTracks()[0].readyState === "ended") {
      clearInterval(tickInterval);
    }
    tick++;
    ctx.fillRect(tick, tick, 150 + tick, 75 + tick);
  }, 100);

  return strm;
};

interface TroubleShooterProps {
  onTestPassed: () => void;
}

const TroubleShooter = (
  props: TroubleShooterProps,
  //eslint-disable-next-line sonarjs/cognitive-complexity
): JSX.Element => {
  const context = useSessionContext();

  const dashboardUrl = useDashboardUrl();

  const [retryCount, setRetryCount] = useState(0);
  const [isMediaTestRunning, setIsMediaTestRunning] = useState(false);

  const [testResultsList, setTestResultsList] = useState<TestResultsList>(
    defaultTestResultsList,
  );

  const timerId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!context.serverParams) {
      timerId.current = setTimeout(() => {
        if (!context.serverParams) {
          setTestResultsList({
            socketIO: TestResult.Fail,
            audioServer: TestResult.DidNotRun,
            audioMedia: TestResult.DidNotRun,
            videoServer: TestResult.DidNotRun,
            videoMedia: TestResult.DidNotRun,
          });
        }
      }, 20000);
    }
    return () => {
      if (timerId?.current) {
        clearTimeout(timerId.current);
      }
    };
  }, [context.serverParams]);

  //get server info info from context, and check socket status
  useEffect(() => {
    // //check socketIO status:
    setTestResultsList((prev) => ({
      ...prev,
      socketIO: context?.socket?.isConnected
        ? TestResult.Pass
        : TestResult.Fail,
    }));
  }, [context]);

  //run the janus tests
  useEffect(() => {
    const setResult = (test: TestList, result: TestResult) => {
      setTestResultsList((prev) => ({
        ...prev,
        [test]: result,
      }));
    };

    const runSingleTest = async (
      type: FeedType,
      serverName: TestList,
      mediaName: TestList,
    ): Promise<void> => {
      let strm: MediaStream | null = null;

      if (!context.serverParams) {
        throw new Error("Invalid parameters");
      }

      try {
        strm = generateCanvasVideoStream();
        const adapter = new EchoAdapter(
          context.user?.id || SYSTEM_USER_ID,
          context.serverParams,
        );
        await adapter.start(type, strm, setResult);
      } catch (error) {
        switch (testResultsList[serverName]) {
          case TestResult.Unknown:
            setResult(serverName, TestResult.Fail);
            setResult(mediaName, TestResult.DidNotRun);
            break;
          case TestResult.Fail:
            setResult(mediaName, TestResult.DidNotRun);
            break;
          case TestResult.Pass:
            setResult(mediaName, TestResult.Fail);
            break;
        }
      } finally {
        if (strm) {
          strm.getTracks()[0].stop();
        }
      }
    };

    const runJanusTest = async () => {
      setIsMediaTestRunning(true);

      await Promise.all([
        runSingleTest(
          FeedType.Audio,
          TestList.audioServer,
          TestList.audioMedia,
        ),
        runSingleTest(
          FeedType.Camera,
          TestList.videoServer,
          TestList.videoMedia,
        ),
      ]);
    };

    //run Janus test, if test didnt start yet:
    if (!isMediaTestRunning && context.serverParams) {
      runJanusTest();
    }
  }, [isMediaTestRunning, context.serverParams, testResultsList]);

  //test status and results check
  useEffect(() => {
    // check all test results to set test passing status
    let result = TestResult.Pass;
    if (
      Object.values(testResultsList).filter(
        (test) => test === TestResult.Unknown,
      ).length > 0
    ) {
      result = TestResult.Unknown;
    } else if (
      Object.values(testResultsList).filter((test) => test === TestResult.Fail)
        .length > 0
    ) {
      result = TestResult.Fail;
    }

    // check test passing status:
    switch (result) {
      case TestResult.Pass:
        props.onTestPassed();
        console.info("Troubleshooter Passed", testResultsList);
        break;
      case TestResult.Fail:
        console.error("Troubleshooter Failed", testResultsList);
        break;
      default:
      // do nothing
    }
  }, [props, testResultsList]);

  const retryTest = () => {
    setRetryCount(retryCount + 1);
    setTestResultsList({
      ...defaultTestResultsList,
      socketIO: context?.socket?.isConnected
        ? TestResult.Pass
        : TestResult.Fail,
    });
    setIsMediaTestRunning(false);
  };

  const isTestRunning = (): boolean => {
    if (!testResultsList) return false;
    return (
      Object.values(testResultsList).filter(
        (test) => test === TestResult.Unknown,
      ).length > 0
    );
  };

  return (
    <>
      {!retryCount && isTestRunning() ? (
        <SessionLoading>Checking system</SessionLoading>
      ) : (
        <TroubleShooterNetworkResult
          isRunning={isTestRunning()}
          retryCount={retryCount}
          onclickRetry={retryTest}
          onclickBack={() => navigateToDashboard(dashboardUrl)}
          results={testResultsList}
        />
      )}
    </>
  );
};
export default TroubleShooter;
