import { ElipMask, FeedType, OutgoingVideo, RectMask } from "@proximie/media";

const LOCAL_STORAGE_VIDEO_KEY = "videos";

// {
// 	[userId]:{
// 		lastSessionId: sessionId,
// 		sessions:{
// 			[sessionId]:[
// 				video1,
// 				video2
// 			]
// 		}
// 	}
// }

// in localStorage we index the StoreInfo object by sessionId.  We do not allow
// more than one member in this object to prevent any memory leaks
export type StoreInfo = Record<string, SessionInfo>;
export type SessionInfo = {
  lastSessionId: string;
  sessions: Record<string, VideoInfo[]>;
};
export type VideoInfo = {
  sessionId: string;
  type: FeedType;
  streamId: string;
  deviceId: string;
  uniqueId: string;
  label: string;
  masks?: Array<RectMask | ElipMask>;
};

export default abstract class VideoStore {
  //LATER - should we ever store the video feeds in the backend?

  private static write(
    sessionId: string,
    userId: string,
    info: VideoInfo[],
  ): void {
    const sessionStore = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_VIDEO_KEY) ?? "{}",
    );

    //initialize user if empty
    if (!sessionStore[userId])
      sessionStore[userId] = {
        lastSessionId: sessionId,
        sessions: {},
      };

    //apply new info
    sessionStore[userId].sessions[sessionId] = info;
    sessionStore[userId].lastSessionId = sessionId;

    //save to storage
    localStorage.setItem(LOCAL_STORAGE_VIDEO_KEY, JSON.stringify(sessionStore));
    console.log(
      "VideoStore set=",
      localStorage.getItem(LOCAL_STORAGE_VIDEO_KEY),
    ); //TODO - remove me
  }

  static get(sessionId: string, userId: string): VideoInfo[] {
    let sessionStore: StoreInfo;
    try {
      const item = localStorage.getItem(LOCAL_STORAGE_VIDEO_KEY) || "{}";
      console.log("VideoStore get=", item); //TODO - remove me
      sessionStore = JSON.parse(item);
    } catch {
      return [];
    }

    if (!sessionStore[userId]) return [];

    // for backwards compatibility we set uniqueId to deviceId if it doesn't exist
    //LATER - remove me!
    return (sessionStore[userId].sessions[sessionId] ?? []).map(
      (video: VideoInfo): VideoInfo => {
        if (!video.uniqueId) {
          video.uniqueId = video.deviceId;
        }
        return video;
      },
    );
  }

  static getByUserId(userId: string): SessionInfo {
    let sessionStore: StoreInfo;
    try {
      const item = localStorage.getItem(LOCAL_STORAGE_VIDEO_KEY) || "{}";
      sessionStore = JSON.parse(item);
    } catch {
      return {
        lastSessionId: "",
        sessions: {},
      };
    }

    return (
      sessionStore[userId] ?? {
        lastSessionId: "",
        sessions: {},
      }
    );
  }

  static add(
    sessionId: string,
    userId: string,
    connection: OutgoingVideo,
    deviceId: string,
    uniqueId: string,
    label: string,
    masks?: Array<RectMask | ElipMask>,
  ): void {
    const videos = this.get(sessionId, userId).filter(
      (video: VideoInfo): boolean => video.streamId !== connection.streamId,
    );

    videos.push({
      sessionId: sessionId,
      streamId: connection.streamId,
      type: connection.type,
      deviceId,
      uniqueId,
      label,
      masks,
    });
    this.write(sessionId, userId, videos);
  }

  static remove(sessionId: string, userId: string, streamId: string): void {
    const videos = this.get(sessionId, userId).filter(
      (video: VideoInfo): boolean => video.streamId !== streamId,
    );

    this.write(sessionId, userId, videos);
  }

  static setMasks(
    sessionId: string,
    userId: string,
    streamId: string,
    masks: Array<RectMask | ElipMask>,
  ): void {
    const videos = this.get(sessionId, userId).map(
      (video: VideoInfo): VideoInfo =>
        video.streamId === streamId ? { ...video, masks } : video,
    );

    this.write(sessionId, userId, videos);
  }

  static clearMasks(sessionId: string, userId: string, streamId: string): void {
    const videos = this.get(sessionId, userId).map(
      (video: VideoInfo): VideoInfo =>
        video.streamId === streamId ? { ...video, masks: undefined } : video,
    );

    this.write(sessionId, userId, videos);
  }
}
