import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import SidebarPanel from "../SidebarPanel/SidebarPanel";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { useTranslation } from "react-i18next";
import { Permissions } from "@proximie/components";

const { Restricted } = Permissions;

interface AppointmentInfoProps {
  closePanel: () => void;
}

const Label = ({ children }: { children: React.ReactNode }) => (
  <Typography variant={"h6"} color={"primary"}>
    {children}
  </Typography>
);

const Value = ({ children, ...props }: { children: React.ReactNode }) => (
  <Typography {...props} variant={"body2"} color={"primary"} gutterBottom>
    {children}
  </Typography>
);

const AppointmentInfo = ({ closePanel }: AppointmentInfoProps) => {
  const { session, participants } = useSessionContext();
  const { t } = useTranslation();

  return (
    <SidebarPanel title="Session details" closePanel={closePanel}>
      {session ? (
        <>
          <Box px={2}>
            <Divider />
          </Box>
          <Box px={2} pt={2}>
            <Label>{t("liveSession.sessionInfoPanel.sessionNameHeader")}</Label>
            <Value data-cy="info-name">{session.title}</Value>
            <Restricted to={"speciality:read"}>
              <Label>
                {t("liveSession.sessionInfoPanel.specialityHeader")}
              </Label>
              <Value data-cy="info-speciality">{session.type.name}</Value>
            </Restricted>
            <Label>{t("liveSession.sessionInfoPanel.descriptionHeader")}</Label>
            <Value data-cy="info-description">{session.description}</Value>
            <Label>{t("liveSession.sessionInfoPanel.ownerHeader")}</Label>
            <Value data-cy="info-owner">
              {session.owner.name || session.owner.email}
            </Value>
            <Label>
              {t("liveSession.sessionInfoPanel.participantsHeader")}
            </Label>
            <Value data-cy="info-invited-count">
              {participants?.length ?? 0}
            </Value>
          </Box>
        </>
      ) : (
        <Box p={2}>Could not load appointment information</Box>
      )}
    </SidebarPanel>
  );
};

export default AppointmentInfo;
