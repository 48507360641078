import { ExceptionCodes } from "./lib/error-handling/exception-codes";
import * as exceptions from "./lib/error-handling/exceptions";
import * as api from "./lib/axios/axios-base-requests";
import * as interceptors from "./lib/axios/axios-interceptors";
import * as dateUtilities from "./lib/utils/dateUtils";
import * as countryUtilities from "./lib/utils/countryUtils";

import {
  maskEmail,
  isEmailValid,
  getJoinedNameAndEmail,
  stripHtml,
  stripHtmlAndLinks,
} from "./lib/utils/emailUtils";
import { isLocalOrE2EEnvironment } from "./lib/utils/commonFunction";

export * from "./lib/logger/logger";
export * from "./lib/logger/common";
export * from "./lib/constants";
export * from "./lib/durationUtilities";
export * from "./lib/error-handling/types/index";

export {
  ExceptionCodes,
  exceptions,
  api,
  interceptors,
  maskEmail,
  isLocalOrE2EEnvironment,
  isEmailValid,
  getJoinedNameAndEmail,
  dateUtilities,
  countryUtilities,
  stripHtml,
  stripHtmlAndLinks,
};
