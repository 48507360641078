import axios from "axios";

import { api, noContentApi, replaceIn } from "@proximie/api";
import { ORGANISATION_ID_QUERY_PARAM_NAME } from "@proximie/common";

/* eslint-disable @typescript-eslint/no-explicit-any */

axios.interceptors.response.use((response) => response.data);
export const setAxiosTokenInterceptor = async (
  token: string,
): Promise<void> => {
  axios.defaults.headers["Authorization"] = `Bearer ${token}`;
};

export const setCurrentOrganisationIdQueryParam = (
  organisationId: number,
): void => {
  if (organisationId) {
    axios.defaults.params[ORGANISATION_ID_QUERY_PARAM_NAME] = organisationId;
  }
};

export async function get<Req extends Record<string, any>, Res>(
  apiObject: api<Req, Res>,
  idsInUrl: Req,
  opts: any = {},
): Promise<Res> {
  const path = replaceIn(apiObject.path, idsInUrl);
  return axios.get(path, opts);
}

export async function post<Req, Res>(
  apiObject: api<Req, Res>,
  body: Req,
  opts: any = {},
): Promise<Res> {
  return axios.post(apiObject.path, body, opts);
}

export async function put<Req extends Record<string, any>>(
  apiObject: noContentApi<Req>,
  idsInUrl: Record<string, number | string> = {},
  body: Req,
  opts: any = {},
): Promise<void> {
  const path = replaceIn(apiObject.path, idsInUrl);
  await axios.put(path, body, opts);
}
