import * as t from "io-ts";
import { makeRoute } from "@proximie/api";
import { UUID } from "io-ts-types/lib/UUID";
import { common } from "@proximie/api";

const RecordsToDeIdentifyRequest = t.record(t.string, t.unknown);

const DeIdentifiedRecords = t.record(t.string, UUID);

const ReIdentificationRequest = t.type({
  deIds: t.array(UUID),
});

const DeleteRecordsRequest = t.type({
  deIds: t.array(UUID),
});

const PersonalInformationRecord = t.type({
  id: t.string,
  value: t.string,
});

const SearchByValuesDeIdentifiedRecordsRequest = t.type({
  values: t.array(t.string),
});

export const deIdentify = makeRoute(
  "/personal-information/de-id",
  RecordsToDeIdentifyRequest,
  DeIdentifiedRecords,
);

export const reIdentify = makeRoute(
  "/personal-information/re-id",
  ReIdentificationRequest,
  t.array(PersonalInformationRecord),
);

export const deleteRecords = makeRoute(
  "/personal-information",
  DeleteRecordsRequest,
  common.empty,
);

export const searchByValues = makeRoute(
  "/personal-information/search-by-values",
  SearchByValuesDeIdentifiedRecordsRequest,
  t.array(PersonalInformationRecord),
);

export type SearchByValuesDeIdentifiedRecordsRequest = t.TypeOf<
  typeof SearchByValuesDeIdentifiedRecordsRequest
>;

export type RecordsToDeIdentifyRequest = t.TypeOf<
  typeof RecordsToDeIdentifyRequest
>;

export type DeleteRecordsRequest = t.TypeOf<typeof DeleteRecordsRequest>;

export type DeIdentifiedRecords = t.TypeOf<typeof DeIdentifiedRecords>;

export type PersonalInformationRecord = t.TypeOf<
  typeof PersonalInformationRecord
>;
