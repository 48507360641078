import ConnectionJanusVideoIncoming from "../Janus/ConnectionJanusVideoIncoming";
import { ConnectionOptions, ConnectionType, Direction } from "../Connection";
import Device, { DeviceType } from "../../Devices/Device";
import DeviceDcp, { DcpType } from "../../Devices/DeviceDcp";
import DeviceVirtual from "../../Devices/DeviceVirtual";
import Endpoint, { EndpointType } from "../../Endpoints/Endpoint";
import EndpointDcp from "../../Endpoints/EndpointDcpSession";
import EndpointJanusVideo from "../../Endpoints/Janus/EndpointJanusVideo";
import {
  IStreamCapabilityLocalJanus,
  IStreamCapabilityStateValue,
  StreamingModeType,
} from "@proximie/dcp";
import { BITRATE, CODEC } from "../../../Constants";
import { ConnectionMetadata } from "../../../../index";
import { JanusJS } from "@proximie/janus-gateway";

const LOCAL_ROOM_ID = "local-room";

export default class ConnectionDcpVideoOutgoing extends ConnectionJanusVideoIncoming {
  override connectionType = ConnectionType.DcpVideoOutgoing;
  // it's not strictly outgoing....
  override direction = Direction.Outgoing;
  private timerId: ReturnType<typeof setTimeout> | null = null;
  private isConnected = false;

  constructor(
    endpoint: Endpoint,
    device: Device,
    public streamId: string,
    public options: ConnectionOptions,
  ) {
    super(endpoint, device, streamId, options);
    console.debug({ streamId }, "Creating pending connection");

    this.timerId = setTimeout(() => {
      console.debug("Pending timer expired");
      // we do not send an error - an error indicates that we should retry
      this.close();
    }, 45000);

    this.once("connected", () => {
      if (this.timerId) {
        console.debug({ streamId }, "Connected - clearing timeout");
        clearTimeout(this.timerId);
        this.timerId = null;
      }
    });
  }

  private isStopStreaming(error?: Error): boolean {
    // return true if we should let the originator deal with the error
    return error?.message !== "Device has closed";
  }

  override async close(error?: Error): Promise<void> {
    console.debug(
      { streamId: this.streamId },
      "ConnectionDcpVideoOutgoing:close - error=",
      error,
    );

    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }

    const device = this.devices[DeviceType.Dcp];
    if (device && device instanceof DeviceDcp) {
      device.off("state", this.deviceUpdate);

      if (this.isStopStreaming(error)) {
        const endpoint = this.endpoints[EndpointType.Dcp];
        if (endpoint) {
          try {
            await endpoint.request(
              {
                deviceId: device.deviceId,
                component: device.options.component,
                serviceName: device.serviceName,
              },
              {
                mode: "none",
                //LATER - pre-LMS backwards compatibility
                streaming: false,
              },
            );
          } catch (error) {
            console.warn(
              { streamId: this.streamId },
              "Failed to halt stream - error=",
              error,
            );
          }
        }
      }
    }
    await super.close(error);
  }

  kick(): Promise<void> {
    console.debug(
      { streamId: this.streamId },
      `Outgoing DCP Kicked ${
        (this.devices[DeviceType.Dcp] as DeviceDcp)?.options.component
      }`,
    );
    return this.close();
  }

  //LATER - pre-LMS backwards compatibility - start
  private static extractMode(value?: IStreamCapabilityStateValue): {
    mode: StreamingModeType | null;
    isLegacy: boolean;
  } {
    if (!value) {
      return { mode: null, isLegacy: false };
    }
    if (value.mode) {
      return { mode: value.mode, isLegacy: false };
    }
    return { mode: value.streaming ? "remote" : "none", isLegacy: true };
  }

  //LATER - pre-LMS backwards compatibility - end

  private async startLocalJanus(
    janusConfig: IStreamCapabilityLocalJanus,
  ): Promise<void> {
    console.debug({ streamId: this.streamId }, "Starting local janus");

    if (!this.options.globals) {
      console.warn(
        { streamId: this.streamId },
        "No globals defined for outgoing DCP connection",
      );
      throw new Error("No globals defined for outgoing DCP connection");
    } else if (typeof this.options.globals.janusMutex !== "undefined") {
      // another connection has started Janus - wait for it to complete
      console.debug(
        { streamId: this.streamId },
        "Waiting for local janus to start",
      );
    } else if (this.options.globals.localJanus) {
      console.debug({ streamId: this.streamId }, "Local janus already started");
      this.options.globals.janusMutex = Promise.resolve();
    } else {
      this.options.globals.janusMutex = new Promise<void>((resolve, reject) => {
        if (!this.options.globals) {
          console.warn({ streamId: this.streamId }, "Globals are not defined");
          return reject(new Error("Globals are not defined"));
        }

        this.options.globals.localJanus = new EndpointJanusVideo({
          userId: this.userId,
          sessionId: LOCAL_ROOM_ID,
          serverUrl: {
            url: janusConfig.url,
            httpUrl: janusConfig.url,
            apiKey: janusConfig.secret,
          },
          iceServers: this.options.globals.serverParams?.iceServers || [],
          iceTransportPolicy: "all",
          codec: CODEC,
          bitrate: BITRATE,
        });

        this.options.globals.localJanus.on("closed", () => {
          console.warn({ streamId: this.streamId }, "Local janus closed");
          delete this.options.globals?.localJanus;
        });

        this.options.globals.localJanus.on("error", (error: Error) => {
          console.warn(
            { streamId: this.streamId },
            "Local janus errored - error=",
            error,
          );
          delete this.options.globals?.localJanus;
        });

        this.options.globals.localJanus
          .connect()
          .then(resolve)
          .catch((error: Error) => {
            console.warn("Failed to connect to local Janus - error=", error);
            delete this.options.globals?.localJanus;
            reject(error);
          });
      });
    }

    try {
      await this.options.globals.janusMutex;
    } finally {
      this.options.globals.janusMutex = undefined;
    }
  }

  private deviceUpdate = async (): Promise<void> => {
    const endpoint = this.endpoints[EndpointType.Dcp];
    if (!endpoint) {
      throw new Error("No DCP endpoint");
    }

    const device = this.devices[DeviceType.Dcp];
    if (!(device instanceof DeviceDcp)) {
      throw new Error("DCP device does not exist");
    }

    const { mode, isLegacy } = ConnectionDcpVideoOutgoing.extractMode(
      device.state?.STREAM,
    );
    if (mode === null) {
      console.warn({ streamId: this.streamId }, "No state present - ignoring");
      return;
    }

    console.debug(
      { streamId: this.streamId },
      "ConnectionDcpVideoOutgoing:deviceUpdate - update=",
      mode,
      device.state?.STREAM,
    );

    switch (mode) {
      case "none":
        this.isConnected = false;
        await this.close(
          device.state?.STREAM?.reason === "user-request"
            ? undefined
            : new Error("Stream closed by DCP"),
        );
        break;
      case "local":
      case "remote":
        if (
          !isLegacy &&
          !this.isConnected &&
          device.state?.STREAM?.localJanus &&
          this.options.hostDeviceId &&
          this.options.globals
        ) {
          try {
            this.isConnected = true;

            await this.startLocalJanus(device.state.STREAM.localJanus);

            if (this.options.globals?.localJanus) {
              const device = new DeviceVirtual("local-janus-device");
              this.addDevice(device, this.options.globals.localJanus);
            }
          } catch {
            this.isConnected = false;
          }
        }
    }
  };

  override async open(): Promise<void> {
    console.debug(
      { streamId: this.streamId },
      "ConnectionDcpVideoOutgoing:open",
    );
    const endpoint = this.endpoints[EndpointType.Dcp];
    if (!endpoint || !(endpoint instanceof EndpointDcp)) {
      throw new Error("No DCP endpoint");
    }

    const device = this.devices[DeviceType.Dcp];
    if (!device || !(device instanceof DeviceDcp)) {
      throw new Error("DCP device does not exist");
    }

    if (!device.hasDcpType(DcpType.Stream) || !device.serviceName) {
      throw new Error("Service is not defined");
    }

    device.on("state", this.deviceUpdate);

    const { mode: currMode } = ConnectionDcpVideoOutgoing.extractMode(
      device.state?.STREAM,
    );

    if (currMode === null) {
      console.debug({ streamId: this.streamId }, "No state - ignore for now");
      return;
    }

    if (currMode !== "none") {
      // if we're already streaming then connect to it now
      console.debug(
        { streamId: this.streamId },
        `Mode=${String(currMode)} - connecting immediately`,
      );
      this.deviceUpdate();
      // carry on - we might have to go from "local" -> "remote" or vice versa
    }

    if (currMode !== "remote") {
      endpoint.request(
        {
          deviceId: device.deviceId,
          component: device.options.component,
          serviceName: device.serviceName,
        },
        {
          mode: "remote",
          //LATER - pre-LMS backwards compatibility
          streaming: true,
          streamId: this.streamId,
          metadata: {
            deviceType: device.deviceType,
            mediaType: device.mediaType,
            devices: [
              {
                deviceId: device.deviceId,
                component: device.options.component,
                services: device.serviceName,
              },
            ],
            ...this.options.params,
            capabilities: {
              ...this.options.params?.capabilities,
              canMask: false,
              canPTZ: false,
            },
          },
        },
      );
    }
  }

  protected override join(params: ConnectionMetadata): Promise<void> {
    const dcpDevice = this.devices[DeviceType.Dcp];
    if (!dcpDevice || !(dcpDevice instanceof DeviceDcp)) {
      throw new Error("DCP device does not exist");
    }

    console.debug(
      { streamId: this.streamId },
      "ConnectionDcpVideoOutgoing:join",
      params,
      dcpDevice.options.component,
    );

    return new Promise<void>((resolve, reject) => {
      const register = {
        request: "join",
        room: "local-room",
        ptype: "subscriber",
        feed: dcpDevice.options.component,
      };

      this.handle?.send({
        message: register,
        success: resolve,
        error: (error: string) => reject(new Error(error)),
      } as unknown as JanusJS.PluginMessage);
    });
  }

  override addDevice(device: Device, endpoint: Endpoint) {
    super.addDevice(device, endpoint);
    // a bit of a fudge this - we need to decide if addDevice should be synchronous or not....
    this.startJanus(endpoint).catch((error: Error) => {
      console.warn("Failed to start Janus - error=", error);
      return this.close(error);
    });
  }

  //LATER - pre-LMS backwards compatibility - start
  public get isLegacyPxKit(): boolean {
    const device = this.devices[DeviceType.Dcp];
    if (!device || !(device instanceof DeviceDcp)) {
      throw new Error("DCP device does not exist");
    }

    if (!device.hasDcpType(DcpType.Stream) || !device.serviceName) {
      throw new Error("Service is not defined");
    }

    const stream = device.state?.STREAM;

    return stream ? !("mode" in stream) : true;
  }

  //LATER - pre-LMS backwards compatibility - end
}
