import {
  CustomerSupportDialog,
  DialogTitle,
  Permissions,
  SnackbarContext,
} from "@proximie/components";
import React, { FC, useEffect, useState } from "react";
import { Chat, ChatMessage } from "../Chat/Chat";
import PtzPanel from "../PtzPanel/PtzPanel";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton as MuiIconButton,
  Tooltip,
} from "@mui/material";
import ParticipantsList from "../ParticipantsList/ParticipantsList";
import AppointmentInfo from "../AppointmentInfo/AppointmentInfo";
import ChatIcon from "../ChatIcon/ChatIcon";
import { PtzUser } from "../../utils/PtzUser";
import { MediaVideo, AudioParticipant } from "@proximie/media";
import OpenWidthIcon from "@mui/icons-material/OpenWith";
import HelpIcon from "@mui/icons-material/Help";
import { ActiveTab } from "../constants";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from "@mui/icons-material/Groups";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { environment } from "../../../environments/environment";
import { useTranslation } from "react-i18next";

const { usePermission } = Permissions;
export const confirmLogOutMessage =
  "Are you sure you want to leave this session?";

export interface SideBarProps {
  chats: ChatMessage[];
  audioParticipants: AudioParticipant[];
  muteAll: () => Promise<void>;
  setMuteState: (streamId: string, isMuted: boolean) => Promise<void>;
  onLeave: () => void;
  requestSession: () => void;
  ptzUsers: Record<string, PtzUser>;
  connections: Array<MediaVideo>;
  activeTab: ActiveTab;
  setActiveTab: React.Dispatch<React.SetStateAction<ActiveTab>>;
  selectedPtzDevice: string | undefined;
  setSelectedPtzDevice: (value: string | undefined) => void;
  videoWithMask: boolean;
}

//eslint-disable-next-line sonarjs/cognitive-complexity
const SideBar: FC<SideBarProps> = (props: SideBarProps): JSX.Element => {
  const { t } = useTranslation();
  const [supportTicketRaised, setSupportTicketRaised] = useState(false);
  const { sessionId, organisation, user, sessionParams } = useSessionContext();

  const [numberOfReadMsgs, setNumberOfReadMsgs] = useState<number>(0);
  const { showSnackbar } = SnackbarContext.useSnackbarContext();

  const activeTab = props.activeTab;
  const setActiveTab = props.setActiveTab;
  useEffect(() => {
    if (activeTab === ActiveTab.CHAT) setNumberOfReadMsgs(props.chats.length);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chats]);

  const updateActiveTab = (tab: ActiveTab) => {
    if (tab === ActiveTab.PTZPANEL && activeTab !== ActiveTab.PTZPANEL) {
      props.requestSession();
    } else if (tab === ActiveTab.CHAT) {
      setNumberOfReadMsgs(props.chats.length);
    }
    setActiveTab((oldTab) => {
      return tab === oldTab ? ActiveTab.NONE : tab;
    });
  };

  const getColour = (tab: ActiveTab) => {
    return activeTab === ActiveTab.NONE || activeTab === tab
      ? "primary"
      : "default";
  };

  const handleRaiseSupportTicketClick = (
    _event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSupportTicketRaised(true);
  };

  const onCloseSupportTicketPopup = () => {
    setSupportTicketRaised(false);
    updateActiveTab(ActiveTab.NONE);
  };

  const handleSupportTicketError = () => {
    showSnackbar({
      message: {
        title: t(
          "common.components.snackbar.messages.failedToSubmitSupportTicketTitle",
        ),
        body: t(
          "common.components.snackbar.messages.failedToSubmitSupportTicket",
        ),
      },
      severity: "error",
    });
  };

  const showSupportForm = usePermission("support-ticket:create");

  return (
    <Box display={"flex"}>
      <CustomerSupportDialog
        onClose={onCloseSupportTicketPopup}
        onError={handleSupportTicketError}
        open={supportTicketRaised}
        baseUrl={environment.api.baseUrl}
        mode="in-session"
        context={{
          organizationName: organisation?.name,
          userName: user?.name,
          userId: user?.id,
          sessionId: sessionParams?.sessionId,
          streamId: sessionParams?.streamId,
          email: user?.email,
          organisations: organisation
            ? [
                {
                  name: organisation.name,
                  id: organisation.id,
                },
              ]
            : [],
        }}
        supportLines={environment.supportLines}
        showSupportForm={showSupportForm}
      />

      {activeTab === ActiveTab.PARTICIPANTS && (
        <ParticipantsList
          audioParticipants={props.audioParticipants}
          setMuteState={props.setMuteState}
          muteAll={props.muteAll}
          closePanel={() => updateActiveTab(ActiveTab.PARTICIPANTS)}
        />
      )}

      {activeTab === ActiveTab.SESSIONINFO && (
        <AppointmentInfo
          closePanel={() => updateActiveTab(ActiveTab.SESSIONINFO)}
        />
      )}

      {activeTab === ActiveTab.CHAT && (
        <Chat
          className={""}
          messages={props.chats}
          closePanel={() => updateActiveTab(ActiveTab.CHAT)}
        />
      )}

      {activeTab === ActiveTab.PTZPANEL && (
        <PtzPanel
          ptzUsers={props.ptzUsers}
          connections={props.connections}
          closePanel={() => updateActiveTab(ActiveTab.PTZPANEL)}
          selectedPtzDevice={props.selectedPtzDevice}
          setSelectedPtzDevice={props.setSelectedPtzDevice}
          videoWithMask={props.videoWithMask}
        />
      )}

      <Box
        flexDirection="column"
        display="flex"
        className="sidebar"
        aria-label="side-bar"
        height={"100%"}
        sx={{ width: 56 }}
      >
        <Tooltip title="Participants" placement="left">
          <MuiIconButton
            aria-label="network"
            name="network"
            color={getColour(ActiveTab.PARTICIPANTS)}
            sx={{ mt: 2 }}
            data-cy="network-button"
            onClick={() => {
              updateActiveTab(ActiveTab.PARTICIPANTS);
            }}
          >
            <GroupsIcon />
          </MuiIconButton>
        </Tooltip>

        <Tooltip title="Chat" placement="left">
          <MuiIconButton
            color={getColour(ActiveTab.CHAT)}
            sx={{ mt: 2 }}
            onClick={() => {
              updateActiveTab(ActiveTab.CHAT);
            }}
            data-testid="chat-button"
          >
            <ChatIcon
              numberOfUnreadMessage={props.chats.length - numberOfReadMsgs}
            />
          </MuiIconButton>
        </Tooltip>

        <Tooltip title="PTZ Camera Control" placement="left">
          <MuiIconButton
            aria-label="PTZ"
            name="PTZ"
            color={getColour(ActiveTab.PTZPANEL)}
            sx={{ mt: 1 }}
            onClick={() => {
              updateActiveTab(ActiveTab.PTZPANEL);
            }}
            data-cy="ptz-button"
          >
            <OpenWidthIcon />
          </MuiIconButton>
        </Tooltip>

        <Tooltip title="Info" placement="left">
          <MuiIconButton
            aria-label="info"
            name="info"
            color={getColour(ActiveTab.SESSIONINFO)}
            sx={{ mt: 2 }}
            data-cy="info-button"
            onClick={() => {
              updateActiveTab(ActiveTab.SESSIONINFO);
            }}
          >
            <InfoIcon />
          </MuiIconButton>
        </Tooltip>

        <Tooltip title="Contact support" placement="left">
          <MuiIconButton
            aria-label="Contact support"
            name="Contact support"
            color={getColour(ActiveTab.SUPPORT)}
            sx={{ mt: 2 }}
            data-testid="customer-support-button"
            data-cy="customer-support-button"
            onClick={(e) => {
              updateActiveTab(ActiveTab.SUPPORT);
              handleRaiseSupportTicketClick(e);
            }}
          >
            <HelpIcon />
          </MuiIconButton>
        </Tooltip>

        <Tooltip title="Leave session" placement="left">
          <MuiIconButton
            aria-label="logout"
            name="logout"
            color={getColour(ActiveTab.LOGOUT)}
            sx={{ mt: 2 }}
            date-cy="logout-button"
            onClick={() => {
              updateActiveTab(ActiveTab.LOGOUT);
            }}
          >
            <LogoutIcon />
          </MuiIconButton>
        </Tooltip>
      </Box>

      <Dialog
        open={activeTab === ActiveTab.LOGOUT}
        onClose={() => {
          updateActiveTab(ActiveTab.NONE);
        }}
      >
        <DialogTitle
          onClose={() => {
            updateActiveTab(ActiveTab.NONE);
          }}
        >
          {confirmLogOutMessage}
        </DialogTitle>

        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={props.onLeave}
            size={"large"}
          >
            Leave
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              updateActiveTab(ActiveTab.NONE);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SideBar;
