import React from "react";
import { ChatMessage } from "../components/Chat/Chat";
import SessionParams from "./SessionParams";
import SocketIoClientWrapper from "../wrappers/SocketIOClientWrapper/SocketIOClientWrapper";
import { User } from "../hooks/useUser";
import { MediaVideo } from "@proximie/media";
import { ServerParams } from "@proximie/media";
import { SessionC, OrganisationC } from "../hooks/useSession/useSession";
import { Participant } from "../hooks/useParticipants/useParticipants";

export interface ShowForPermissionProps {
  invert?: boolean;
  permission: string | string[];
  additionalPredicate?: (isAllowed: boolean) => boolean;
  children: any;
}

export type FeedSpotlight = {
  streamId: string;
  spotlightedBy: { userId: string };
};

export enum SessionFeedView {
  SPOTLIGHT_VIEW,
  GRID_VIEW,
}

export default interface SessionContext {
  serverParams?: ServerParams | null;
  sessionParams: SessionParams | null;
  session?: SessionC;
  sessionId?: string;
  organisation?: OrganisationC;
  participants?: Participant[];
  inviteParticipant?: (userId: string) => Promise<void>;
  requestSession: () => void;
  chats?: ChatMessage[];
  endSession?: () => void;
  leaveSession?: () => void;
  socket?: SocketIoClientWrapper;
  ShowForPermission: React.FC<ShowForPermissionProps>;
  checkMyPermission: (permission: string | string[]) => boolean;
  role?: string;
  user?: User;
  spotlight: {
    spotlightedFeed: FeedSpotlight | null;
    requestSpotlightedFeeds: () => void;
    setSpotlightedFeed: (streamId: string | null) => void;
  };
  view: SessionFeedView;
  setView: (view: SessionFeedView) => void;
  setSessionVideos: (videos: MediaVideo[]) => void;
}
