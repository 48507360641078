/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Slider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SidebarPanel from "../SidebarPanel/SidebarPanel";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTheme } from "@mui/material/styles";
import { liveApiSockets } from "@proximie/dataregion-api";
import {
  EventTypes,
  PtzUser,
  PtzUserSingleValue,
  PtzUserValues,
} from "../../utils/PtzUser";
import { MediaVideo } from "@proximie/media";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { Participant } from "../../hooks/useParticipants/useParticipants";

export interface PtzPanelProps {
  ptzUsers: Record<string, PtzUser>;
  connections: Array<MediaVideo>;
  closePanel: () => void;
  selectedPtzDevice: string | undefined;
  setSelectedPtzDevice: (value: string | undefined) => void;
  videoWithMask: boolean;
}

const MID_GRAY_COLOR = "rgba(0, 0, 0, .5)";
const UNUSED_USER = null;

type MovementCommands = "PAN" | "TILT";

const sensitivitymarks = [
  {
    value: 5,
    label: <Typography variant={"body2"}>Slow</Typography>,
  },
  {
    value: 250,
    label: <Typography variant={"body2"}>Fast</Typography>,
  },
];

const zoomMarks = [
  {
    value: 0,
    label: (
      <ZoomOutIcon
        sx={{
          fontSize: 30,
          paddingBottom: "5px",
          color: MID_GRAY_COLOR,
        }}
      />
    ),
  },
  {
    value: 100,
    label: (
      <ZoomInIcon
        sx={{ fontSize: 30, paddingTop: "15px", color: MID_GRAY_COLOR }}
      />
    ),
  },
];

function titleCase(str: string) {
  return str
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

const PtzPanel = ({
  ptzUsers,
  connections,
  closePanel,
  selectedPtzDevice,
  setSelectedPtzDevice,
  videoWithMask,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
PtzPanelProps): JSX.Element => {
  const { user, participants } = useSessionContext();
  const { colors } = useTheme();

  const theme = useTheme();

  const [values, setValues] = useState<PtzUserValues | null>(null);
  const [controllerId, setControllerId] = useState<string | null>(UNUSED_USER);

  const [uncommitedZoom, setUncommitedZoom] = useState<number>(0);
  const [isShowRequested, setIsShowRequested] = useState<boolean>(false);

  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const accordElem = useRef(null);

  //setup msgs event listeners
  useEffect(() => {
    // store so we can compare and ignore any late arriving events
    const streamId = selectedPtzDevice;

    const receiveControlNotification = (
      myControllerId: string,
      _requesterId: string,
    ): void => {
      if (streamId !== selectedPtzDevice) return;
      setControllerId(myControllerId);
    };

    const receiveStatusNotification = (myValues: PtzUserValues): void => {
      if (streamId !== selectedPtzDevice) return;

      if (myValues.ZOOM) {
        setUncommitedZoom(getZoomValue(myValues.ZOOM));
      }
      setValues(myValues);
    };

    setValues(null);
    setControllerId(UNUSED_USER);

    if (selectedPtzDevice && ptzUsers[selectedPtzDevice]) {
      ptzUsers[selectedPtzDevice].on(
        EventTypes.ControlNotification,
        receiveControlNotification,
      );
      ptzUsers[selectedPtzDevice].on(
        EventTypes.StatusNotification,
        receiveStatusNotification,
      );
      ptzUsers[selectedPtzDevice].requestStatusAndControl();
    }

    return () => {
      if (selectedPtzDevice && ptzUsers[selectedPtzDevice]) {
        ptzUsers[selectedPtzDevice].off(
          EventTypes.ControlNotification,
          receiveControlNotification,
        );
        ptzUsers[selectedPtzDevice].off(
          EventTypes.StatusNotification,
          receiveStatusNotification,
        );
      }
    };
  }, [selectedPtzDevice, ptzUsers]);

  //default device selection if none is selected
  useEffect(() => {
    if (selectedPtzDevice) {
      const device = connections.find((currConnection: MediaVideo): boolean => {
        if (!currConnection.params.devices) {
          return false;
        }

        return currConnection.streamId === selectedPtzDevice;
      });
      if (!device || !ptzUsers[selectedPtzDevice]) {
        setSelectedPtzDevice(undefined);
        return;
      }
    }

    if (!selectedPtzDevice) {
      let lastControlTime = -1;

      let myStreamId: string | undefined;

      //get proper selection from history
      Object.keys(ptzUsers).forEach((thisStreamId: string): void => {
        const connection = connections.find(
          (myConnection: MediaVideo): boolean => {
            if (!myConnection.params.devices) {
              return false;
            }

            return myConnection.streamId === thisStreamId;
          },
        );
        if (!connection) {
          return;
        }

        if (
          ptzUsers[thisStreamId].timeOfLastControllerAssigned > lastControlTime
        ) {
          lastControlTime = ptzUsers[thisStreamId].timeOfLastControllerAssigned;
          myStreamId = thisStreamId;
        }
      });

      myStreamId && setSelectedPtzDevice(myStreamId);
    }
  }, [selectedPtzDevice, ptzUsers, connections, values, setSelectedPtzDevice]);

  const canControl = (): boolean => {
    if (!selectedPtzDevice) {
      return false;
    }
    return (
      ptzUsers[selectedPtzDevice] && ptzUsers[selectedPtzDevice].isControllable
    );
  };

  const handleChangeCamera = (event: SelectChangeEvent<string>): void => {
    setSelectedPtzDevice(event.target.value);
  };

  const handleControlClick = (): void => {
    if (!selectedPtzDevice) return;

    if (controllerId !== UNUSED_USER) {
      setIsShowRequested(true);
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setIsShowRequested(false);
      }, 3000);
    }
    ptzUsers[selectedPtzDevice].requestControl();
  };

  const handleSensitivityPanel = (
    _event: React.SyntheticEvent,
    expanded: boolean,
  ): void => {
    setTimeout(() => {
      if (expanded && accordElem.current) {
        (accordElem.current as HTMLDivElement).scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }, 500);
  };

  const handleCancelClick = (): void => {
    if (!selectedPtzDevice) return;
    ptzUsers[selectedPtzDevice].cancelControl();
  };

  const handleChangeMounting = (
    event: SelectChangeEvent<liveApiSockets.PtzMountings>,
  ): void => {
    if (!canControl() || !selectedPtzDevice || !values?.mounting) return;

    ptzUsers[selectedPtzDevice].setMounting(
      values.mounting.component,
      values.mounting.service,
      event.target.value as liveApiSockets.PtzMountings,
    );
  };

  const handlePanTiltCommand = (
    action: MovementCommands,
    value: number,
  ): void => {
    if (!canControl() || !selectedPtzDevice || !values?.[action]) return;

    ptzUsers[selectedPtzDevice].sendPanTilt(
      values[action].component,
      values[action].service,
      action,
      value,
    );
  };

  const handleChangeZoom = (_event: Event, value: number | number[]): void => {
    if (!canControl() || !selectedPtzDevice || !values) return;
    setUncommitedZoom(value as number);
  };

  const handleZoomOnChangeCommitted = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[],
  ): void => {
    if (!canControl() || !selectedPtzDevice || !values?.ZOOM) return;

    const myValue = Array.isArray(value) ? value[0] : value;

    ptzUsers[selectedPtzDevice].sendZoom(
      values.ZOOM.component,
      values.ZOOM.service,
      myValue,
    );
    setUncommitedZoom(myValue);
  };

  const handleChangePanSensitivity = (
    _event: Event,
    value: number | number[],
  ): void => {
    if (!canControl() || !selectedPtzDevice || !values?.PAN) return;

    ptzUsers[selectedPtzDevice].setSensitivity(
      "PAN",
      Array.isArray(value) ? value[0] : value,
    );
  };

  const handleChangeTiltSensitivity = (
    _event: Event,
    value: number | number[],
  ): void => {
    if (!canControl() || !selectedPtzDevice || !values?.TILT) return;

    ptzUsers[selectedPtzDevice].setSensitivity(
      "TILT",
      Array.isArray(value) ? value[0] : value,
    );
  };

  const getActivePtzDevicesCount = (): number => {
    return Object.keys(ptzUsers).filter((thisStreamId: string) =>
      connections.find((currConnection: MediaVideo): boolean => {
        if (!currConnection.params.devices) {
          return false;
        }

        return currConnection.streamId === thisStreamId;
      }),
    ).length;
  };

  const getCameraControlStatus = (): string => {
    if (!getActivePtzDevicesCount()) return "No PTZ camera currently detected ";
    if (!selectedPtzDevice) return "";
    if (canControl()) return "You have control";
    if (controllerId === UNUSED_USER) {
      return "Available";
    }
    if (controllerId !== user?.id && participants) {
      const participant = participants.find(
        (myParticipant: Participant): boolean =>
          myParticipant.id === controllerId,
      );
      return `Controlled by ${participant?.name || "UNKNOWN"}`;
    }
    return "";
  };

  const getControlButton = (): React.ReactNode => {
    if (!selectedPtzDevice || !getActivePtzDevicesCount()) {
      return (
        <Button
          fullWidth
          variant="contained"
          color={"secondary"}
          data-testid={"ptz-permission-btn"}
          data-cy="ptz-permission-btn"
          disabled
        >
          Control PTZ Camera
        </Button>
      );
    }

    if (
      !ptzUsers[selectedPtzDevice] ||
      !ptzUsers[selectedPtzDevice].isLockable
    ) {
      return null;
    }
    if (canControl())
      return (
        <Button
          fullWidth
          variant="outlined"
          color="error"
          onClick={handleCancelClick}
          data-testid={"ptz-permission-btn"}
          data-cy="ptz-permission-btn"
        >
          End Control
        </Button>
      );
    if (controllerId === UNUSED_USER)
      return (
        <Button
          fullWidth
          variant="contained"
          color={"secondary"}
          onClick={handleControlClick}
          data-testid={"ptz-permission-btn"}
          data-cy="ptz-permission-btn"
        >
          Control PTZ Camera
        </Button>
      );
    if (controllerId !== user?.id)
      return (
        <Button
          fullWidth
          variant="contained"
          color={"secondary"}
          onClick={handleControlClick}
          data-testid={"ptz-permission-btn"}
          data-cy="ptz-permission-btn"
        >
          Request control
        </Button>
      );

    return (
      <Button
        fullWidth
        variant="contained"
        color={"secondary"}
        onClick={handleControlClick}
        data-testid={"ptz-permission-btn"}
        data-cy="ptz-permission-btn"
      >
        Control PTZ
      </Button>
    );
  };

  const getIsDisabledPanTilt = (
    action: MovementCommands,
    bound: "min" | "max",
  ): boolean => {
    if (!canControl() || !selectedPtzDevice || !values) return false;

    const actionValue = values[action];
    if (!actionValue) {
      return false;
    }

    //flip disabled min/max controls if hanging
    if (values?.mounting?.value === liveApiSockets.PtzMountings.Inverted) {
      bound = bound === "min" ? "max" : "min";
    }
    return actionValue.value === actionValue[bound];
  };

  const getZoomValue = (zoomValues: PtzUserSingleValue | undefined): number => {
    if (!zoomValues || typeof zoomValues.value !== "number") return 0;
    const value = Math.round(
      ((zoomValues.value - zoomValues.min) /
        (zoomValues.max - zoomValues.min)) *
        100,
    );
    return isNaN(value) ? 0 : value;
  };

  return (
    <SidebarPanel title="PTZ Camera Control" closePanel={closePanel}>
      <Box py={1} px={2} height={100} maxWidth={"100%"}>
        {getActivePtzDevicesCount() > 0 && (
          <FormControl fullWidth>
            <InputLabel>Ptz Camera Name</InputLabel>
            <Select
              size="small"
              value={selectedPtzDevice ?? ""}
              label="Ptz Camera Name"
              onChange={handleChangeCamera}
              fullWidth
              data-testid="ptz-camera-selector"
            >
              {Object.keys(ptzUsers).map((myStreamId: string, index) => {
                const connection = connections.find(
                  (currConnection: MediaVideo): boolean => {
                    if (!currConnection.params.devices) {
                      return false;
                    }

                    return currConnection.streamId === myStreamId;
                  },
                );
                return (
                  connection && (
                    <MenuItem value={myStreamId} key={myStreamId}>
                      {connection.params.label} ({index + 1})
                    </MenuItem>
                  )
                );
              })}
            </Select>
          </FormControl>
        )}
        <Typography variant={"body1"} noWrap>
          {getCameraControlStatus()}
        </Typography>
      </Box>

      {videoWithMask && (
        <Box px={2} mt={1} mb={1}>
          <Alert
            severity="warning"
            sx={{ border: `1px solid ${colors.Amber200}` }}
          >
            A privacy mask has been applied to this video feed.
          </Alert>
        </Box>
      )}

      <Box px={2} height={"30px"} display="flex">
        {isShowRequested && (
          <>
            <CheckCircleIcon />
            <Typography variant={"body2"} noWrap ml={1}>
              Request sent
            </Typography>
          </>
        )}
      </Box>

      <Box pb={2} px={2}>
        {getControlButton()}
      </Box>

      <Divider variant="middle" />

      <Box sx={canControl() ? {} : { pointerEvents: "none", opacity: "0.4" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          height={"200px"}
          px={2}
          my={5}
        >
          <Box width={"170px"}>
            <Typography mb={2} variant={"body1"} textAlign={"center"} noWrap>
              Pan / Tilt
            </Typography>
            <Box
              height={"170px"}
              width={"170px"}
              display={"flex"}
              flexDirection={"column"}
              borderRadius={"50%"}
              border={`solid ${theme.palette.primary.main}`}
              overflow={"hidden"}
              sx={{ transform: "rotate(45deg)" }}
            >
              <Box flex={1} display={"flex"}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => handlePanTiltCommand("TILT", 1)}
                  data-testid={"ptz-tilt-up"}
                  disabled={getIsDisabledPanTilt("TILT", "max")}
                  sx={{ borderColor: MID_GRAY_COLOR }}
                >
                  <ArrowDropUpIcon
                    sx={{ fontSize: 50, transform: "rotate(-45deg)" }}
                  />
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => handlePanTiltCommand("PAN", 1)}
                  data-testid={"ptz-pan-right"}
                  disabled={getIsDisabledPanTilt("PAN", "max")}
                  sx={{ borderColor: MID_GRAY_COLOR }}
                >
                  <ArrowRightIcon
                    sx={{ fontSize: 50, transform: "rotate(-45deg)" }}
                  />
                </Button>
              </Box>
              <Box flex={1} display={"flex"}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => handlePanTiltCommand("PAN", -1)}
                  data-testid={"ptz-pan-left"}
                  disabled={getIsDisabledPanTilt("PAN", "min")}
                  sx={{ borderColor: MID_GRAY_COLOR }}
                >
                  <ArrowLeftIcon
                    sx={{ fontSize: 50, transform: "rotate(-45deg)" }}
                  />
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => handlePanTiltCommand("TILT", -1)}
                  data-testid={"ptz-tilt-down"}
                  disabled={getIsDisabledPanTilt("TILT", "min")}
                  sx={{ borderColor: MID_GRAY_COLOR }}
                >
                  <ArrowDropDownIcon
                    sx={{ fontSize: 50, transform: "rotate(-45deg)" }}
                  />
                </Button>
              </Box>

              <Box
                height={"40%"}
                width={"40%"}
                position={"absolute"}
                borderRadius={"50%"}
                border={`solid ${theme.palette.primary.main}`}
                overflow={"hidden"}
                sx={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Paper sx={{ height: "100%", width: "100%" }}></Paper>
              </Box>
            </Box>
          </Box>

          <Box display={"flex"} flexDirection={"column"} width={"70px"}>
            <Typography mb={2} variant={"body1"} textAlign={"center"} noWrap>
              Zoom
            </Typography>
            <Slider
              data-testid={"ptz-zoom-slider"}
              orientation="vertical"
              defaultValue={0}
              value={uncommitedZoom}
              onChange={handleChangeZoom}
              onChangeCommitted={handleZoomOnChangeCommitted}
              step={1}
              valueLabelDisplay="auto"
              marks={zoomMarks}
              min={zoomMarks[0].value}
              max={zoomMarks[1].value}
            />
          </Box>
        </Box>

        {values?.mounting && (
          <>
            <Divider variant="middle" />

            <Box mt={5} mb={3} px={2}>
              <FormControl fullWidth>
                <InputLabel>Pan / tilt movement</InputLabel>
                <Select
                  size="small"
                  value={
                    (values.mounting.value as liveApiSockets.PtzMountings) ||
                    liveApiSockets.PtzMountings.Standard
                  }
                  label="Pan / tilt movement"
                  onChange={handleChangeMounting}
                  fullWidth
                  data-testid="ptz-camera-mounting"
                >
                  <MenuItem value={liveApiSockets.PtzMountings.Inverted}>
                    {titleCase(liveApiSockets.PtzMountings.Inverted)}
                  </MenuItem>
                  <MenuItem value={liveApiSockets.PtzMountings.Standard}>
                    {titleCase(liveApiSockets.PtzMountings.Standard)}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </>
        )}

        <Accordion onChange={handleSensitivityPanel} disableGutters>
          <AccordionSummary
            sx={{
              backgroundColor: "rgba(0, 0, 0, .1)",
              maxHeight: "32px",
              minHeight: "32px",
            }}
            expandIcon={<ExpandMoreIcon />}
            data-testid="ptz-AccordionSummary"
          >
            <Typography>Sensitivity</Typography>
          </AccordionSummary>
          <AccordionDetails ref={accordElem}>
            {values?.PAN && (
              <Box px={2} mb={2}>
                <Typography variant={"body1"}>
                  <SwapHorizIcon
                    sx={{
                      verticalAlign: "middle",
                      display: "inline-flex",
                      marginRight: "10px",
                    }}
                  />
                  Pan
                </Typography>
                <Slider
                  value={values.PAN.sensitivity}
                  step={5}
                  valueLabelDisplay="auto"
                  marks={sensitivitymarks}
                  min={sensitivitymarks[0].value}
                  max={sensitivitymarks[1].value}
                  onChange={handleChangePanSensitivity}
                  data-testid="ptz-pan-sensitivity"
                />
              </Box>
            )}
            {values?.TILT && (
              <Box mt={1} px={2}>
                <Typography variant={"body1"}>
                  <SwapVertIcon
                    sx={{
                      verticalAlign: "middle",
                      display: "inline-flex",
                      marginRight: "10px",
                    }}
                  />
                  Tilt
                </Typography>
                <Slider
                  value={values.TILT.sensitivity}
                  step={5}
                  valueLabelDisplay="auto"
                  marks={sensitivitymarks}
                  min={sensitivitymarks[0].value}
                  max={sensitivitymarks[1].value}
                  onChange={handleChangeTiltSensitivity}
                  data-testid="ptz-tilt-sensitivity"
                />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </SidebarPanel>
  );
};

export default PtzPanel;
