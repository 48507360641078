import queryString from "query-string";
import SessionParams from "../models/SessionParams";
import { environment } from "../../environments/environment";

export const isValidSessionParams = (queryParams: string): boolean => {
  const parsed = queryString.parse(queryParams);
  const endpoint = parsed["endpoint"] as string;
  let streamId = parsed["stream_id"] as string;

  if (!streamId) {
    streamId = parsed["session-id"] as string;
  }

  return !!endpoint && !!streamId;
};

export const getSessionParamsFromURLQueryOrNull = (
  searchString?: string,
): SessionParams | null => {
  const searchParams = new URLSearchParams(
    searchString || window.location.search,
  );
  const endpoint = searchParams.get("endpoint") || undefined;
  let streamId = searchParams.get("stream_id") || undefined;
  const sessionId = searchParams.get("session_id") || "";

  if (!streamId) {
    streamId = searchParams.get("session-id") || undefined;
  }

  if (!!endpoint && !!streamId) {
    return {
      endpoint,
      sessionId,
      streamId,
    };
  } else {
    return null;
  }
};

export const generateURLRoot = () => {
  let dashboardDomain = `my.${environment.domain}`;

  if (environment.domain.includes("localhost")) {
    dashboardDomain = `${environment.domain}:4200`;
  }

  const protocol = environment.domain.includes("localhost") ? "http" : "https";
  return `${protocol}://${dashboardDomain}`;
};

export const navigateToDashboard = (dashboardUrl?: string) => {
  if (dashboardUrl) {
    window.location.href = dashboardUrl;
  } else {
    window.location.href = generateURLRoot();
  }
};
