import Quality, { QualityMetrics, Controls, SummaryStats } from "./Quality";

export enum SimulcastLayers {
  High = "high",
  Medium = "medium",
  Low = "low",
}

export enum QualityLevel {
  Low = 0,
  Medium = 1,
  High = 2,
}

export default abstract class QualityVideoOutgoing extends Quality {
  public abstract controls: Controls | null;

  protected generateSummaryStats(report: RTCStatsReport): SummaryStats {
    const summary: SummaryStats = {};
    let ssrc = "";
    const remoteStats: Record<string, RTCStats> = {};

    report.forEach((stat /* RTCStats*/): void => {
      summary.timestamp = stat.timestamp;
      switch (stat.type) {
        case "outbound-rtp":
          if (!stat.rid || stat.rid === "h") {
            // only consider the highest quality stream
            summary.framesTransferred = stat.framesSent;
            summary.framesProcessed = stat.framesEncoded;
            summary.totalProcessingTime = stat.totalEncodeTime;
            summary.width = stat.frameWidth;
            summary.height = stat.frameHeight;
            ssrc = stat.ssrc;
          }
          break;
        case "transport":
          summary.bytesTransferred = stat.bytesSent;
          summary.packetsTransferred = stat.packetsSent;
          break;
        case "candidate-pair":
          if (stat.state === "succeeded") {
            summary.totalRoundTripTime = stat.totalRoundTripTime;
            summary.responsesReceived = stat.responsesReceived;
            summary.availableOutgoingBitrate = stat.availableOutgoingBitrate;
          }
          break;
        case "remote-inbound-rtp":
          // store all the remote data so we can access it by SSRC later
          remoteStats[stat.ssrc] = stat;
          break;
        default:
        // ignore everything else
      }
    });

    if (remoteStats[ssrc]) {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      summary.packetsLost = (remoteStats[ssrc] as any).packetsLost;
    }

    return summary;
  }

  public static getInitialConstraints(): MediaStreamConstraints {
    throw new Error("Not implemented in abstract class");
  }

  abstract get initialConstraints(): MediaStreamConstraints;

  protected override debug(metrics: QualityMetrics): void {
    console.debug(
      { streamId: this.streamId },
      `OUTBOUND STATS ${JSON.stringify(metrics)}`,
    );
  }

  abstract isSimulcastActive(
    _level: QualityLevel,
    _layer: SimulcastLayers,
  ): boolean;
  abstract getSimulcastBitrate(
    _level: QualityLevel,
    _layer: SimulcastLayers,
  ): number;
  abstract getSimulcastScale(
    _level: QualityLevel,
    _layer: SimulcastLayers,
  ): number;
}
