export type User = {
  id: string;
  email: string;
  hasAcceptedTerms: boolean;
  name?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  userTitle?: string;
  speciality?: string;
  countryISO?: string;
  profileReference?: string;
};
