import * as t from "io-ts";
import { makeRoute } from "./dataregion-api";
import ServerParams from "../../../media/src/lib/models/ServerParams";

const FetchConnectionSettingsRequest = t.type({
  mediaSessionId: t.string,
});

export type FetchConnectionSettingsRequest = t.TypeOf<
  typeof FetchConnectionSettingsRequest
>;

export const fetchConnectionSettings = makeRoute(
  "/streams/:mediaSessionId/connection-settings",
  FetchConnectionSettingsRequest,
  ServerParams,
);
