/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ElementType } from "react";
import { Typography as MUITypography, useTheme } from "@mui/material";
import { TypographyVariants } from "../../models/typography";
import { ColorName, FontWeightName, fontWeights } from "../../index";
import { TypographyTheme } from "../../styles/typography";

export const VARIANT_MAPPINGS: Record<TypographyVariants, ElementType<any>> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "h6",
  subtitle2: "h6",
  subtitle3: "h6",
  body1: "p",
  body2: "p",
  caption: "caption",
  overline: "span",
  button: "span",
};

export interface TypographyProps {
  variant: TypographyVariants;
  weight?: FontWeightName;
  color?: ColorName;
  display?: "initial" | "block" | "inline";
  ["data-testid"]?: string;
  ["data-cy"]?: string;
  children?: React.ReactNode;
  noWrap?: boolean;
  style?: Record<string, any>;
}

export function Typography({
  variant,
  children,
  color,
  weight,
  style = {},
  ...rest
}: TypographyProps) {
  const { colors, typography } = useTheme();
  return (
    <MUITypography
      {...rest}
      style={{
        color: color ? colors[color] : "inherit",
        ...style,
      }}
      sx={{
        ...(typography as unknown as TypographyTheme)[variant],
        fontWeight: fontWeights[weight ?? "regular"],
      }}
      component={VARIANT_MAPPINGS[variant]}
    >
      {children}
    </MUITypography>
  );
}
