import { DeviceType } from "./Device";
import DeviceLocal from "./DeviceLocal";
import Endpoint from "../Endpoints/Endpoint";
import Connection, { ConnectionOptions } from "../Connections/Connection";
import ConnectionJanusVideoOutgoing from "../Connections/Janus/ConnectionJanusVideoOutgoing";
import QualityVideoOutgoingCamera from "../Quality/QualityVideoOutgoingCamera";
import FeedType from "../../models/FeedType";
import CallStats from "../CallStats";

export default class DeviceLocalCamera extends DeviceLocal {
  public override deviceType = DeviceType.Camera;
  public override readonly mediaType = FeedType.Camera;

  public override invoke(
    endpoint: Endpoint,
    streamId: string,
    options: ConnectionOptions,
  ): Connection | null {
    const quality = new QualityVideoOutgoingCamera({
      bitrate: endpoint.config.bitrate,
    });

    const connection = new ConnectionJanusVideoOutgoing(
      endpoint,
      this,
      streamId,
      {
        ...this.options,
        ...options,
        params: {
          ...options.params,
        },
        quality,
      },
    );

    connection.on("connecting", (): void => {
      CallStats.outgoingCamera(connection.pc, streamId);
    });

    return connection;
  }
}
