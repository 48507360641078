import { ENV_NAMES } from "../../../../../infra-naming-constants";

export enum AppName {
  DASHBOARD = "dashboard",
  LIVE = "live",
}

export enum AppEnvironment {
  LOCALHOST = "localhost",
  DEVELOPMENT = "development",
  STAGING = "staging",
  PRODUCTION = "production",
}

export enum LogLevel {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

export interface AttributeData {
  level: LogLevel;
  organisationId?: number;
  profileId?: number;
  message: string;
  stackTrace?: string;
  error?: Record<string, unknown>;
  arguments?: string[];
  attributes?: Record<string, unknown>;
}

export type LogProps = Omit<AttributeData, "level">;

export interface MetaData {
  osName?: string;
  osVersion?: string;
  browserName?: string;
  browserVersion?: string;
  app: AppName;
  version: string;
  env: AppEnvironment;
  [key: string]: unknown;
}

export type LogData = MetaData & AttributeData;

export interface FormatedLogData {
  meta: MetaData;
  attributes: LogProps;
}

export interface NewRelicConfig {
  hostURL: string;
  auth: string;
}

export interface Logger {
  info: (payload: LogProps) => void | Promise<void>;
  warn: (payload: LogProps) => void | Promise<void>;
  error: (payload: LogProps) => void | Promise<void>;
  setConfig?: (payload: Partial<NewRelicConfig>) => void;
}

export const getEnvironment = (environmentName: string): AppEnvironment => {
  switch (environmentName) {
    case ENV_NAMES.PRODUCTION:
      return AppEnvironment.PRODUCTION;

    case ENV_NAMES.STAGING:
      return AppEnvironment.STAGING;

    case ENV_NAMES.DEVELOPMENT:
      return AppEnvironment.DEVELOPMENT;

    default:
      return AppEnvironment.LOCALHOST;
  }
};
