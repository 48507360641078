import CallStatsInfo from "../models/CallStatsInfo";
import FeedType from "../models/FeedType";
import { decodeStreamId } from "../MediaUtil";
//eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CallstatsJs from "callstats-js/callstats.min";

export type CallStatsParams = {
  endpoint: string;
  organisationUuid: string;
  appointmentUuid: string;
  isSessionOwner: boolean;
  sessionOwnerEmail: string;
};

export default abstract class CallStats {
  static sessionId = "";
  static info: CallStatsInfo | null = null;
  static callstatsJs: CallstatsJs | null = null;
  static params?: CallStatsParams;
  static directionLookup: { [key: number]: string } = {};

  static async initialise(
    sessionId: string,
    params?: CallStatsParams,
    _info?: CallStatsInfo,
  ): Promise<void> {
    console.log("CallStats:initialise: info=", _info);

    if (!_info) {
      console.warn("CallStats:initialise: No info specified");
      return Promise.resolve();
    }

    if (this.callstatsJs) {
      return Promise.reject(new Error("Already in use"));
    }

    this.callstatsJs = new CallstatsJs();

    this.sessionId = sessionId;
    this.params = params;

    this.directionLookup = {
      [this.callstatsJs?.transmissionDirection.sendonly]: "sendonly",
      [this.callstatsJs?.transmissionDirection.receiveonly]: "recvonly",
      [this.callstatsJs?.transmissionDirection.sendrecv]: "sendrecv",
      [this.callstatsJs?.transmissionDirection.inactive]: "inactive",
    };

    //TODO - how to add sessionParams.isSessionOwner....
    this.info = _info;

    if (this.info.proxies && Object.keys(this.info.proxies).length > 0) {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).csioproxy = true;

      this.callstatsJs.setProxyConfig(this.info.proxies);
    }

    // settle the returned promise when either:
    // a) callstatsJs has completed (successfully or unsuccessfully), or
    // b) after 500ms
    // whichever is sooner
    try {
      await Promise.race([
        new Promise<void>((_resolve, reject): void => {
          setTimeout((): void => {
            reject(new Error("Callstats timed-out"));
          }, 500);
        }),
        CallStats.start(),
      ]);
    } catch (error) {
      this.destroy();
      throw error;
    }
  }

  private static start(): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      const options = {
        disablePrecalltest: true,
        additionalIDs: {
          pbxID: this.params?.endpoint || "N/A",
          tenantID: this.params?.organisationUuid || "N/A",
          sessionID: this.params?.appointmentUuid || "N/A",
          customerID:
            this.params && this.params.isSessionOwner ? "owner" : "participant",
        },
      };

      const result = this.callstatsJs.initialize(
        this.info?.appId,
        (
          _forceNew: boolean,
          cb: (err: Error | null, token: string) => void,
        ): void => {
          cb(
            null,
            typeof this.info?.token === "string" ? this.info?.token : "",
          );
        },
        this.info?.userId,
        (csError: string): void => {
          if (csError === "success") {
            resolve();
          } else {
            console.warn(
              "CallStats:initialise: Failed to initialise - csError=",
              csError,
            );
            reject(new Error(csError));
          }
        },
        null,
        options,
      );

      if (!result || !result.status || result.status !== "success") {
        reject(new Error(result.message));
      }
    });
  }

  private static addPeerConnection(
    pc: RTCPeerConnection,
    streamId: string,
    media?: number,
    direction?: number,
  ): void {
    if (!this.info) {
      // callstats was not configured
      return;
    }

    console.log({ streamId }, "CallStats:addPeerConnection: pc=", pc);

    if (!this.callstatsJs) {
      console.warn({ streamId }, "CallStats:addPeerConnection: not active");
      return;
    }

    const { type: mediaType, userId, index } = decodeStreamId(streamId);
    const description = `mediaType:${
      FeedType[mediaType]
    } userId:${userId} index:${index} direction:${
      (direction && this.directionLookup[direction]) || "N/A"
    }`;

    const attributes = {
      remoteEndpointType: this.callstatsJs.endpointType.peer,
      fabricTransmissionDirection: direction,
    };
    const result = this.callstatsJs.addNewFabric(
      pc,
      // we overload the remoteUserId with a meaningful string
      // describing the connection
      description,
      media,
      this.sessionId,
      attributes,
      (csError: string): void => {
        if (csError !== "success") {
          console.warn(
            { streamId },
            "CallStats:addPeerConnection: Couldn't add new PC - csError=",
            csError,
          );
        }
      },
    );

    if (!result || !result.status || result.status !== "success") {
      console.warn(
        { streamId },
        "CallStats:addPeerConnection: Failed to add peer connection - result=",
        result,
      );
    }
  }

  static audio(pc: RTCPeerConnection, streamId: string): void {
    console.log({ streamId }, "CallStats:audio: pc=", pc);
    CallStats.addPeerConnection(
      pc,
      streamId,
      this.callstatsJs?.fabricUsage.audio,
      this.callstatsJs?.transmissionDirection.sendrecv,
    );
  }

  static incomingCamera(pc: RTCPeerConnection, streamId: string): void {
    console.log({ streamId }, "CallStats:incomingCamera: pc=", pc);
    CallStats.addPeerConnection(
      pc,
      streamId,
      this.callstatsJs?.fabricUsage.video,
      this.callstatsJs?.transmissionDirection.receiveonly,
    );
  }

  static outgoingCamera(pc: RTCPeerConnection, streamId: string): void {
    console.log({ streamId }, "CallStats:outgoingCamera: pc=", pc);
    CallStats.addPeerConnection(
      pc,
      streamId,
      this.callstatsJs?.fabricUsage.video,
      this.callstatsJs?.transmissionDirection.sendonly,
    );
  }

  static incomingScreen(pc: RTCPeerConnection, streamId: string): void {
    console.log({ streamId }, "CallStats:incomingScreen: pc=", pc);
    CallStats.addPeerConnection(
      pc,
      streamId,
      this.callstatsJs?.fabricUsage.screen,
      this.callstatsJs?.transmissionDirection.receiveonly,
    );
  }

  static outgoingScreen(pc: RTCPeerConnection, streamId: string): void {
    console.log({ streamId }, "CallStats:outgoingScreen: pc=", pc);
    CallStats.addPeerConnection(
      pc,
      streamId,
      this.callstatsJs?.fabricUsage.screen,
      this.callstatsJs?.transmissionDirection.sendonly,
    );
    CallStats.sendEvent(pc, this.callstatsJs?.fabricEvent.screenShareStart);
  }

  // LATER: currently a connection is always shown as timed-out on completion.
  // This is because we do not get notification of the closure of the
  // PeerConnection until it is too late (and the PeerConnection has been
  // deleted).  When we re-factor ServiceAdapter we should try to remedy this
  // by sending a terminated event to callstats.io
  private static sendEvent(
    pc: RTCPeerConnection,
    event?: number,
    // eslint-disable-next-line @typescript-eslint/ban-types
    obj?: object,
  ): void {
    if (!this.info) {
      // callstats was not configured
      return;
    }

    console.log("CallStats:sendEvent: pc=", pc, "event=", event);

    if (!this.callstatsJs) {
      console.warn("CallStats:sendEvent: Not active");
      return;
    }

    const result = this.callstatsJs.sendFabricEvent(
      pc,
      event,
      this.sessionId,
      obj,
    );

    if (!result || !result.status || result.status !== "success") {
      console.warn("CallStats:sendEvent: failed - result=", result);
    }
  }

  static setMute(pc: RTCPeerConnection, isMuted: boolean): void {
    console.log("CallStats:setMute: pc=", pc);
    CallStats.sendEvent(
      pc,
      isMuted
        ? this.callstatsJs?.fabricEvent.audioMute
        : this.callstatsJs?.fabricEvent.audioUnmute,
    );
  }

  static end(pc: RTCPeerConnection): void {
    console.log("CallStats:end: pc=", pc);
    CallStats.sendEvent(pc, this.callstatsJs?.fabricEvent.fabricTerminated);
  }

  // this function is probably only relevant for testing purposes
  static destroy() {
    this.callstatsJs = null;
  }
}
