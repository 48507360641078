import AudioParticipant, { AudioParticipants } from "./models/AudioParticipant";
import Participants from "./models/Participants";
import FeedType from "./models/FeedType";

const STREAM_ID_SEPARATOR = "-";
const STREAM_ID_PATTERN = new RegExp(
  `^(?<type>\\d+)${STREAM_ID_SEPARATOR}(?<userId>.*)${STREAM_ID_SEPARATOR}(?<index>\\d+)${STREAM_ID_SEPARATOR}(?<timestamp>\\d+)$`,
);

export type StreamObj = {
  type: FeedType;
  userId: string;
  index?: number;
  timestamp?: number;
};

export function encodeStreamId(streamObj: StreamObj): string {
  return [
    streamObj.type,
    streamObj.userId,
    streamObj.index ?? 0,
    streamObj.timestamp ?? Date.now(),
  ].join(STREAM_ID_SEPARATOR);
}

export function decodeStreamId(streamId: string): Required<StreamObj> {
  const parts = STREAM_ID_PATTERN.exec(streamId);
  if (!parts) {
    throw new Error(`Invalid streamId format=${streamId}`);
  }

  return {
    type: Number(parts[1]),
    userId: parts[2],
    index: Number(parts[3]),
    timestamp: Number(parts[4]),
  };
}

export function validateStreamId(streamId: string): boolean {
  return STREAM_ID_PATTERN.test(streamId);
}

export function compareStreamId(streamIdA: string, streamIdB: string): number {
  const {
    index: indexA,
    userId: userIdA,
    timestamp: timestampA,
  } = decodeStreamId(streamIdA);
  const {
    index: indexB,
    userId: userIdB,
    timestamp: timestampB,
  } = decodeStreamId(streamIdB);

  return (
    indexA - indexB || userIdA.localeCompare(userIdB) || timestampA - timestampB
  );
}

export const ConsolidateIntoParticipants = (
  local: AudioParticipant,
  participants: AudioParticipants,
): Participants => {
  const participantsArray: AudioParticipant[] = Object.values(participants);

  // If the local user was found in media server participants, update
  const localUserFromMediaServer = participantsArray.find(
    (participant) => participant.isLocal,
  );

  if (localUserFromMediaServer) {
    local.metadata = localUserFromMediaServer.metadata;
    local.streamId = localUserFromMediaServer.streamId;
    local.isConnected = localUserFromMediaServer.isConnected;
    local.isMuted = localUserFromMediaServer.isMuted;
    local.isActive = localUserFromMediaServer.isActive;
  }

  const remote: AudioParticipant[] = participantsArray.filter(
    (participant: AudioParticipant): boolean =>
      participant.streamId !== local.streamId,
  );

  return { local, remote };
};

export default {
  ConsolidateIntoParticipants,
  encodeStreamId,
  decodeStreamId,
  validateStreamId,
  compareStreamId,
};
