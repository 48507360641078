/* eslint-disable sonarjs/cognitive-complexity */
import React, { FC, useEffect, useRef, useState } from "react";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { EventTypes, PtzUser } from "../../utils/PtzUser";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PTZTooltip, PTZTooltipVariants } from "./PTZTooltip";
import { Participant } from "../../hooks/useParticipants/useParticipants";

export interface PtzPermissionLabelProps {
  ptzUser: PtzUser | null;
  videoWithMask: boolean;
  streamId: string;
  checkIfVideoWithMasks: (streamId: string) => void;
  anchorEl?: HTMLElement | null;
  boundaryElement?: HTMLElement | null;
  variant?: PTZTooltipVariants;
}

export enum messages {
  //i am owner
  REQUESTED_FROM_ME = "[_REQUESTER] requests interactive device control", //
  OWNED = "You control this interactive device", //
  DENIED = "Request denied. You are still in control",
  RECEIVED_BY_ME = "Request successful. You now control this interactive device", //

  //i am NOT owner
  ENDED = "Interactive device control ended", //
  GIVEN = "Interactive device control given to [_CONTROLLER]", //
  BUSY = "Interactive device still in use by [_CONTROLLER]. Try again later",
}

const PERMISSION_NOTIFICATION_TIME = 4000;
const PERMISSION_REQUEST_PROMPT_TIME = 20000; //ms

const PtzPermissionLabel: FC<PtzPermissionLabelProps> = (
  props: PtzPermissionLabelProps,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): JSX.Element => {
  const { user, participants } = useSessionContext();
  const { colors } = useTheme();

  const UNUSED_USER = null;

  const [requesterId, setRequesterId] = useState<string | null>(UNUSED_USER);
  const [controllerId, setControllerId] = useState<string | null>(UNUSED_USER);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [status, setStatus] = useState<messages>(messages.ENDED);
  const [lastStatus, setLastStatus] = useState<messages>(messages.ENDED);

  const [countdownEndTime, setCountdownEndTime] = useState<number>(0);
  const [timeRemaining, setTimeRemaining] = useState<number>(
    PERMISSION_REQUEST_PROMPT_TIME,
  );

  const [infosOpen, setInfosOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (status === messages.REQUESTED_FROM_ME) {
      interval = setInterval(() => {
        const rem = Math.round((countdownEndTime - Date.now()) / 1000) * 1000;
        if (rem < 1000) {
          props.ptzUser?.rescindControl();
          clearInterval(interval);
          return;
        }
        setTimeRemaining(rem);
      }, 1000);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, countdownEndTime]);

  const statusRef = useRef<messages>();
  statusRef.current = status;
  const requesterRef = useRef<string | null>();
  requesterRef.current = requesterId;

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const updateStatus = (newStatus: messages) => {
      if (newStatus === messages.REQUESTED_FROM_ME) {
        setCountdownEndTime(Date.now() + PERMISSION_REQUEST_PROMPT_TIME);
        setTimeRemaining(PERMISSION_REQUEST_PROMPT_TIME);
      }

      setLastStatus(statusRef.current as messages);
      setStatus(newStatus);
      setIsOpen(true);

      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(
        () => {
          setIsOpen(false);
        },
        newStatus === messages.REQUESTED_FROM_ME
          ? PERMISSION_REQUEST_PROMPT_TIME
          : PERMISSION_NOTIFICATION_TIME,
      );
    };
    props.ptzUser &&
      props.ptzUser.on(EventTypes.ControlAlreadyRequested, () => {
        updateStatus(messages.BUSY);
      });
    props.ptzUser &&
      props.ptzUser.on(
        EventTypes.ControlNotification,
        (thisControllerId: string, thisRequesterId: string): void => {
          //if i have control
          if (thisControllerId === user?.id) {
            //if i requested, i received
            if (requesterRef.current === user?.id) {
              updateStatus(messages.RECEIVED_BY_ME);
              props.checkIfVideoWithMasks(props.streamId);
              setInfosOpen(true);
            }
            //if someone is requesting now, prompt
            else if (thisRequesterId) {
              updateStatus(messages.REQUESTED_FROM_ME);
            }
            //if someone requested before, i denied
            else if (
              requesterRef.current !== UNUSED_USER &&
              requesterRef.current !== user?.id
            ) {
              updateStatus(messages.DENIED);
            }
            //i just took free camera
            else {
              updateStatus(messages.OWNED);
              props.checkIfVideoWithMasks(props.streamId);
              setInfosOpen(true);
            }
          }

          //if i dont have control
          else {
            //if i requested control, then im denied
            if (requesterRef.current === user?.id) {
              updateStatus(messages.BUSY);
            }
            //if someone had requested from me, then i gave it
            else if (
              statusRef.current === messages.REQUESTED_FROM_ME &&
              thisControllerId
            ) {
              updateStatus(messages.GIVEN);
            }
            //if i was owner, i ended
            else if (
              statusRef.current === messages.OWNED ||
              statusRef.current === messages.DENIED ||
              statusRef.current === messages.RECEIVED_BY_ME
            ) {
              updateStatus(messages.ENDED);
            }
          }

          setRequesterId(thisRequesterId);
          setControllerId(thisControllerId);
        },
      );
    props.ptzUser && props.ptzUser.requestStatusAndControl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ptzUser, setCountdownEndTime, setTimeRemaining]);

  const getSnackbarMessage = () => {
    const requester = (participants ?? []).find(
      (sessionParticipant: Participant): boolean =>
        sessionParticipant.id === requesterId,
    );
    const requesterName = requester?.name ?? "UNKNOWN";

    const controller = (participants ?? []).find(
      (sessionParticipant: Participant): boolean =>
        sessionParticipant.id === controllerId,
    );
    const controllerName = controller?.name ?? "UNKNOWN";

    return status
      .replace("[_REQUESTER]", requesterName)
      .replace("[_CONTROLLER]", controllerName);
  };

  return (
    <>
      <PTZTooltip
        text={getSnackbarMessage()}
        variant={props.variant ?? "up"}
        autoHandoverDuration={timeRemaining / 1000}
        showAutoHandoverDuration={status === messages.REQUESTED_FROM_ME}
        open={isOpen && status !== lastStatus}
        anchorEl={props.anchorEl}
        boundaryElement={props.boundaryElement}
        actions={
          status === messages.REQUESTED_FROM_ME
            ? [
                {
                  text: "Allow",
                  action: () => props.ptzUser?.rescindControl(),
                  dataTestId: "ptz-permission-btn-allow",
                },
                {
                  text: "Deny",
                  action: () => props.ptzUser?.denyControl(),
                  dataTestId: "ptz-permission-btn-deny",
                },
              ]
            : []
        }
      />

      {props.videoWithMask && (
        <Dialog
          open={infosOpen}
          onClose={() => {
            setInfosOpen(false);
          }}
        >
          <DialogTitle sx={{ m: 1, p: 2 }}>
            Privacy warning
            <IconButton
              aria-label="close"
              onClick={() => {
                setInfosOpen(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            Request successful. You now control this interactive device.
            <Alert
              severity="warning"
              data-testid="pii-ptz-warning"
              sx={{ mt: 3, border: `1px solid ${colors.Amber200}` }}
            >
              This PTZ camera feed has a privacy mask applied. Moving the PTZ
              camera could expose sensitive information.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color={"secondary"}
              onClick={() => {
                setInfosOpen(false);
              }}
              data-testid={"close-more-infos"}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PtzPermissionLabel;
