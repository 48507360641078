import * as t from "io-ts";

const CallStatsInfo = t.type({
  appId: t.string,
  proxies: t.dictionary(t.string, t.string),
  token: t.string,
  userId: t.string,
});

type CallStatsInfo = t.TypeOf<typeof CallStatsInfo>;
export default CallStatsInfo;
