import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { environment } from "../../../environments/environment";

export type Participant = {
  id: string;
  name: string;
  email: string;
  invitationStatus?: string;
  role?: string;
};

type Participants = {
  participants: Participant[];
};

const getParticipants: (sessionId: string) => () => Promise<Participants> =
  (sessionId: string) => () =>
    axios.get(
      `${environment.apiUrl}/api/v2/sessions/${sessionId}/participants`,
    );

export const useParticipants = (
  sessionId: string | undefined,
  opts?: SWRConfiguration,
) => {
  const { data, error, mutate } = useSWR<Participants>(
    sessionId ? `session-${sessionId}-participant` : null,
    getParticipants(sessionId || ""),
    opts,
  );

  const removeParticipant = async (participantId: string): Promise<void> => {
    await axios.delete(
      `${environment.apiUrl}/api/v2/sessions/${sessionId}/participants/${participantId}/delete-url/${sessionId}`,
    );
    mutate();
  };

  const inviteParticipant = async (participantId: string): Promise<void> => {
    await axios.patch(
      `${environment.apiUrl}/api/v2/sessions/${sessionId}/participants`,
      { participants: [{ id: participantId }] },
    );
    mutate();
  };

  return {
    participants: data?.participants,
    areParticipantsLoading: !error && !data,
    participantsError: error,
    mutateParticipants: mutate,
    removeParticipant,
    inviteParticipant,
  };
};
