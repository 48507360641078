/*
This class implements a fixed size FIFO queue.  It is used by the
quality components to calculate rolling averages for the statistics.

When the queue is created it is given a maximum length.  When items are
added up to (and including) the maximum length they are added to the
head of the queue.  When the queue length is at maximum length and a new
item is pushed then the oldest element is removed from the tail of the
list and the new item is added to the head.  Thus, the queue will never
exceed the maximum size.

NB. The only ways to remove elements are to push a new one, or to
clear the queue completely.
*/
export default class FixedFifo<T> {
  private arr: Array<T> = [];
  constructor(private maxLength: number) {}

  push(elem: T): T | null {
    let removedElem;

    if (this.arr.length >= this.maxLength) {
      removedElem = this.arr.shift();
    }
    this.arr.push(elem);

    return removedElem || null;
  }

  length(): number {
    return this.arr.length;
  }

  first(): T {
    return this.arr[0];
  }

  last(): T {
    return this.arr[this.arr.length - 1];
  }

  at(index: number): T {
    return this.arr[index];
  }

  get(): Array<T> {
    return this.arr;
  }

  clear(): void {
    this.arr = [];
  }

  full(): boolean {
    return this.arr.length === this.maxLength;
  }
}
