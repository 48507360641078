import React, { useEffect, useState } from "react";
import { PARTICIPANT_STATUS } from "../constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import Participant, { ParticipantProps } from "../Participant/Participant";
import SidebarPanel from "../SidebarPanel/SidebarPanel";
import { Participant as SessionParticipant } from "../../hooks/useParticipants";
import { useMembers, Member } from "../../hooks/useMembers";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { AudioParticipant } from "@proximie/media";
import { Permission } from "../../utils/checkPermission";

interface ParticipantsListProps {
  audioParticipants: AudioParticipant[];
  muteAll: () => Promise<void>;
  setMuteState: (streamId: string, isMuted: boolean) => Promise<void>;
  closePanel: () => void;
}

const ParticipantsList = ({
  audioParticipants,
  muteAll,
  setMuteState,
  closePanel,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
ParticipantsListProps): JSX.Element => {
  const {
    organisation,
    user,
    participants,
    inviteParticipant,
    ShowForPermission,
  } = useSessionContext();

  const [searchText, setSearchText] = useState("");
  const [inThisSessionList, setInThisSessionList] = useState<
    ParticipantProps[]
  >([]);
  const [otherInvitedList, setOtherInvitedList] = useState<ParticipantProps[]>(
    [],
  );
  const [notInvitedList, setNotInvitedList] = useState<ParticipantProps[]>([]);

  const { members } = useMembers(organisation?.id || "", {
    suspense: false,
    refreshInterval: 5000,
  });

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const clearSearch = () => {
    setSearchText("");
  };

  const getName = <T extends { name: string | null; email?: string | null }>(
    participant: T,
  ) => {
    return (
      (participant.name?.trim() || null) ??
      (participant.email ? maskEmail(participant.email) : null) ??
      "N/A"
    );
  };

  const maskEmail = (email: string | null) => {
    if (!email) return email;
    return `${email.substring(0, 3)}***@***...`;
  };

  useEffect(() => {
    const filterBySearchText = (
      list: ParticipantProps[],
    ): ParticipantProps[] => {
      return list.filter((user) => {
        if (user.name) {
          return user.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        } else {
          return false;
        }
      });
    };

    setNotInvitedList(
      filterBySearchText(
        (members || [])
          .filter(
            (member: Member): boolean =>
              !(participants || []).some(
                (participant: SessionParticipant) =>
                  participant.id === member.id,
              ),
          )
          .map(
            (member: Member): ParticipantProps => ({
              id: member.id,
              key: "notInvited" + member.id,
              name: getName(member),
              userStatus: PARTICIPANT_STATUS.NotInvited,
              inviteUser: async () => {
                await inviteParticipant?.(member.id || "");
              },
              isMe: user ? user.id === member.id : false,
            }),
          ),
      ),
    );

    setOtherInvitedList(
      filterBySearchText(
        (participants || [])
          .filter(
            (participant: SessionParticipant): boolean =>
              !audioParticipants.some(
                (subUser) => subUser.userId === participant.id,
              ),
          )
          .map(
            (participant: SessionParticipant): ParticipantProps => ({
              id: participant.id,
              key: "others" + participant.id,
              name: getName(participant),
              role: participant.role,
              userStatus: PARTICIPANT_STATUS.Invited,
              isMe: false,
            }),
          ),
      ),
    );

    setInThisSessionList(
      filterBySearchText(
        audioParticipants.reduce(
          (
            accumulator: ParticipantProps[],
            audioParticipant: AudioParticipant,
          ): ParticipantProps[] => {
            const participant = participants?.find(
              (myParticipant) => myParticipant.id === audioParticipant.userId,
            );
            if (participant) {
              accumulator.push({
                id: participant.id,
                key: "joined" + audioParticipant.streamId,
                name: getName(participant),
                role: participant.role,
                userStatus: PARTICIPANT_STATUS.Online,
                isMuted: audioParticipant.isMuted,
                isLocal: audioParticipant.isLocal,
                isActive: audioParticipant.isActive,
                isMe: user ? user.id === participant.id : false,
                metadata: audioParticipant.metadata,
                setMuteState: (mute: boolean) => {
                  setMuteState(audioParticipant.streamId, mute);
                },
              });
            }
            return accumulator;
          },
          [],
        ),
      ),
    );
  }, [
    audioParticipants,
    searchText,
    members,
    participants,
    user,
    setMuteState,
  ]);

  const handleMuteAllClick = () => {
    muteAll();
  };
  return (
    <SidebarPanel title="Participants" closePanel={closePanel}>
      <ShowForPermission permission={Permission.MUTE_ALL}>
        <Box pt={1} pb={2} px={2}>
          <Button
            fullWidth
            variant="contained"
            color={"secondary"}
            onClick={handleMuteAllClick}
            data-testid={"mute-all-btn"}
          >
            Mute All
          </Button>
        </Box>
      </ShowForPermission>

      <Box py={1} px={2}>
        <TextField
          size="small"
          label="Search participants"
          fullWidth
          value={searchText}
          onChange={handleTextFieldChange}
          data-testid={"participants-search-box"}
          InputProps={{
            endAdornment:
              searchText.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
        />
      </Box>

      <Box flex={1} style={{ overflowY: "scroll" }}>
        <Accordion defaultExpanded disableGutters>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              In this session ({inThisSessionList.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {inThisSessionList.map((participant: ParticipantProps) => (
              // eslint-disable-next-line react/jsx-key
              <Participant {...participant} />
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Others invited ({otherInvitedList.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {otherInvitedList.map((participant: ParticipantProps) => (
              // eslint-disable-next-line react/jsx-key
              <Participant {...participant} />
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Not invited ({notInvitedList.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {notInvitedList.map((participant: ParticipantProps) => (
              // eslint-disable-next-line react/jsx-key
              <Participant {...participant} />
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </SidebarPanel>
  );
};
export default ParticipantsList;
