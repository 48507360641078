import React, { FC, useEffect } from "react";
import { RouteProps } from "react-router-dom";
import { ErrorBoundary } from "@proximie/components";

export interface PageProps extends RouteProps {
  title: string;
  component: FC<RouteProps>;
}

export const Page = ({ title, component: Component }: PageProps) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <ErrorBoundary>
      <Component />
    </ErrorBoundary>
  );
};
