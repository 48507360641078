import Device, { DeviceType, DeviceOptions } from "./Device";
import Endpoint from "../Endpoints/Endpoint";
import Connection, { ConnectionOptions } from "../Connections/Connection";
import ConnectionJanusVideoOutgoing from "../Connections/Janus/ConnectionJanusVideoOutgoing";
import QualityVideoOutgoingScreenshare from "../Quality/QualityVideoOutgoingScreenshare";
import FeedType from "../../models/FeedType";
import CallStats from "../CallStats";

export interface DeviceScreenOptions extends DeviceOptions {
  stream: MediaStream;
}

export default class DeviceScreen extends Device {
  public override readonly deviceType = DeviceType.Screen;
  public override readonly mediaType = FeedType.Screen;

  constructor(deviceId: string, public options: DeviceScreenOptions) {
    super(deviceId, options);
  }

  public override invoke(
    endpoint: Endpoint,
    streamId: string,
    options: ConnectionOptions,
  ): Connection | null {
    const quality = new QualityVideoOutgoingScreenshare({
      bitrate: endpoint.config.bitrate,
    });

    const connection = new ConnectionJanusVideoOutgoing(
      endpoint,
      this,
      streamId,
      {
        ...options,
        quality,
      },
    );

    connection.on("connecting", (): void => {
      CallStats.outgoingScreen(connection.pc, streamId);
    });

    return connection;
  }
}
