import * as t from "io-ts";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";
import ServerURL from "./ServerURL";
import CallStatsInfo from "./CallStatsInfo";
import WatchRTCConfig from "./WatchRTCConfig";
import DcpBrokerConfig from "./DcpBrokerConfig";

// This replicates the RTCIceServer TypeScript type
const IceServer = t.type({
  username: t.union([t.string, t.undefined]),
  credential: t.union([t.string, t.undefined]),
  urls: t.string,
});

const MandatoryServerParams = t.type({
  audioServer: ServerURL,
  videoServer: ServerURL,
  username: t.string,
  credential: t.string,
});

const OptionalServerParams = t.partial({
  iceServers: t.array(IceServer),
  // Needs decoding on the media-client side
  startedAt: DateFromISOString,
  callstats: CallStatsInfo,
  newRelicLogUrl: t.string,
  watchRTC: WatchRTCConfig,
  dcpHomeBroker: DcpBrokerConfig,
  dcpSessionBroker: DcpBrokerConfig,
});

const ServerParams = t.intersection([
  MandatoryServerParams,
  OptionalServerParams,
]);

type ServerParams = t.TypeOf<typeof ServerParams>;

export default ServerParams;
