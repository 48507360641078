import React, { FormEvent, useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  TextField,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { ScrollBottomStyled, ChatListStyled } from "./Chat.style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SidebarPanel from "../SidebarPanel/SidebarPanel";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { liveApiSockets } from "@proximie/dataregion-api";

export interface ChatMessage {
  type: string;
  message: string;
  isAuthor: boolean;
  author: string;
  date: Date;
  sequenceNumber?: number;
}

export interface ChatProps {
  messages: ChatMessage[];
  className?: string;
  closePanel: () => void;
}

export const Chat: React.FC<ChatProps> = ({
  messages,
  closePanel,
}: ChatProps) => {
  const { socket } = useSessionContext();

  const messagesListRef = useRef<HTMLUListElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [autoScrollToBottom, setAutoScrollToBottom] = useState(true);
  const [hasScroll, setHasScroll] = useState(false);
  const handleSend = (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    const messageValue = inputValue;
    setInputValue("");
    if (messageValue && socket) {
      socket.sendSync(liveApiSockets.MediaSessionEventType.chat, {
        message: messageValue,
      });
    }
  };

  const handleScroll = (event: Event) => {
    const element = event.target as HTMLElement;
    //there were some pixel differences and === was not running. Added +10 to be safe
    if (element) {
      const isAtBottom =
        element.scrollHeight - element.offsetHeight <= element.scrollTop + 10;
      setAutoScrollToBottom(isAtBottom);
    }
  };

  const scrollToBottom = () => {
    setAutoScrollToBottom(true);
  };

  useEffect(() => {
    const messageListElement = messagesListRef?.current;
    if (messageListElement) {
      messageListElement.addEventListener("scroll", handleScroll);
      setHasScroll(true);
    }
    return () => {
      if (messageListElement) {
        messageListElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const messageListElement = messagesListRef?.current;
    if (messageListElement && autoScrollToBottom) {
      messageListElement.scrollTop = messageListElement.scrollHeight;
    }
  }, [autoScrollToBottom, messages]);

  return (
    <SidebarPanel
      data-testid="chat-window"
      title="Chat"
      closePanel={closePanel}
    >
      <Box px={2}>
        <Divider />
      </Box>
      <ChatListStyled ref={messagesListRef}>
        {messages.map((message) => {
          return (
            <ListItem key={message.sequenceNumber}>
              <Grid
                container
                justifyContent={message.isAuthor ? "flex-end" : "flex-start"}
              >
                <Grid item xs={10}>
                  <Box pt={1} textAlign={"right"} fontSize="11px">
                    {message.author}
                    {" - "}
                    {new Date(message.date).toLocaleTimeString()}
                  </Box>
                  <Box
                    component={Paper}
                    p={2}
                    bgcolor={message.isAuthor ? "" : "primary.main"}
                    color={message.isAuthor ? "" : "primary.contrastText"}
                    className="text-container"
                    data-cy="chat-bubble"
                  >
                    {message.message}
                  </Box>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </ChatListStyled>

      <Box pt={2} pb={2} px={2}>
        <Divider />
      </Box>

      <Box
        display={"flex"}
        component={"form"}
        onSubmit={handleSend}
        pl={2}
        flexGrow={0}
        flexShrink={0}
      >
        <TextField
          autoFocus={true}
          variant={"standard"}
          color={"primary"}
          multiline
          maxRows={4}
          value={inputValue}
          fullWidth
          placeholder={"Message participants..."}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSend(e);
            }
          }}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          data-cy="chat-input"
        />
        <IconButton
          title={"Send message"}
          color={"primary"}
          disabled={!inputValue}
          type={"submit"}
          data-testid={"submit-btn"}
          size="large"
        >
          <Send />
        </IconButton>
      </Box>

      {hasScroll && !autoScrollToBottom && (
        <ScrollBottomStyled
          color="primary"
          aria-label="add"
          size="medium"
          variant="contained"
          onClick={scrollToBottom}
        >
          <ExpandMoreIcon />
        </ScrollBottomStyled>
      )}
    </SidebarPanel>
  );
};
