import { liveApiSockets } from "@proximie/dataregion-api";
import { CompositeDevice, IComponentPublishInfo } from "@proximie/dcp-mqtt";
import {
  constrain,
  ICapabilityDefinitionRange,
  IComponentRequestPayload,
} from "@proximie/dcp";
import { DeviceMetadata } from "@proximie/media";
import SocketIoClientWrapper from "../wrappers/SocketIOClientWrapper/SocketIOClientWrapper";

export enum EventTypes {
  ControlNotification = "control_notification",
  ControlAssigned = "control_assigned",
  ControlCancelled = "control_cancelled",
  ControlRequested = "control_requested",
  ControlRescinded = "control_rescinded",
  ControlDenied = "control_denied",
  StatusNotification = "status_notification",
  ControlAlreadyRequested = "control_already_requested",
}

export const DefaultSensitvity: Record<string, number> = {
  PAN: 50,
  TILT: 50,
};
const UNUSED_USER = null;

type ValueType = number | liveApiSockets.PtzMountings;

export type PtzUserSingleValue = {
  min: number;
  max: number;
  value: ValueType;
  sensitivity: number;
  component: string;
  service: string;
};

export type PtzUserValues = Record<string, PtzUserSingleValue>;

export class PtzUser extends CompositeDevice {
  private deviceId = "";
  public controllerId: string | null = UNUSED_USER;
  private requesterId: string | null = UNUSED_USER;
  private sensitivity: Record<string, number> = {};
  private targetValues: Record<string, ValueType> = {};
  public timeOfLastControllerAssigned = 0;
  private isInitialised = false;
  private timerId: ReturnType<typeof setTimeout> | null = null;
  private controlListener: (
    control: liveApiSockets.MediaSessionEventDetailsPtzControlV2,
    myUserId: string,
  ) => void;

  private handleStatusNotification(
    control: liveApiSockets.MediaSessionEventDetailsPtzControlV2,
  ): void {
    if (control.command === liveApiSockets.PtzCommands.StatusNotification) {
      let myControllerId: string | null = UNUSED_USER;
      if (control.controllerId === this.userId) {
        if (this.controllerId !== this.userId) {
          this.timeOfLastControllerAssigned = Date.now();
          this.emit(EventTypes.ControlAssigned);
        }
        myControllerId = this.userId;
      } else {
        if (this.controllerId === this.userId) {
          this.timeOfLastControllerAssigned = Date.now();
          this.emit(EventTypes.ControlCancelled, control.controllerId);
        }
        myControllerId = control.controllerId;
      }

      this.controllerId = myControllerId;
      this.requesterId = control.requesterId;
      this.isInitialised = true;

      this.controlUpdate();
    }
  }

  constructor(
    private userId: string,
    private deviceList: DeviceMetadata[],
    private socket?: SocketIoClientWrapper,
  ) {
    super({ label: "PTZ USER", available: true });

    // deviceId should exist only for virtual devices
    this.deviceId =
      deviceList.length === 1 &&
      deviceList[0].deviceId === deviceList[0].component
        ? deviceList[0].deviceId
        : "";

    this.on("component:state", (_componentName: string): void => {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.targetValues = {};
        this.statusUpdate();
      }, 1000);
    });

    this.on("component:services", (_componentName: string): void => {
      this.statusUpdate();
    });

    this.controlListener = this.onControl.bind(this);

    this.socket?.onBroadcast(
      liveApiSockets.MediaSessionEventBroadcastTopics.ptzControl,
      this.controlListener,
    );

    this.requestStatusAndControl();
  }

  private onControl(
    control: liveApiSockets.MediaSessionEventDetailsPtzControlV2,
    myUserId: string,
  ): void {
    if (control.deviceId !== this.deviceId) {
      return;
    }

    switch (control.command) {
      case liveApiSockets.PtzCommands.StatusRequest:
        break;
      case liveApiSockets.PtzCommands.StatusNotification:
        this.handleStatusNotification(control);
        break;
      case liveApiSockets.PtzCommands.ControlRequest:
        if (this.userId !== myUserId) {
          this.emit(EventTypes.ControlRequested, myUserId);
        }
        break;
      case liveApiSockets.PtzCommands.ControlRescind:
        if (this.userId !== myUserId) {
          this.emit(EventTypes.ControlRescinded, myUserId);
          // wait for StatusNotification before we change state
        }
        break;
      case liveApiSockets.PtzCommands.ControlDeny:
        if (this.userId !== myUserId) {
          this.emit(EventTypes.ControlDenied, myUserId);
          // wait for StatusNotification before we change state
        }
        break;
    }
  }

  public requestStatusAndControl() {
    if (this.isInitialised) {
      // if we've got the data already then we'll emit it
      this.controlUpdate();
    } else {
      // otherwise we'll request it from the owner and we'll emit the
      // status when we get the response
      this.send(liveApiSockets.PtzCommands.StatusRequest);
    }
    this.statusUpdate();
  }

  public requestControl() {
    if (!this.isControllable) {
      if (this.requesterId) {
        this.emit(EventTypes.ControlAlreadyRequested);
      } else {
        this.send(liveApiSockets.PtzCommands.ControlRequest);
      }
    }
  }

  public cancelControl() {
    if (this.isControllable) {
      this.send(liveApiSockets.PtzCommands.ControlCancel);
    }
  }

  public rescindControl() {
    if (this.isControllable) {
      this.controllerId = UNUSED_USER;
      this.send(liveApiSockets.PtzCommands.ControlRescind);
    }
  }

  public denyControl() {
    if (this.isControllable) {
      this.send(liveApiSockets.PtzCommands.ControlDeny);
    }
  }

  public async setMounting(
    componentName: string,
    serviceName: string,
    mounting: liveApiSockets.PtzMountings,
  ): Promise<void> {
    if (this.isControllable) {
      await this.sendCommand(componentName, serviceName, "mounting", mounting);
    }
  }

  // defined as sync to match similar methods
  public async setSensitivity(
    capabilityName: string,
    sensitivity: number,
  ): Promise<void> {
    if (this.isControllable) {
      if (typeof this.sensitivity[capabilityName] === "number") {
        this.sensitivity[capabilityName] = sensitivity;
      }
      this.statusUpdate();
    }
  }

  public async sendPanTilt(
    componentName: string,
    serviceName: string,
    movement: string,
    value: number,
  ): Promise<void> {
    if (!this.isControllable) {
      return;
    }

    const states = this.getComponentState(componentName)?.state[serviceName];

    const services =
      this.getComponentServices(componentName)?.services[serviceName];

    const movementService = services?.[movement] as ICapabilityDefinitionRange;
    if (!movementService) {
      console.warn("Camera service has no", movement);
      return;
    }

    const movementState = states?.[movement];
    if (typeof movementState !== "number") {
      console.warn("Camera state has no", movement);
      return;
    }

    let mountingFactor = 1;
    const mountingState = states?.mounting;
    if (typeof mountingState === "string") {
      mountingFactor =
        mountingState === liveApiSockets.PtzMountings.Standard ? 1 : -1;
    }

    const currentZoomPercent =
      typeof states?.ZOOM === "number" && services?.ZOOM
        ? states.ZOOM / services.ZOOM.max
        : 1;

    const sensitivity = this.sensitivity[movement] || 1;

    //get step size
    const min = movementService.min;
    const max = movementService.max;
    const diff = max - min;
    let step = (diff * sensitivity) / 1000;

    //reduce movement by 50% on max zoom
    step = Math.round(step * (1 - currentZoomPercent / 2));

    const newValue = constrain(movementState + value * step * mountingFactor, {
      min,
      max,
    });

    console.log("sendPanTilt", {
      movement,
      componentName,
      serviceName,
      newValue,
    });

    await this.sendCommand(componentName, serviceName, movement, newValue);
  }

  public async sendZoom(
    componentName: string,
    serviceName: string,
    value: number,
  ): Promise<void> {
    if (!this.isControllable) {
      return;
    }

    const services = this.getComponentServices(componentName);

    const zoomStatus = services?.services[serviceName].ZOOM;
    if (!zoomStatus) {
      console.warn("Camera doesn't support zoom");
      return;
    }

    const newValue =
      ((zoomStatus.max - zoomStatus.min) / 100) * value + zoomStatus.min;

    console.log("sendZoom", {
      componentName,
      serviceName,
      newValue,
    });

    await this.sendCommand(componentName, serviceName, "ZOOM", newValue);
  }

  private async sendCommand(
    componentName: string,
    serviceName: string,
    movement: string,
    value: ValueType,
  ): Promise<void> {
    this.targetValues[movement] = value;

    this.statusUpdate();

    const requestPayload: IComponentRequestPayload = {
      request: {
        [serviceName]: {
          [movement]: value,
        },
      },
    };

    console.log(
      {
        component: componentName,
      },
      `PTZ SEND ${JSON.stringify(requestPayload)}`,
    );

    return this.requestComponent(componentName, requestPayload);
  }

  private controlUpdate() {
    this.emit(
      EventTypes.ControlNotification,
      this.controllerId,
      this.requesterId,
    );
  }

  private setValue(
    component: IComponentPublishInfo,
    serviceName: string,
    capabilityName: string,
    values: PtzUserValues,
  ): void {
    if (typeof values[capabilityName] !== "undefined") {
      return;
    }

    if (!component.statePayload || !component.servicesPayload) {
      // information missing - it might appear later
      return;
    }

    if (
      typeof component.statePayload.state[serviceName][capabilityName] ===
      "undefined"
    ) {
      console.warn("No corresponding state", capabilityName);
      return;
    }

    const capability = component.servicesPayload.services[serviceName][
      capabilityName
    ] as ICapabilityDefinitionRange;

    if (
      typeof this.sensitivity[capabilityName] === "undefined" &&
      typeof DefaultSensitvity[capabilityName] !== "undefined"
    ) {
      this.sensitivity[capabilityName] = DefaultSensitvity[capabilityName];
    }

    values[capabilityName] = {
      min: capability.min,
      max: capability.max,
      value:
        this.targetValues[capabilityName] ||
        (component.statePayload.state[serviceName][
          capabilityName
        ] as ValueType),
      sensitivity: this.sensitivity[capabilityName],
      component: component.name,
      service: serviceName,
    };
  }

  private statusUpdate() {
    console.debug("statusUpdate", this.deviceList, this.components);

    const values: PtzUserValues = {};

    this.deviceList.forEach((metadata: DeviceMetadata): void => {
      const component = this.getComponent(metadata.component);
      console.debug("component", metadata, component);
      if (!component) {
        // no components have been received yet
        return;
      }

      let services: string[];
      if (typeof metadata.services === "undefined") {
        services = Object.keys(component.servicesPayload?.services || {});
      } else {
        services = Array.isArray(metadata.services)
          ? metadata.services
          : [metadata.services];
      }

      services.forEach((serviceName: string): void => {
        Object.keys(
          component.servicesPayload?.services[serviceName] || {},
        ).forEach((capabilityName: string): void => {
          this.setValue(component, serviceName, capabilityName, values);
        });
      });
    });

    this.emit(EventTypes.StatusNotification, values);
  }

  private async send(
    command: liveApiSockets.PtzCommands,
    args = {},
  ): Promise<void> {
    try {
      await this.socket?.broadcastAsync(
        liveApiSockets.MediaSessionEventBroadcastTopics.ptzControl,
        {
          command: command,
          deviceId: this.deviceId,
          ...args,
        },
      );
    } catch (error) {
      console.warn("Error sending PTZ command=", error);
    }
  }

  get isControllable(): boolean {
    return !this.isLockable || this.controllerId === this.userId;
  }

  get isLockable(): boolean {
    return !!(this.deviceId && this.socket);
  }

  public hasDeviceId(deviceId: string): boolean {
    return this.deviceList.some((device: DeviceMetadata): boolean => {
      return device.deviceId === deviceId;
    });
  }

  public shutdown(): void {
    this.socket?.offBroadcast(
      liveApiSockets.MediaSessionEventBroadcastTopics.ptzControl,
      this.controlListener,
    );

    super.shutdown();
  }
}
