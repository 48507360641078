import * as t from "io-ts";

const ServerURL = t.type({
  url: t.string,
  httpUrl: t.string,
  apiKey: t.string,
});
type ServerURL = t.TypeOf<typeof ServerURL>;

export default ServerURL;
