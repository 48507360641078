import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { ServerParams } from "@proximie/media";
import { isLocalOrE2EEnvironment } from "@proximie/common";

const getServerParams: (
  streamId: string,
  region: string,
) => () => Promise<ServerParams> = (streamId: string, region: string) => () =>
  axios.get(
    isLocalOrE2EEnvironment(environment.name)
      ? `http://localhost:8020/api/v1/streams/${streamId}/connection-settings`
      : `https://${region}.${environment.domain}/api/v1/streams/${streamId}/connection-settings`,
  );

export const useServerParams = (
  streamId?: string,
  region?: string,
  opts?: SWRConfiguration,
) => {
  const { data, error } = useSWR<ServerParams>(
    streamId ? `stream-${streamId}-server-params` : null,
    getServerParams(streamId ?? "", region ?? ""),
    {
      // we only to get the server params once - they will not change
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...opts,
    },
  );

  return {
    serverParams: data,
    areServerParamsLoading: !error && !data,
    serverParamsError: error,
  };
};
