import { Box, Toolbar, useTheme } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { AppBarGrow } from "./AppBar.style";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Logo } from "../Logo/Logo";

interface AppBarProps {
  children?: ReactNode;
}

const AppBar: FC<AppBarProps> = (props: AppBarProps) => {
  const { colors, palette } = useTheme();

  const minRes = useMediaQuery("(min-width:1025px)");
  return (
    <Toolbar
      component={Box}
      bgcolor={colors.Grey900}
      color={palette.primary.contrastText}
    >
      <Box flexShrink={0}>
        {minRes && (
          <Logo variant={"media-app"} wrapperStyle={{ paddingLeft: 0 }} />
        )}
      </Box>
      <AppBarGrow />
      {props.children}
    </Toolbar>
  );
};
export default AppBar;
