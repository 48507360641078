import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider, createTheme } from "@mui/material/styles";
import Favicon from "react-favicon";
import { generateTheme } from "./mui-theme/proximie/theme";
import { useFlavor } from "libs/flavorinator/src/lib/useFlavor";
import { Prefetch } from "../components/Prefetch/Prefetch";
import "./global.css";

interface GlobalStylesProps {
  children: React.ReactNode;
}

export const GlobalStyles = (props: GlobalStylesProps) => {
  const { theme, favicons, logos } = useFlavor();
  const prefetchImageUrls = [...Object.values(logos)];

  return (
    <StyledEngineProvider injectFirst>
      {favicons.map((favicon) => (
        <Favicon key={favicon} url={favicon} />
      ))}
      <Prefetch imageUrls={prefetchImageUrls} />
      <ThemeProvider theme={createTheme(theme ?? generateTheme())}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
