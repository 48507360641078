//mostly copied from dashboard-frontend
import useSWR, { SWRConfiguration } from "swr";
import * as t from "io-ts";
import axios from "axios";
import { environment } from "../../../environments/environment";

const SessionOwner = t.type({
  id: t.string,
  name: t.union([t.undefined, t.null, t.string]),
  email: t.union([t.undefined, t.null, t.string]),
});

const SessionType = t.type({
  name: t.string,
  weighting: t.number,
});

const Stream = t.type({
  id: t.string,
  // status: t.string, // Something changed here
  status: t.string,
  url: t.string,
});
const SessionAccess = t.type({
  name: t.string,
});

const Facility = t.type({
  id: t.string,
  name: t.string,
  isRemovable: t.union([t.undefined, t.boolean]),
});

const Room = t.type({
  id: t.string,
  name: t.string,
  isRemovable: t.union([t.undefined, t.boolean]),
});

const Organisation = t.type({
  id: t.string,
  name: t.string,
  type: t.string,
  allowImageOverlay: t.boolean,
  allowPublicVisibility: t.boolean,
  allowRecording: t.boolean,
  allowVideoSharing: t.boolean,
  allowWearables: t.boolean,
  countryISO: t.string,
});

export const User = t.type({
  id: t.string,
  email: t.string,
  hasAcceptedTerms: t.boolean,
  name: t.union([t.undefined, t.string]),
  firstName: t.union([t.undefined, t.string]),
  lastName: t.union([t.undefined, t.string]),
  phone: t.union([t.undefined, t.null, t.string]),
  userTitle: t.union([t.undefined, t.string]),
  speciality: t.union([t.undefined, t.string]),
  countryISO: t.union([t.undefined, t.string]),
});

const UpdatedBy = t.type({
  email: t.string,
  name: t.union([t.null, t.string]),
});

const Session = t.type({
  title: t.string,
  owner: SessionOwner,
  scheduledDateAndTime: t.union([t.undefined, t.string]),
  scheduledAt: t.string,
  estimatedDuration: t.number,
  createdAt: t.string,
  updatedAt: t.string,
  type: SessionType,
  access: SessionAccess,
  description: t.union([t.undefined, t.string]),
  deletedAt: t.union([t.undefined, t.null, t.string]),
  editedAt: t.union([t.undefined, t.string]),
  timePassedSinceLastEdit: t.union([t.undefined, t.string]),
  updatedBy: t.union([t.undefined, UpdatedBy]),
});

const GetSessionResponse = t.type({
  id: t.string,
  session: Session,
  stream: t.union([t.undefined, Stream]),
  facility: t.union([t.undefined, Facility]),
  room: t.union([t.undefined, Room]),
  organisation: Organisation,
});

export type SessionC = t.TypeOf<typeof Session>;
export type OrganisationC = t.TypeOf<typeof Organisation>;
export type StreamC = t.TypeOf<typeof Stream>;
export type FacilityC = t.TypeOf<typeof Facility>;
export type RoomC = t.TypeOf<typeof Room>;

export const useSession = (
  sessionId: string | undefined | null,
  opts?: SWRConfiguration,
) => {
  const getSession = (): Promise<t.TypeOf<typeof GetSessionResponse>> =>
    axios.get(`${environment.apiUrl}/api/v2/sessions/${sessionId}`);

  const { data, error, mutate } = useSWR<t.TypeOf<typeof GetSessionResponse>>(
    sessionId ? `sessions/${sessionId}` : null,
    getSession,
    opts,
  );

  const endSession = async (): Promise<void> => {
    await axios.delete(`${environment.apiUrl}/api/v2/sessions/${sessionId}`);
    mutate();
  };

  return {
    ...data,
    sessionId: data?.id,
    isSessionLoading: !error && !data,
    isSessionError: error,
    mutateSession: mutate,
    endSession,
  };
};
