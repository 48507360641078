import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Popover,
  SpeedDialAction,
  SpeedDialIcon,
  useTheme,
} from "@mui/material";
import SocketIoClientWrapper from "../../wrappers/SocketIOClientWrapper/SocketIOClientWrapper";
import { Delete } from "@mui/icons-material";
import { Icon } from "@proximie/components";
import { MediaVideo } from "@proximie/media";
import { CursorIcon } from "../icons/CursorIcon/CursorIcon";
import { SpeedDialStyled } from "../SpeedDial/SpeedDial.style";
import { DrawIcon } from "../icons/DrawIcon/DrawIcon";
import { ColorPickerIcon } from "../icons/ColorPickerIcon/ColorPickerIcon";
import { liveApiSockets } from "@proximie/dataregion-api";

interface TelestrationControlsProps {
  isTelestrationActive: boolean;
  isEraserActive: boolean;
  toggleTelestration: () => void;
  isCursorMode: boolean;
  toggleCursorMode: () => void;
  socket: SocketIoClientWrapper;
  toggleEraser: () => void;
  currentColor: string;
  colorPicker: JSX.Element;
  videos: MediaVideo[];
}

const SpeedDialMenuOpen = "SpeedDialMenuOpen";
const SpeedDialActionSelected = "SpeedDialActionSelected";
const ColorPickerOpen = "ColorPickerOpen";

export const TelestrationControls: (
  props: TelestrationControlsProps,
) => JSX.Element = ({
  currentColor,
  isTelestrationActive,
  isEraserActive,
  isCursorMode,
  toggleCursorMode,
  colorPicker,
  toggleTelestration,
  toggleEraser,
  socket,
  videos,
}: TelestrationControlsProps) => {
  const { colors } = useTheme();

  const [menuOpen, setMenuOpen] = useState(false);
  const colorPickerButtonRef = useRef(null);
  const [colorPickerAnchorEl, setColorPickerAnchorEl] =
    useState<HTMLElement | null>(null);
  const isColorPickerVisible = Boolean(colorPickerAnchorEl);
  const [cursorIconColor, setCursorIconColor] = useState("");
  const [drawIconColor, setDrawIconColor] = useState("");

  const openColorPicker = (event: React.MouseEvent<HTMLElement>) => {
    setColorPickerAnchorEl(event.currentTarget);
  };

  const closeColorPicker = () => {
    setColorPickerAnchorEl(null);
  };

  useEffect(() => {
    setCursorIconColor(colors.WhiteHighEmphasis);
    setDrawIconColor(colors.WhiteHighEmphasis);
  }, [colors]);

  useEffect(() => {
    closeColorPicker();
  }, [currentColor]);

  return (
    <div>
      <SpeedDialStyled
        ariaLabel="Open annotation menu"
        icon={
          menuOpen ? (
            <SpeedDialIcon style={{ color: colors.WhiteHighEmphasis }} />
          ) : (
            <DrawIcon color={colors.WhiteHighEmphasis} />
          )
        }
        FabProps={{
          className: menuOpen ? SpeedDialMenuOpen : undefined,
        }}
        open={menuOpen}
        direction={"down"}
        onClose={(_event, reason) => {
          if (reason === "toggle") {
            setMenuOpen(false);
          }
        }}
        onOpen={(_event, reason) => {
          if (reason === "toggle") {
            setMenuOpen(true);
          }
        }}
      >
        <SpeedDialAction
          data-cy={"toggle-cursor-mode"}
          data-testid={"toggle-cursor-mode"}
          className={isCursorMode ? SpeedDialActionSelected : undefined}
          onMouseEnter={() => setCursorIconColor(colors.Brand200)}
          onMouseLeave={() => setCursorIconColor(colors.WhiteHighEmphasis)}
          icon={
            <div>
              <CursorIcon color={cursorIconColor} />
              <p>Point</p>
            </div>
          }
          tooltipOpen={false}
          onClick={toggleCursorMode}
        />
        <SpeedDialAction
          data-cy={"toggle-telestration-mode"}
          data-testid={"toggle-telestration-mode"}
          className={isTelestrationActive ? SpeedDialActionSelected : undefined}
          onMouseEnter={() => setDrawIconColor(colors.Brand200)}
          onMouseLeave={() => setDrawIconColor(colors.WhiteHighEmphasis)}
          icon={
            <div>
              <DrawIcon color={drawIconColor} />
              <p>Draw</p>
            </div>
          }
          tooltipOpen={false}
          onClick={toggleTelestration}
        />
        <SpeedDialAction
          data-cy={"toggle-open-color-picker"}
          data-testid={"toggle-open-color-picker"}
          className={isColorPickerVisible ? ColorPickerOpen : undefined}
          ref={colorPickerButtonRef}
          icon={
            <div>
              <ColorPickerIcon color={currentColor} />
              <p>Color</p>
            </div>
          }
          tooltipOpen={false}
          onClick={openColorPicker}
        />
        <SpeedDialAction
          className={isEraserActive ? SpeedDialActionSelected : undefined}
          data-testid={"toggle-eraser-mode"}
          icon={
            <div>
              <Icon name={"eraser"} />
              <p>Erase</p>
            </div>
          }
          tooltipOpen={false}
          onClick={toggleEraser}
        />
        <SpeedDialAction
          data-cy={"clear-telestrations"}
          data-testid={"clear-telestrations"}
          icon={
            <div>
              <Delete />
              <p>Clear all</p>
            </div>
          }
          tooltipOpen={false}
          onClick={() => {
            videos.forEach((video: MediaVideo): void => {
              socket.sendSync(
                liveApiSockets.MediaSessionEventType.telestration,
                {
                  streamId: video.streamId,
                  command: "clearAll",
                },
              );
            });
          }}
        />
      </SpeedDialStyled>
      <Popover
        open={isColorPickerVisible}
        anchorEl={colorPickerAnchorEl}
        onClose={closeColorPicker}
        PaperProps={{
          style: {
            backgroundColor: colors.Grey900,
            borderRadius: "10px",
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: 52,
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box padding={"16px"}>{colorPicker}</Box>
      </Popover>
    </div>
  );
};
