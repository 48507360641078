import { ServerParams, ServerURL } from "../../index";
import FeedType from "../models/FeedType";
import { TestList, TestResult } from "../models/TroubleShooterTests";
import { init } from "./Init/Janus";
import EndpointJanusEcho from "./Endpoints/Janus/EndpointJanusEcho";
import ConnectionJanusEcho from "./Connections/Janus/ConnectionJanusEcho";
import { encodeStreamId } from "../MediaUtil";
import DeviceVirtual from "./Devices/DeviceVirtual";

const CONNECTION_GUARD_TIMEOUT = 45000; //msecs
const STATS_COLLECTION_INTERVAL = 1000; //msecs

export type ResultCallback = (connection: TestList, status: TestResult) => void;

export default class EchoAdapter {
  private type?: FeedType;
  private serverUrl: ServerURL = { url: "", httpUrl: "", apiKey: "" };

  private echoEndpoint: EndpointJanusEcho | null = null;
  private echoConnection: ConnectionJanusEcho | null = null;
  private checkTimerId: ReturnType<typeof setInterval> | null = null;

  constructor(private userId: string, private serverParams: ServerParams) {}

  private setFeedTypeAndServerUrl(feedType: FeedType): void {
    if (this.type) {
      throw new Error("Already active");
    }

    if (feedType === FeedType.Audio) {
      this.type = FeedType.Audio;
      this.serverUrl = this.serverParams.audioServer;
    } else if (feedType === FeedType.Camera) {
      this.type = FeedType.Camera;
      this.serverUrl = this.serverParams.videoServer;
    } else {
      throw new Error("Invalid source");
    }
  }

  private async isMediaFlowing(stream: MediaStream): Promise<boolean> {
    if (!this.echoConnection) {
      return false;
    }

    const stats =
      (await this.echoConnection.pc?.getStats(stream.getTracks()[0])) || [];

    let dataFlowing = false;
    stats.forEach((report) => {
      if (report.type === "inbound-rtp") {
        const packetsReceived = report.packetsReceived;
        console.log("packetsReceived=", packetsReceived);
        if (packetsReceived > 0) {
          dataFlowing = true;
        }
      }
    });
    return dataFlowing;
  }

  private connect(
    stream: MediaStream,
    setResult: ResultCallback,
  ): Promise<void> {
    // linter complains if we make the Promise executor function async
    //eslint-disable-next-line sonarjs/cognitive-complexity
    return new Promise((resolve, reject) => {
      init()
        .then(() => {
          this.echoEndpoint = new EndpointJanusEcho({
            userId: this.userId,
            sessionId: "",
            serverUrl: this.serverUrl,
            iceServers: this.serverParams.iceServers || [],
            iceTransportPolicy: "relay",
          });

          this.echoEndpoint?.on("closed", (): void => {
            console.warn("EchoAdapter: endpoint closed");
          });
          return this.echoEndpoint?.connect();
        })
        .then(() => {
          if (!this.echoEndpoint) {
            throw new Error("Endpoint not defined");
          }

          const device = new DeviceVirtual("ECHO_DEVICE");

          const streamId = encodeStreamId({
            // we should always have a type by the time we get here
            type: this.type || FeedType.Audio,
            userId: this.userId,
          });

          this.echoConnection = new ConnectionJanusEcho(
            this.echoEndpoint,
            device,
            streamId,
            {},
          );

          this.echoConnection?.on("closed", (): void => {
            console.warn("EchoAdapter - has been closed");
          });

          this.echoConnection?.on("error", (error: Error): void => {
            console.warn("EchoAdapter - has error=", error);
          });

          // we're only interested in the first time this event is fired
          this.echoConnection?.once(
            "connected",
            (remoteStream: MediaStream) => {
              this.checkTimerId = setInterval(async () => {
                const isMediaFlowing = await this.isMediaFlowing(remoteStream);
                if (isMediaFlowing) {
                  setResult(this.mediaTestName, TestResult.Pass);
                  resolve();
                } else {
                  console.debug("No media is flowing yet");
                }
              }, STATS_COLLECTION_INTERVAL);
            },
          );

          return this.echoConnection?.open();
        })
        .then(() => {
          setResult(this.serverTestName, TestResult.Pass);
          return this.echoConnection?.send(stream);
        })
        .catch((error) => reject(error));
    });
  }

  public async start(
    feedType: FeedType,
    stream: MediaStream,
    setResult: ResultCallback,
  ): Promise<void> {
    console.debug("EchoAdapter:start", this.serverParams);

    this.setFeedTypeAndServerUrl(feedType);

    let guardTimerId: ReturnType<typeof setTimeout> | undefined;
    try {
      await Promise.race([
        this.connect(stream, setResult),
        new Promise((_resolve, reject) => {
          guardTimerId = setTimeout(() => {
            reject(new Error("Timed-out waiting for connection"));
          }, CONNECTION_GUARD_TIMEOUT);
        }),
      ]);
      if (guardTimerId) {
        clearTimeout(guardTimerId);
      }
      if (this.checkTimerId) {
        clearInterval(this.checkTimerId);
      }
      this.finish();
    } catch (error) {
      if (guardTimerId) {
        clearTimeout(guardTimerId);
      }
      if (this.checkTimerId) {
        clearInterval(this.checkTimerId);
      }
      console.warn("EchoAdapter:start - error=", error);
      setResult(this.mediaTestName, TestResult.Fail);
      this.finish();
      throw error;
    }
  }

  public finish(): void {
    this.echoConnection?.close();
    this.echoConnection = null;
    this.echoEndpoint?.close();
    this.echoEndpoint = null;
  }

  public get serverTestName(): TestList {
    return this.type === FeedType.Audio
      ? TestList.audioServer
      : TestList.videoServer;
  }

  public get mediaTestName(): TestList {
    return this.type === FeedType.Audio
      ? TestList.audioMedia
      : TestList.videoMedia;
  }
}
