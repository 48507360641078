/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Endpoint, { EndpointConfig } from "../Endpoint";
import Janus, { JanusJS } from "@proximie/janus-gateway";
import { PrxMessage } from "../../Connections/Janus/message.d";
import ServerURL from "../../../models/ServerURL";

export type IceTransportPolicy = "relay" | "all";

export interface EndpointJanusConfig extends EndpointConfig {
  serverUrl: ServerURL;
  iceServers: RTCIceServer[];
  iceTransportPolicy: IceTransportPolicy;
}

export interface CreateOptions {
  plugin: string;

  [key: string]: string | number | boolean;
}

export default abstract class EndpointJanus extends Endpoint {
  private instance: JanusJS.Janus | null = null;
  protected abstract pluginName: string;
  protected abstract createOptions: CreateOptions;
  protected abstract ignoreErrors: number[];

  constructor(public config: EndpointJanusConfig) {
    super(config);
  }

  override connect(): Promise<void> {
    console.debug("EndpointJanus:connect");
    return new Promise<void>((resolve, reject) => {
      this.instance = new Janus({
        apisecret: this.config.serverUrl.apiKey,
        server: this.config.serverUrl.url,
        iceServers: this.config.iceServers,
        iceTransportPolicy: this.config.iceTransportPolicy,
        success: () => {
          resolve();
        },
        error: (error: string): void => {
          if (error.startsWith("Lost connection to the server")) {
            // if we lost connection to the server then attempt to
            // re-connect.  If it was a temporary network failue then
            // we may be able to resume without any noticing, otherwise
            // we'll emit an error
            setTimeout(() => {
              if (!this.instance) {
                // connection has been closed - ignore
                return;
              }

              //  reconnect() does not exist in definition file
              // @ts-ignore
              this.instance["reconnect"]({
                success: () => {
                  console.debug("EndpointJanus:connect - reconnecting");
                  // emit message to allow audio and monitor connections
                  // to synchronise participant lists
                  this.emit("reconnected");
                },
                error: (myError: any): void => {
                  console.debug("EndpointJanus:connect - error=", myError);
                  this.close(new Error(myError)).catch(() => {
                    // ignore
                  });
                },
              });
            }, 10000);
          } else {
            this.instance = null;
            // only other errors that occur here are at the start so just reject
            reject(new Error(error));
          }
        },
        destroyed: () => {
          this.close(new Error("Endpoint has been destroyed")).catch(() => {
            /* ignore */
          });
        },
      });
    });
  }

  public create(handle: JanusJS.PluginHandle): Promise<void> {
    console.debug("EndpointJanus:create");

    return new Promise<void>((resolve, reject) => {
      const register = {
        request: "create",
        room: this.sessionId,
        ...this.createOptions,
      };

      handle.send({
        message: register,
        success: (response: PrxMessage) => {
          if (
            !response.error_code ||
            this.ignoreErrors.includes(response.error_code)
          ) {
            resolve();
          } else {
            reject(new Error(response.error));
          }
        },
        error: (error: string) => reject(new Error(error)),
      } as unknown as JanusJS.PluginMessage);
    });
  }

  attach(attachOptions: JanusJS.PluginOptions): Promise<JanusJS.PluginHandle> {
    console.debug("EndpointJanus:attach");

    return new Promise<JanusJS.PluginHandle>((resolve, reject) => {
      if (!this.instance) {
        reject(new Error("Instance not available"));
        return;
      }

      this.instance.attach({
        ...attachOptions,
        // overwrite the dummy value in options with the real value
        plugin: this.pluginName,
        opaqueId: this.userId.toString(),
        success: resolve,
        error: (error) => {
          console.warn("EndpointJanus:attach - error=", error);
          reject(new Error(error));
        },
      });
    });
  }

  override close(error?: Error): Promise<void> {
    console.debug("EndpointJanus:close - error=", error);
    if (this.instance) {
      this.instance.destroy();
      this.instance = null;
      return super.close(error);
    }
    return Promise.resolve();
  }
}
