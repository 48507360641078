import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import {
  ORGANISATION_ID_QUERY_PARAM_NAME,
  TIMEZONE_HEADER_PARAM_NAME,
} from "../constants";

interface SetAxiosInterceptors {
  onRequestError?: (error: AxiosError) => Promise<void>;
  onResponseError?: (error: AxiosError) => Promise<void>;
}

export const requestHandler = (
  request: AxiosRequestConfig,
): AxiosRequestConfig => {
  request.headers[TIMEZONE_HEADER_PARAM_NAME] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return request;
};

export const requestErrorHandler =
  (onRequestError?: (error: AxiosError) => Promise<void>) =>
  async (error: AxiosError): Promise<AxiosError> => {
    if (onRequestError) await onRequestError(error);

    return Promise.reject(error);
  };

export const responseSuccessHandler = (
  response: AxiosResponse,
): AxiosResponse => {
  return response;
};

export const responseErrorHandler =
  (onResponseError?: (error: AxiosError) => Promise<void>) =>
  async (error: AxiosError): Promise<AxiosError> => {
    if (onResponseError) await onResponseError(error);

    return Promise.reject(error);
  };

export const setAuthorizationHeader = (token: string): void => {
  axios.defaults.headers["Authorization"] = `Bearer ${token}`;
};

export const setCurrentOrganisationIdQueryParam = (
  organisationId: number,
): void => {
  if (organisationId) {
    axios.defaults.params = axios.defaults.params || {};
    axios.defaults.params[ORGANISATION_ID_QUERY_PARAM_NAME] = organisationId;
  }
};

export const setAxiosInterceptors = ({
  onRequestError,
  onResponseError,
}: SetAxiosInterceptors): {
  requestInterceptorId: number;
  responseInterceptorId: number;
} => {
  const requestInterceptorId = axios.interceptors.request.use(
    requestHandler,
    requestErrorHandler(onRequestError),
  );
  const responseInterceptorId = axios.interceptors.response.use(
    responseSuccessHandler,
    responseErrorHandler(onResponseError),
  );
  return { requestInterceptorId, responseInterceptorId };
};

export const removeAxiosInterceptors = (
  requestId: number,
  responseId: number,
) => {
  axios.interceptors.request.eject(requestId);
  axios.interceptors.response.eject(responseId);
};
