import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { environment } from "../../../environments/environment";

export type Member = {
  id: string;
  name: string;
  email: string;
};

type Members = {
  members: Member[];
};

const getMembers: (organisationId: string) => () => Promise<Members> =
  (organisationId: string) => () =>
    axios.get(
      `${environment.apiUrl}/api/v2/organisations/${organisationId}/members`,
    );

export const useMembers = (organisationId: string, opts?: SWRConfiguration) => {
  const { data, error } = useSWR<Members>(
    `organisation-${organisationId}-members`,
    getMembers(organisationId),
    opts,
  );

  return {
    members: data?.members,
    areMembersLoading: !error && !data,
    membersError: error,
  };
};
