export const PROXIMIE_URLS = {
  TermsAndConditions: "https://proximie.com/terms-of-service/",
  PrivacyPolicy: "https://proximie.com/privacy-notice/",
  Proximie: "https://www.proximie.com/",
};

export const FeatureFlags = {
  // Temporary
  CUSTOMER_SUPPORT_V2: "ff_support_ticket_v2",
};
